import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { palette, Icon } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import { infoCircle } from 'lib/icons';
import { getWorkOrderOpTotalParts } from 'lib/selectors/getWorkOrderOpTotalParts';
import { getWorkOrderOpBadParts } from 'lib/selectors/getWorkOrderOpBadParts';

const PartsSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  color: ${palette.Indigo600};
  height: 1rem;
`;

const PartsSummaryText = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
`;

// Component to display parts summary information
const PartsSummary = () => {
  const { t } = useTranslation();

  const goodParts = useSelector(getWorkOrderOpTotalParts);
  const badParts = useSelector(getWorkOrderOpBadParts);

  if (
    [goodParts, badParts].some((value) => {
      return value > 0;
    })
  ) {
    // Render the parts summary information
    return (
      <PartsSummaryContainer>
        <Icon icon={infoCircle} color={palette.Indigo600} size="0.75rem" />

        <PartsSummaryText>
          {t('parts_summary', {
            parts: goodParts + badParts,
            good: goodParts,
            rejects: badParts,
          })}
        </PartsSummaryText>
      </PartsSummaryContainer>
    );
  }

  // Return null if no parts summary needs to be displayed
  return null;
};

export default PartsSummary;
