import { createSelector } from 'reselect';
import { getWorkOrderManagement } from './getWorkOrderManagement';

const getLoadingPartAdjustment = createSelector(
  [getWorkOrderManagement],
  (workOrderManagement) => {
    return workOrderManagement.loadingPartAdjustment;
  }
);

export { getLoadingPartAdjustment };
