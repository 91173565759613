// React and Hooks
import React from 'react';
import { useTranslation } from 'react-i18next';
// Actions
// Selectors
// Utils
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
// Components
import { WheelLabel, WheelSublabel, Icon } from '@m12s/component-library';
import { farPause } from 'lib/icons';
import { OpRunOverrideRejectLabel } from './OpRunOverrideRejectLabel';

/**
 * A component that displays the paused state content within a wheel visualization
 * @component
 * @param {Object} props - Component props
 * @param {number} [props.expectedSetupDurationMs] - Expected setup duration in milliseconds
 * @returns {JSX.Element} Rendered paused wheel content with icon, label and override reject label
 */
export const PausedWheelContent = ({ expectedSetupDurationMs }) => {
  const { t } = useTranslation();
  
  return (
    <>
      <WheelLabel>
        <Icon
          icon={farPause}
          style={{
            height: '3rem',
            width: '3rem',
            marginBottom: '0.25rem',
          }}
        />
      </WheelLabel>
      <WheelSublabel
        style={{
          marginTop: '0.5rem',
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
          fontWeight: 600,
        }}
      >
        {_startCase(t('paused'))}
      </WheelSublabel>
      <OpRunOverrideRejectLabel
        style={{
          fontWeight: 600,
        }}
        expectedSetupDurationMs={expectedSetupDurationMs}
      />
    </>
  );
};

/**
 * PropTypes for the PausedWheelContent component
 */
PausedWheelContent.propTypes = {
  expectedSetupDurationMs: PropTypes.number,
};

export default PausedWheelContent;