import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { Subheader } from '@m12s/component-library';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Downtime } from 'components/Downtime';
import { Alarms } from 'components/Alarms';
import { Utilization } from 'components/Utilization';
import MainLayout from 'components/Layouts/MainLayout';
import { CycleTime } from 'components/CycleTime';
import { CycleCountStatus } from 'components/CycleCountStatus';
import { MachineStatus } from 'components/MachineStatus';
import { OEE } from 'components/OEE';
import { PartsGoal } from 'components/PartsGoal';
import Routes from 'lib/utils/routes';
import { CustomTabAuthorizationModal, CustomTab } from 'components/CustomTab';
import { getMachine } from 'lib/selectors/getMachine';

import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getHomeTabRoute, getVisibleTabs } from 'lib/selectors/getTabs';
import { getCustomTabs } from 'lib/selectors/getCustomTab';
import { MMEmbeddableZoneProvider } from '@machinemetrics/mm-react-embeddable';
import Tab from './Tab';

const ContentContainer = styled(MainLayout.Container)`
  background-color: #fafbfc;
`;

const MachineContent = ({ tab }) => {
  const tabIdentifier = tab.id;
  switch (tabIdentifier) {
    case 'cycleCount':
      return <CycleCountStatus />;
    case 'partCount':
      return <MachineStatus />;
    case 'utilization':
      return <Utilization />;
    case 'downtime':
      return <Downtime />;
    case 'alarms':
      return <Alarms />;
    case 'partsGoal':
      return <PartsGoal />;
    case 'cycleTime':
      return <CycleTime />;
    case 'oee':
      return <OEE />;
    default:
      return null;
  }
};

const MachineMain = ({ tab: currentTab }) => {
  const history = useHistory();
  const machine = useSelector(getMachine);
  const tabs = useSelector(getVisibleTabs);
  const scopeStart = useSelector(getScopeStart);
  const homeTabRoute = useSelector(getHomeTabRoute);
  const customTabs = useSelector(getCustomTabs);
  const windowPath = window.location.pathname;

  if (!scopeStart || !homeTabRoute) {
    // prevent renders for invalid scope
    return null;
  }

  return (
    <MMEmbeddableZoneProvider>
      <Subheader
        defaultValue={currentTab}
        onChange={(value) => {
          return history.push(Routes.machineIdHomeTabPath(machine.id, value));
        }}
      >
        {tabs.length > 1 &&
          tabs.map((tab) => {
            return <Tab key={tab.id} tab={tab} />;
          })}
      </Subheader>
      <ContentContainer>
        <CustomTabAuthorizationModal />
        {customTabs.map((customTab) => {
          return (
            <CustomTab
              key={customTab.id}
              tab={customTab}
              base={Routes.machineIdHomeTabPath(machine.id, customTab.id)}
              hidden={
                windowPath !==
                Routes.machineIdHomeTabPath(machine.id, customTab.id)
              }
            />
          );
        })}
        <Switch>
          {tabs.map((tab) => {
            return (
              <Route
                key={tab.id}
                path={Routes.machineIdHomeTabPath(machine.id, tab.id)}
              >
                <MachineContent tab={tab} />
              </Route>
            );
          })}
          <Route path={Routes.machineIdHomePath(machine.id)}>
            <Redirect to={homeTabRoute} />
          </Route>
        </Switch>
      </ContentContainer>
    </MMEmbeddableZoneProvider>
  );
};

MachineMain.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default MachineMain;
