import React, { useRef, useEffect, useState, useMemo } from 'react';
import _capitalize from 'lodash/capitalize';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Icon } from '@m12s/component-library';
import { cube, playSolid } from 'lib/icons';
import { getLatestJobName } from 'lib/selectors/getLatestJobName';
import { getLatestActivityType } from 'lib/selectors/getLatestActivityType';
import { getIsLatestActivitySetOpen } from 'lib/selectors/getIsLatestActivitySetOpen';
import { getIsRunningUntaggedOperation } from 'lib/selectors/getIsRunningUntaggedOperation';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getProgramName } from 'lib/selectors/getProgramName';
import useOpenOperationSelection from 'lib/hooks/useOpenOperationSelection';
import { WorkOrderInfoGroup } from './WorkOrderInfoGroup';
import {
  Text,
  TouchAreaContainer,
  OperationName,
  TextSpacer,
  MachineHeaderButtonText,
  StartJobMachineHeaderButton,
  ProgramName,
} from './styled';

const TouchArea = ({ variant, onClickToOverview, setTouchPointRight }) => {
  const windowSize = useWindowDimensions();
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(windowSize.width);
  useEffect(() => {
    setWindowWidth(windowSize.width);
  }, [windowSize]);
  const isRunningUntaggedOp = useSelector(getIsRunningUntaggedOperation);
  const jobNameRaw = useSelector(getLatestJobName);
  const jobName = useMemo(() => {
    return isRunningUntaggedOp ? t(jobNameRaw) : jobNameRaw;
  }, [isRunningUntaggedOp, jobNameRaw, t]);

  const isLatestActivitySetOpen = useSelector(getIsLatestActivitySetOpen);
  const latestActivityType = useSelector(getLatestActivityType);

  const latestActivityName = useMemo(() => {
    return _get(latestActivityType, 'name', '');
  }, [latestActivityType]);

  const isPaused = useSelector(getIsPaused);
  const programName = useSelector(getProgramName);
  const openOperationSelection = useOpenOperationSelection();

  const bottomLeftRef = useRef();
  // need this to calculate the tablet touch point width
  useEffect(() => {
    const touchPointWidth = bottomLeftRef?.current?.getBoundingClientRect();
    if (touchPointWidth) {
      setTouchPointRight(touchPointWidth.width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  if (!isLatestActivitySetOpen) {
    return (
      <TouchAreaContainer
        id="touch-point"
        ref={bottomLeftRef}
        onTouchEnd={openOperationSelection}
      >
        <StartJobMachineHeaderButton
          onClick={openOperationSelection}
          variant={variant}
        >
          <Icon icon={playSolid} size="0.625rem" />
          <MachineHeaderButtonText variant={variant}>
            {t('Start Job')}
          </MachineHeaderButtonText>
        </StartJobMachineHeaderButton>

        <ProgramName variant={variant} muted={isPaused} noLineHeight>
          {programName && (
            <Text variant={variant}>
              {`${_capitalize(t('Program'))} ${programName}`}
            </Text>
          )}

          {!programName && (
            <Text variant={variant} shadow muted>
              {t('No Program Running')}
            </Text>
          )}
        </ProgramName>
      </TouchAreaContainer>
    );
  }

  return (
    <TouchAreaContainer
      id="touch-point"
      ref={bottomLeftRef}
      onTouchEnd={onClickToOverview}
    >
      <WorkOrderInfoGroup
        variant={variant}
        onClickToOverview={onClickToOverview}
        isPaused={isPaused}
      />

      <OperationName variant={variant} muted={isPaused} noLineHeight>
        <Icon
          icon={cube}
          style={{
            marginRight: '0.5rem',
          }}
        />
        {jobName}
      </OperationName>
      <TextSpacer />
      <Text variant={variant} shadow muted noWrap>
        {latestActivityName}
        {isPaused && t('pausedInParens')}
      </Text>
      <TextSpacer />
    </TouchAreaContainer>
  );
};

export { TouchArea };
