import { getLaborScopeTotalParts } from 'lib/selectors/getScopeTotalParts';
import { getIsERPJobControlBarFlow } from 'lib/selectors/getIsERPJobControlBarFlow';
import { getRejectedParts } from 'lib/selectors/getRejectedParts';
import { createSelector } from 'reselect';
import { getNetSetupParts } from './getSetupParts';
import { getNetShiftSetupParts } from './getShiftSetupParts';
import { getSetupModeSpansShift } from './getSetupModeSpansShift';

const getSetupPartsDisplay = createSelector(
  [
    getNetSetupParts,
    getIsERPJobControlBarFlow,
    getRejectedParts,
    getLaborScopeTotalParts,
    getSetupModeSpansShift,
    getNetShiftSetupParts,
  ],
  (
    netSetupParts,
    showERPJobControlBar,
    rejectedParts,
    laborTicketPartsMade,
    setupModeSpansShift,
    netShiftSetupParts
  ) => {
    const parts = setupModeSpansShift ? netShiftSetupParts : netSetupParts;

    const displayParts = parts;

    return {
      isERPFlow: showERPJobControlBar,
      displayParts,
      rejectedParts,
      unsubmittedParts: laborTicketPartsMade,
    };
  }
);

export { getSetupPartsDisplay };
