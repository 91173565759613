import styled from 'styled-components';
import { palette } from '@m12s/component-library';
import { Small, Display } from 'components/Text';

const SetupPartsSubtext = styled(Small).attrs({
  muted: true,
})`
  color: ${palette.Grey700};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const SetupPartsDisplayContainer = styled(Display)`
  display: flex;
  flex-direction: column;
`;

const SetupPartsDisplayText = styled.span`
  color: ${palette.Grey700};
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem;
`;

export {
  SetupPartsSubtext,
  FlexRow,
  SetupPartsDisplayContainer,
  SetupPartsDisplayText,
};
