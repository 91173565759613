// React and Hooks
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import {
  actionSetOperatorIsLoggingIn,
  actionSetRecentOperatorRuns,
} from 'lib/actions';
// Selectors
import { getOperatorIsLoggingIn } from 'lib/selectors/getOperatorIsLoggingIn';
// Utils
import { START_OPERATOR } from 'lib/api/mutations';

// Components

/**
 * Hook to manage operator login process using GraphQL mutation
 * @returns {Function} A function that starts an operator session:
 *   - Returns null if another operator is currently logging in
 *   - Otherwise returns a Promise resolving to the mutation result
 *
 * @example
 * const startOperator = useStartOperator();
 * const result = await startOperator({
 *   variables: {
 *     input: {
 *       machineId: '123',
 *       operatorId: '456',
 *       startAt: '2023-01-01T00:00:00Z'
 *     }
 *   }
 * });
 */
const useStartOperator = () => {
  const dispatch = useDispatch();
  const isLoggingIn = useSelector(getOperatorIsLoggingIn);

  const [startOperator, { loading }] = useMutation(START_OPERATOR, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ startOperatorResponse }) => {
      if (startOperatorResponse) {
        const { machine, operator, ...operatorRun } = startOperatorResponse;
        const mappedPayload = {
          operatorRuns: [
            {
              ...operatorRun,
              machineId: machine?.id,
              operatorId: operator?.id,
            },
          ],
        };
        dispatch(actionSetRecentOperatorRuns(mappedPayload));
      }
    },
  });

  useEffect(() => {
    dispatch(actionSetOperatorIsLoggingIn(loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  /**
   * @param {Object} args - The mutation arguments
   * @param {Object} args.variables - The variables for the START_OPERATOR mutation
   * @param {Object} args.variables.input - Input object for starting an operator
   * @param {string} args.variables.input.machineId - The ID of the machine
   * @param {string} args.variables.input.operatorId - The ID of the operator
   * @param {boolean} [args.variables.input.pinToPreviousEvent] - Optional flag to pin to previous event
   * @param {string} [args.variables.input.startAt] - Optional ISO timestamp for start time
   * @returns {Promise<{data?: {startOperatorResponse?: Object}, errors?: Array<import('@apollo/client').ApolloError>}>}
   */
  return async (args) => {
    if (isLoggingIn || loading) {
      return null;
    }
    return startOperator(args);
  };
};

export default useStartOperator;
