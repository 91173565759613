import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from '@m12s/component-library';

const PillWrapper = styled(Link)`
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  text-decoration: none;
  top: 100%;
  width: 100%;
  height: 2.5rem;

  cursor: default;
  margin-top: 1rem;

  @media (orientation: landscape) and (max-height: 575px) {
    padding: 0;
  }

  @media (orientation: landscape) and (max-height: 540px) {
    position: relative;
    padding: 1rem;
  }
`;

const PillText = styled.span`
  margin-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 9rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${palette.Grey700};
  letter-spacing: 0.003rem;
  line-height: 1.25rem;
`;

export { PillWrapper, PillText };
