import styled from 'styled-components';
import { Icon } from '@m12s/component-library';

import { H5 } from 'components/Text';

const DrawerHeader = styled.div`
  align-items: center;
  background: ${({ background }) => {
    return background || 'linear-gradient(180deg, #9f7aea 0%, #9272db 100%)';
  }};
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
`;

const DrawerTitle = styled(H5)`
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
`;

const DrawerHeaderIcon = styled(Icon)`
  font-size: 2.5rem;
  line-height: 2.5rem;
`;

const DrawerButtons = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(
    ${({ children }) => {
      return children.length || 1;
    }},
    1fr
  );
  justify-content: space-between;
  padding: 2rem;
`;

const DrawerBody = styled.div`
  padding: 1.5rem;
  flex: 1;
`;

export {
  DrawerButtons,
  DrawerHeader,
  DrawerHeaderIcon,
  DrawerTitle,
  DrawerBody,
};
