// React and Hooks
import { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useGenerateWorkOrderRedirectPath from 'components/LaborTicket/hooks/useGenerateWorkOrderRedirectPath';
// Actions
// Selectors
import { getLaborTicketAction } from 'lib/selectors/getLaborTicketAction';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';

// Utils
import PropTypes from 'prop-types';
import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getEnableStopJobAndSignOut } from 'lib/selectors/getEnableStopJobAndSignOut';

/**
 * Custom hook to handle setup pause actions with stop job and sign out flag.
 *
 * @param {Object} params - The parameters for the hook .
 * @param {boolean} params.laborTicketMarkedAsComplete - Indicates if the labor ticket is marked as complete.
 * @param {Function} params.requestLaborTicketAction - Function to request a labor ticket action.
 * @param {Function} params.handleSubmit - Function to handle form submission.
 * @returns {Object} The state and handlers for the setup pause actions.
 */
const useSetupPauseActionsWithStopJobAndSignOutFlag = ({
  laborTicketMarkedAsComplete,
  requestLaborTicketAction,
  handleSubmit,
}) => {
  const [isLogout, setIsLogout] = useState(false);
  const [showIncompleteSetupModal, setShowIncompleteSetupModal] = useState(
    false
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const workOrderRedirectPath = useGenerateWorkOrderRedirectPath();

  const laborTicketAction = useSelector(getLaborTicketAction);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);

  const isStopAndCreate =
    laborTicketAction === LABOR_TICKET_ACTIONS.STOP_AND_CREATE;
  const enableStopJobAndSignOut = useSelector(getEnableStopJobAndSignOut);

  const shouldShowIncompleteSetupModal = useMemo(() => {
    return (
      enableStopJobAndSignOut &&
      hasOpenSetupActivity &&
      isStopAndCreate &&
      !laborTicketMarkedAsComplete
    );
  }, [
    enableStopJobAndSignOut,
    hasOpenSetupActivity,
    isStopAndCreate,
    laborTicketMarkedAsComplete,
  ]);

  // close the confirmation modal and open the incomplete setup modal
  const handleCancelConfirmationModal = () => {
    setShowConfirmationModal(false);
    setShowIncompleteSetupModal(true);
  };

  // close the incomplete setup modal and open the confirmation modal
  const handleConfirmStopJob = () => {
    setShowIncompleteSetupModal(false);
    setShowConfirmationModal(true);
  };

  // close both modals
  const handleClose = () => {
    setShowConfirmationModal(false);
    setShowIncompleteSetupModal(false);
  };

  const handleStopJob = useCallback(async () => {
    if (isLogout) {
      return requestLaborTicketAction({
        action: LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT,
        opts: {
          redirectPath: workOrderRedirectPath,
          shouldStopCurrentRun: true, // This ensures the current run is stopped
          switchToUnattendedOperator: true,
        },
      });
    }
    return handleSubmit({
      redirectPath: workOrderRedirectPath,
    });
  }, [
    handleSubmit,
    isLogout,
    workOrderRedirectPath,
    requestLaborTicketAction,
    // requestOperatorLogout,
  ]);

  const handlePause = () => {
    setShowIncompleteSetupModal(false);
    if (isLogout) {
      return requestLaborTicketAction({
        action: LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT,
        opts: {
          switchToUnattendedOperator: true,
          shouldPauseSetup: true,
        },
      });
    }
    return requestLaborTicketAction({
      action: LABOR_TICKET_ACTIONS.PAUSE_SETUP,
    });
  };

  return {
    isLogout,
    setIsLogout,
    setShowIncompleteSetupModal,
    showIncompleteSetupModal,
    showConfirmationModal,
    shouldShowIncompleteSetupModal,
    handleCancelConfirmationModal,
    handleConfirmStopJob,
    handleStopJob,
    handlePause,
    handleClose,
  };
};

useSetupPauseActionsWithStopJobAndSignOutFlag.propTypes = {
  laborTicketMarkedAsComplete: PropTypes.bool.isRequired,
  requestLaborTicketAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default useSetupPauseActionsWithStopJobAndSignOutFlag;
