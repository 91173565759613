import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@m12s/component-library';
import { useSelector, useDispatch } from 'react-redux';
import { now, toISO } from 'lib/utils/date';
import { throwSuccessToast, throwErrorToast } from 'lib/utils/toast';
import {
  actionOptimisticActivitySetClose,
  actionCanonicalActivitySetClose,
  actionCanonicalActivitySetReopen,
  actionOptimisticActivityCreate,
  actionCanonicalActivityCreate,
  actionCanonicalActivityDelete,
} from 'lib/actions';
import { AlertModal } from 'components/AlertModal';
import { exclamationTriangle, questionCircle } from 'lib/icons';
import { UPDATE_ACTIVITY_SET, CREATE_ACTIVITY } from 'lib/api/mutations';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getIsChangeActivitiesLoading } from 'lib/selectors/getIsChangeActivitiesLoading';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';
import { getHasOpenActivitySetInSetup } from 'lib/selectors/getHasOpenActivitySetInSetup';
import Routes from 'lib/utils/routes';
import { getMachine } from 'lib/selectors/getMachine';
import { getLatestJobName } from 'lib/selectors/getLatestJobName';
import { CONTROL_ACTIONS, TEMP_REFS } from 'lib/constants';
import { useCreateActivityRequestParams } from 'lib/hooks/useCreateActivityRequestParams';
import { getSetupActivityTypes } from 'lib/selectors/getSetupActivityTypes';
import { getProductionActivityTypes } from 'lib/selectors/getProductionActivityTypes';
import { getIsRunningUntaggedOperation } from 'lib/selectors/getIsRunningUntaggedOperation';

const { ACTIVITY_REF: optimisticActivityRef } = TEMP_REFS;

const { STOP, RESUME, SWITCH_TO_SETUP, SWITCH_TO_PRODUCTION } = CONTROL_ACTIONS;

const getModalPromptStyling = ({
  theme,
  controlAction,
  hasOpenActivitySetInSetup,
}) => {
  if (
    controlAction === STOP ||
    (controlAction === RESUME && !hasOpenActivitySetInSetup)
  ) {
    return { headerColor: theme.accents.danger, icon: exclamationTriangle };
  }
  return { headerColor: theme.accents.secondary, icon: questionCircle };
};

const getModalPromptText = ({
  controlAction,
  hasOpenActivitySetInSetup,
  activityTypeName,
  t,
}) => {
  if (controlAction === RESUME) {
    return hasOpenActivitySetInSetup
      ? t('Resume this Setup?')
      : t('Are you sure you want to resume this Operation?');
  }
  if (controlAction === STOP) {
    return t('Stop this operation?');
  }
  // if none of the above, assume we are transitioning activities for a customer with only two types
  return t('Are you sure you want to switch to {{activityTypeName}}?', {
    activityTypeName,
  });
};

const ControlActionModal = ({ controlAction, setControlAction }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const machine = useSelector(getMachine);
  const latestActivity = useSelector(getLatestActivity);
  const latestActivitySet = useSelector(getLatestActivitySet);
  const hasOpenActivitySetInSetup = useSelector(getHasOpenActivitySetInSetup);
  // TODO: only limited to one type for r1, but this will need to be updated
  // to support multiple types of same mode ('production' in this case)
  const isChangeActivitiesLoading = useSelector(getIsChangeActivitiesLoading);
  const theme = useTheme();
  const createActivityRequestParams = useCreateActivityRequestParams();
  const setupActivityTypes = useSelector(getSetupActivityTypes);
  const productionActivityTypes = useSelector(getProductionActivityTypes);
  const isRunningUntaggedOp = useSelector(getIsRunningUntaggedOperation);
  const jobNameRaw = useSelector(getLatestJobName);
  const jobName = isRunningUntaggedOp ? t(jobNameRaw) : jobNameRaw;
  const activityTypeName = useMemo(() => {
    // we can safely assume "default" type here when only two activity types exist
    if (controlAction === SWITCH_TO_SETUP) {
      return setupActivityTypes?.[0]?.name || null;
    }
    if (controlAction === SWITCH_TO_PRODUCTION) {
      return productionActivityTypes?.[0]?.name || null;
    }
    return null;
  }, [controlAction, setupActivityTypes, productionActivityTypes]);
  const [requestCreateActivity, { loading }] = useMutation(CREATE_ACTIVITY, {
    fetchPolicy: 'no-cache', // this ensures update will only be called once for mutation
    update: (
      _cache,
      {
        data: {
          createActivityResponse: { activity },
        },
      }
    ) => {
      setControlAction(null);
      dispatch(actionOptimisticActivityCreate({ activity }));
    },
    onCompleted: ({ createActivityResponse: { activity } }) => {
      throwSuccessToast(t('Resumed:', { jobName }));
      dispatch(
        actionCanonicalActivityCreate({ activity, optimisticActivityRef })
      );
    },
    onError: () => {
      setControlAction(null);
      dispatch(
        actionCanonicalActivityDelete({ activityRef: optimisticActivityRef })
      );
      throwErrorToast(t('Could not resume:', { jobName }));
    },
  });
  const [requestUpdateActivitySet] = useMutation(UPDATE_ACTIVITY_SET, {
    fetchPolicy: 'no-cache',
    update: (
      _cache,
      {
        data: {
          updateActivitySetResponse: {
            activitySet: {
              activities: [activity],
              ...closedActivitySet
            },
          },
        },
      }
    ) => {
      setControlAction(null);
      dispatch(
        actionOptimisticActivitySetClose({
          activitySet: {
            activitySetRef: closedActivitySet.activitySetRef,
            closedAt: closedActivitySet.closedAt,
          },
          activity: {
            activityRef: activity.activityRef,
            end: activity.end,
          },
        })
      );
    },
    onCompleted: ({
      updateActivitySetResponse: {
        activitySet: {
          activities: [activity], // should be sorted start ASC
          ...closedActivitySet
        },
      },
    }) => {
      if (closedActivitySet.workOrderId) {
        throwSuccessToast(`Stopped order ${closedActivitySet.workOrderId}`);
      } else {
        throwSuccessToast(t('Stopped:', { jobName }));
      }
      dispatch(
        actionCanonicalActivitySetClose({
          activitySet: closedActivitySet,
          activity,
        })
      );
    },
    onError: () => {
      setControlAction(null);
      dispatch(
        actionCanonicalActivitySetReopen({
          activitySet: {
            activitySetRef: latestActivitySet.activitySetRef,
            closedAt: null,
          },
          activity: {
            activityRef: latestActivity.activityRef,
            end: null,
          },
        })
      );
      throwErrorToast(t('Could not stop:', { jobName }));
    },
  });

  /* eslint-disable consistent-return */
  async function triggerControlActionQuery(modalAction) {
    const handleResume = async () => {
      if (loading || isChangeActivitiesLoading) {
        return;
      }
      await requestCreateActivity(
        createActivityRequestParams(latestActivity.activityTypeRef)
      );
    };

    switch (modalAction) {
      case RESUME: {
        await handleResume();
        break;
      }
      case STOP: {
        const nowISO = toISO(now().valueOf());
        await requestUpdateActivitySet({
          variables: {
            input: {
              machineRef: latestActivitySet.machineRef,
              activitySetRef: latestActivitySet.activitySetRef,
              closedAt: nowISO,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateActivitySetResponse: {
              __typename: 'ActivitySet',
              activitySet: {
                ...latestActivitySet,
                closedAt: nowISO,
                activities: [
                  {
                    ...latestActivity,
                    // only close out activity if open
                    end: latestActivity.end || nowISO,
                  },
                ],
              },
            },
          },
        });
        break;
      }
      case SWITCH_TO_SETUP: {
        await requestCreateActivity(
          createActivityRequestParams(setupActivityTypes[0].activityTypeRef)
        );
        break;
      }
      case SWITCH_TO_PRODUCTION: {
        await requestCreateActivity(
          createActivityRequestParams(
            productionActivityTypes[0].activityTypeRef
          )
        );
        break;
      }
      default:
        break;
    }
    history.replace(Routes.machineIdHomePath(machine.id));
  }

  return (
    <AlertModal
      isOpen
      loading={loading || isChangeActivitiesLoading}
      promptText={getModalPromptText({
        controlAction,
        hasOpenActivitySetInSetup,
        activityTypeName,
        t,
      })}
      descriptionText={
        controlAction === RESUME &&
        !hasOpenActivitySetInSetup &&
        'This Production Run is paused. Resuming will continue the previous Production Run. Current metrics (including part count) from the same shift will be retained. Parts and metrics from earlier Activities will still be associated with this Production Run in the MachineMetrics app data.'
      }
      headerColor={
        getModalPromptStyling({
          theme,
          controlAction,
          hasOpenActivitySetInSetup,
        }).headerColor
      }
      icon={
        getModalPromptStyling({
          theme,
          controlAction,
          hasOpenActivitySetInSetup,
        }).icon
      }
      onConfirm={() => {
        triggerControlActionQuery(controlAction);
      }}
      onClose={() => {
        setControlAction(null);
      }}
    />
  );
};

export { ControlActionModal };
