import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _startCase from 'lodash/startCase';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { formatValue } from 'lib/utils/text';
import { getQualityDecimal } from 'lib/selectors/getQualityDecimal';

import { CounterBlock } from '@m12s/component-library';
import { CounterLabel, WheelCounterPadding } from 'components/WheelLayout';
import { TextMedia } from 'pages/Machine/styled';
import { LoadingCounterValue } from './Widgets'


export const QualityMeter = () => {
  const { t } = useTranslation();
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  const qualityDecimal = useSelector(getQualityDecimal);
  const qualityLabel = parseInt(qualityDecimal, 10) * 100;
  return (
    <WheelCounterPadding top left>
      <CounterBlock
        label={
          <CounterLabel loading={arePartCountBucketsLoading}>
            <TextMedia min={40}>
              {_startCase(t('Quality'))}
            </TextMedia>
            <TextMedia max={40}>{_startCase(t('Quality'))}</TextMedia>
          </CounterLabel>
        }
        value={
          arePartCountBucketsLoading ? (
            <LoadingCounterValue />
          ) : (
              `${formatValue(qualityLabel)}%`
            )
        }
      />
    </WheelCounterPadding>
  )
}
