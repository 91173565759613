import React from 'react';
import { useQuery } from '@apollo/client';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useStartOperator from 'lib/hooks/useStartOperator';

import Routes from 'lib/utils/routes';
import { now, toISO } from 'lib/utils/date';
import { DefaultLoading } from 'components/Loading';
import { UserSelect } from 'components/UserSelect';
import { getMachine } from 'lib/selectors/getMachine';
import { GET_OPERATORS } from 'lib/api/queries';
import {
  actionCloseSelectOperator,
  actionOpenLaborTicketModal,
  actionSetOperators,
} from 'lib/actions';

import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import useSubmitLaborTicket from 'components/LaborTicket/useSubmitLaborTicket';
import { getCanCloseLaborTicket } from 'lib/selectors/getCanOpenLaborTicket';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getIsSetupPaused } from 'lib/selectors/getIsSetupPaused';
import { throwErrorToast } from 'lib/utils/toast';
import {
  getSelectOperatorOpen,
  getSelectOperatorOptions,
} from 'lib/selectors/getSelectOperator';
import { getIsLaborTicketEnabled } from 'lib/selectors/getIsLaborTicketEnabled';

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAPMEnabled = useSelector(getIsAPMEnabled);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);

  const { loading } = useQuery(GET_OPERATORS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ Operator: operators }) => {
      dispatch(actionSetOperators(operators));
    },
  });

  const startOperator = useStartOperator();

  const machine = useSelector(getMachine);
  const { t } = useTranslation();

  const laborTicketEnabled = useSelector(getIsLaborTicketEnabled);
  const canCloseLaborTicket = useSelector(getCanCloseLaborTicket);
  const isSetupPaused = useSelector(getIsSetupPaused);

  const selectOperatorOpen = useSelector(getSelectOperatorOpen);
  const selectOperatorOptions = useSelector(getSelectOperatorOptions);

  const requestLaborTicketAction = useSubmitLaborTicket();
  const nowISO = toISO(now().valueOf());

  const redirectPath =
    selectOperatorOptions?.redirectPath ||
    (machine?.id && Routes.machineIdHomePath(machine.id));

  const redirectAndClose = () => {
    history.push(redirectPath);
    return dispatch(actionCloseSelectOperator());
  };

  if (loading) return <DefaultLoading />;

  const onSubmit = async (operator, opts = {}) => {
    try {
      const { errors } = await startOperator({
        variables: {
          input: {
            machineId: machine.id,
            operatorId: operator.id,
            pinToPreviousEvent: opts.pinToPreviousEvent,
            startAt: opts.startAt || nowISO,
          },
        },
      });

      if (errors) {
        throwErrorToast(t('Something went wrong'), {
          autoClose: 1500,
        });
      } else {
        if (laborTicketEnabled && !isAPMEnabled) {
          // if there is a previous labor ticket that can be closed,
          // open the labor ticket modal to submit the labor ticket,
          // parts, etc.
          if (canCloseLaborTicket) {
            dispatch(
              actionOpenLaborTicketModal(LABOR_TICKET_ACTIONS.OPERATOR_LOGIN)
            );
            return redirectAndClose();
          }
          // otherwise, create a new labor ticket
          if (operator.erpId && currentWorkOrderOp && !isSetupPaused) {
            dispatch(actionCloseSelectOperator());
            return requestLaborTicketAction({
              action: LABOR_TICKET_ACTIONS.OPERATOR_LOGIN,
              opts: {
                startAt: nowISO,
                loggingInOperator: operator.id,
              },
            });
          }
        }
        // for regular flow, redirect to home page
        return redirectAndClose();
      }
    } catch (e) {
      if (e?.networkError) {
        if (e?.networkError?.result?.errors?.length > 0) {
          return e?.networkError?.result?.errors?.map(({ message }) => {
            return throwErrorToast(message, {
              autoClose: 1500,
            });
          });
        }
        if (e?.networkError?.result?.message) {
          return throwErrorToast(e?.networkError?.result?.message, {
            autoClose: 1500,
          });
        }
      }
    }
    return null;
  };

  return (
    <UserSelect
      onSubmit={onSubmit}
      isOpen={selectOperatorOpen}
      onClose={() => {
        return dispatch(actionCloseSelectOperator());
      }}
    />
  );
};

export default LoginPage;
