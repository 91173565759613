import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';

import { getLaborScopeTotalParts } from 'lib/selectors/getScopeTotalParts';
import { getOrderProgress } from 'lib/selectors/getOrderProgress';

import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';
import { getLaborTicketAction } from 'lib/selectors/getLaborTicketAction';
import { getProductionActivityTypeRefs } from 'lib/selectors/getProductionActivityTypeRefs';
import { getEnableStopJobAndSignOut } from 'lib/selectors/getEnableStopJobAndSignOut';
import useGenerateWorkOrderRedirectPath from '../hooks/useGenerateWorkOrderRedirectPath';
import useSubmitLaborTicket from '../useSubmitLaborTicket';

import SetupStopJobOrPauseModal from '../SetupStopJobOrPauseModal';
import SetupConfirmStopJobModal from '../SetupConfirmStopJobModal';
import SetupIncompleteModal from '../SetupIncompleteModal';
import useSetupPauseActions from '../useSetupPauseActions';
import useSetupPauseActionsWithStopJobAndSignOutFlag from '../useSetupPauseActionsWithStopJobAndSignOutFlag';
import useProductionLaborTicketStopOrLeaveModal from '../useProductionLaborTicketStopOrLeaveModal';
import useLaborTicketMarkAsCompleteModal from '../useLaborTicketMarkAsCompleteModal';
import useOperatorLogout from '../hooks/useOperatorLogout';
import LaborTicketCurrent from './Content/Current';
import LaborTicketJobTotal from './Content/JobTotal';
import { TicketInfoContainer } from './styled';
import Footer from './Footer';

const mapLaborTicketAction = (action) => {
  switch (action) {
    case LABOR_TICKET_ACTIONS.STOP_AND_CREATE:
      return LABOR_TICKET_ACTIONS.STOP_RUN;
    case LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT:
      return LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT;
    default:
      return action;
  }
};

const LaborTicketCard = ({ form, loading, handleFormChange }) => {
  const enableStopJobAndSignOut = useSelector(getEnableStopJobAndSignOut);

  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const orderProgress = useSelector(getOrderProgress);

  const [initialState, setInitialState] = useState({
    workOrderOp: currentWorkOrderOp,
  });

  useEffect(() => {
    setInitialState({
      workOrderOp: currentWorkOrderOp,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { workOrderOp } = initialState;

  const laborTicketPartsMade = useSelector(getLaborScopeTotalParts);

  const partAdjustment = form.totalParts.value - laborTicketPartsMade;
  const rejectedParts = form.rejectedParts?.value || 0;

  const requestLaborTicketAction = useSubmitLaborTicket({ form });

  const orderTotalParts = orderProgress + partAdjustment - rejectedParts;

  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);
  const laborTicketAction = useSelector(getLaborTicketAction);
  const productionActivityTypes = useSelector(getProductionActivityTypeRefs);

  const requestOperatorLogout = useOperatorLogout();

  const laborTicketMarkedAsComplete = useMemo(() => {
    return form?.workOrderComplete?.value === true;
  }, [form]);

  const workOrderRedirectPath = useGenerateWorkOrderRedirectPath({
    excludeRefFromPath: laborTicketMarkedAsComplete,
  });

  const laborTicketFullfilled = useMemo(() => {
    return orderTotalParts >= workOrderOp?.finishQuantity;
  }, [orderTotalParts, workOrderOp]);

  const shouldStopSetupAndStartProduction = useMemo(() => {
    return (
      [
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE,
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT,
      ].includes(laborTicketAction) && hasOpenSetupActivity === true
    );
  }, [laborTicketAction, hasOpenSetupActivity]);

  const shouldPauseSetupAndLogout = useMemo(() => {
    return (
      enableStopJobAndSignOut &&
      LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT ===
        laborTicketAction &&
      hasOpenSetupActivity === true &&
      !laborTicketMarkedAsComplete
    );
  }, [
    enableStopJobAndSignOut,
    laborTicketAction,
    hasOpenSetupActivity,
    laborTicketMarkedAsComplete,
  ]);

  /** Production */
  const shouldSelectJobAfterStopProduction = useMemo(() => {
    return (
      [
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE,
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT,
      ].includes(laborTicketAction) && hasOpenSetupActivity !== true
    );
  }, [laborTicketAction, hasOpenSetupActivity]);

  const stopJobAndSignout = () => {
    if (enableStopJobAndSignOut) {
      return requestLaborTicketAction({
        action: LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT,
        opts: {
          shouldStopCurrentRun: false,
        },
      });
    }

    return null;
  };

  const [
    ProductionLaborTicketStopOrLeaveModal,
    setProductionStopOrLeaveModalOpen,
    productionRequestStopOrLeaveConfirmation,
  ] = useProductionLaborTicketStopOrLeaveModal({
    laborTicketMarkedAsComplete,
    enableStopJobAndSignOut,
    handleStopJob: () => {
      if (
        laborTicketAction ===
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT
      ) {
        return requestLaborTicketAction({
          action: LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT,
          opts: {
            switchToUnattendedOperator: true,
            shouldStopCurrentRun: true,
          },
        });
      }

      if (shouldSelectJobAfterStopProduction) {
        return handleSubmit({
          redirectPath: workOrderRedirectPath,
        });
      }
      return handleSubmit();
    },
    handleLeaveOpen: async () => {
      if (shouldSelectJobAfterStopProduction) {
        if (
          laborTicketAction ===
          LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT
        ) {
          await requestLaborTicketAction({
            action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
            opts: {
              pendingActivityTypeRef: productionActivityTypes[0],
              switchToUnattendedOperator: true,
            },
          });

          return requestOperatorLogout();
        }

        return requestLaborTicketAction({
          action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
          opts: {
            pendingActivityTypeRef: productionActivityTypes[0],
          },
        });
      }
      return handleSubmit();
    },
    handleStopJobAndSignOut: () => {
      return stopJobAndSignout();
    },
  });

  const [
    MarkAsCompleteModal,
    setMarkAsCompleteModalOpen,
    requestMarkAsCompleteConfirmation,
  ] = useLaborTicketMarkAsCompleteModal({
    laborTicketMarkedAsComplete,
    laborTicketFullfilled,
    handleMarkAsComplete: () => {
      if (shouldSelectJobAfterStopProduction) {
        return handleSubmit({
          redirectPath: workOrderRedirectPath,
          closeOrderOperation: true,
        });
      }

      return handleSubmit({
        closeOrderOperation: true,
      });
    },
  });

  const handleSubmit = async (opts) => {
    return requestLaborTicketAction({
      action: mapLaborTicketAction(laborTicketAction),
      opts,
    });
  };

  const {
    isSetupPauseActionsModalOpen,
    setIsSetupPauseModalOpen,
    canOpenSetupPauseActionModal,
    isSetupConfirmStopJobModalOpen,
    ...setupStopJobOrPauseProps
  } = useSetupPauseActions({
    laborTicketMarkedAsComplete,
    handleSubmit,
    requestLaborTicketAction,
  });

  const { handlePause: pauseOperationAndLogout } = useSetupPauseActions({
    laborTicketMarkedAsComplete,
    handleSubmit,
    requestLaborTicketAction,
  });

  const {
    shouldShowIncompleteSetupModal,
    setShowIncompleteSetupModal,
    isLogout,
    showConfirmationModal,
    handleCancelConfirmationModal,
    ...setupPauseActionProps
  } = useSetupPauseActionsWithStopJobAndSignOutFlag({
    laborTicketMarkedAsComplete,
    requestLaborTicketAction,
    handleSubmit,
  });

  const setupIncompleteModalProps = {
    ...setupPauseActionProps,
    setShowIncompleteSetupModal,
    isLogout,
  };

  const submitToErp = async () => {
    if (!loading) {
      if (form.workOrderComplete.value === null) {
        return handleFormChange(
          'workOrderComplete',
          null,
          `You must select a status for the ${
            hasOpenSetupActivity ? 'setup' : 'production'
          }`
        );
      }

      if (shouldPauseSetupAndLogout) {
        return pauseOperationAndLogout();
      }

      if (shouldShowIncompleteSetupModal) {
        return setShowIncompleteSetupModal(true);
      }

      if (canOpenSetupPauseActionModal) {
        return setIsSetupPauseModalOpen(true);
      }

      if (productionRequestStopOrLeaveConfirmation) {
        return setProductionStopOrLeaveModalOpen(true);
      }

      if (requestMarkAsCompleteConfirmation) {
        return setMarkAsCompleteModalOpen(true);
      }

      if (shouldSelectJobAfterStopProduction) {
        return handleSubmit({
          redirectPath: workOrderRedirectPath,
        });
      }

      // setup complete
      if (laborTicketMarkedAsComplete && shouldStopSetupAndStartProduction) {
        if (
          laborTicketAction ===
          LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT
        ) {
          await requestLaborTicketAction({
            action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
            opts: {
              pendingActivityTypeRef: productionActivityTypes[0],
              switchToUnattendedOperator: true,
            },
          });

          return requestOperatorLogout();
        }

        return requestLaborTicketAction({
          action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
          opts: {
            pendingActivityTypeRef: productionActivityTypes[0],
          },
        });
      }

      if (laborTicketMarkedAsComplete) {
        return handleSubmit({
          shouldStopCurrentRun: true,
        });
      }

      return handleSubmit();
    }
    return null; // Ensure a value is returned at the end
  };

  return (
    <>
      <SetupIncompleteModal {...setupIncompleteModalProps} />
      <SetupConfirmStopJobModal
        isOpen={showConfirmationModal}
        handleCancel={handleCancelConfirmationModal}
        isLogout={isLogout}
        {...setupIncompleteModalProps}
      />
      <SetupStopJobOrPauseModal
        {...setupStopJobOrPauseProps}
        isOpen={isSetupPauseActionsModalOpen}
      />
      <SetupConfirmStopJobModal
        {...setupStopJobOrPauseProps}
        isOpen={isSetupConfirmStopJobModalOpen}
      />
      {MarkAsCompleteModal}
      {ProductionLaborTicketStopOrLeaveModal}

      <TicketInfoContainer>
        <LaborTicketCurrent form={form} />
        <LaborTicketJobTotal form={form} />
      </TicketInfoContainer>
      <Footer form={form} loading={loading} submitToErp={submitToErp} />
    </>
  );
};

LaborTicketCard.propTypes = {
  form: PropTypes.object,
  loading: PropTypes.bool,
  handleFormChange: PropTypes.func,
};

export default LaborTicketCard;
