import { createSelector } from 'reselect';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getOEEMetrics } from 'lib/selectors/getOEEMetrics';

/*
  calculating quality: https://machinemetrics.quip.com/bDM9AOj7yo6q
  quality: good parts / total parts

  We are now using the production API for quality, so we no longer need to calculate it here
*/

const getQualityDecimal = createSelector(
  [getHasOpenActivity, getOEEMetrics],
  (hasOpenActivity, oeeMetrics) => {
    if (
      // metric is only relevant when active / job is running and unpaused
      !hasOpenActivity
    ) {
      return null;
    }
    return oeeMetrics?.quality || null;
  }
);

export { getQualityDecimal };
