import React from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';

import { CounterBlock } from '@m12s/component-library';
import { CounterLabel } from 'components/WheelLayout';
import { TextMedia } from 'pages/Machine/styled';

import { formatValue } from 'lib/utils/text';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { getProductionPartsDisplay } from 'lib/selectors/getProductionPartsDisplay';
import {
  FlexRow,
  LoadingCounterValue,
  ProductionPartsDisplayContainer,
  ProductionPartsDisplayText,
  ProductionPartsSubtext,
} from './styled';

const ProductionPartsDisplay = () => {
  const { t } = useTranslation();

  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);

  const {
    isERPFlow,
    displayParts,
    rejectedParts,
    unsubmittedParts,
  } = useSelector(getProductionPartsDisplay);

  return (
    <CounterBlock
      label={
        <CounterLabel loading={arePartCountBucketsLoading}>
          <TextMedia min={40}>{_startCase(t('you have made'))}</TextMedia>
          <TextMedia max={40}>{_startCase(t('current'))}</TextMedia>
        </CounterLabel>
      }
      value={
        arePartCountBucketsLoading ? (
          <LoadingCounterValue />
        ) : (
          <ProductionPartsDisplayContainer>
            <FlexRow>
              {' '}
              <ProductionPartsDisplayText>
                {formatValue(displayParts)?.toLocaleString()}
              </ProductionPartsDisplayText>
            </FlexRow>
            {isERPFlow && (
              <ProductionPartsSubtext>
                {t('Rejected and unsubmitted', {
                  rejected: rejectedParts,
                  unsubmitted: unsubmittedParts,
                })}
              </ProductionPartsSubtext>
            )}
          </ProductionPartsDisplayContainer>
        )
      }
    />
  );
};

export { ProductionPartsDisplay };
