import { createSelector } from 'reselect';

import { FLAG_KEYS } from 'lib/constants';
import { getLaunchDarklyFlags } from './getLaunchDarklyFlags';

const getEnableStopJobAndSignOut = createSelector(
  [getLaunchDarklyFlags],
  (flags) => {
    return flags?.[FLAG_KEYS.STOP_INCOMPLETE_JOB_AND_SIGN_OUT_ENABLED];
  }
);

export { getEnableStopJobAndSignOut };
