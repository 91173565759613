import { createSelector } from 'reselect';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';
import { getActivityTypes } from './getActivityTypes';

const getOpenActivity = createSelector(
  [getAreActivitiesEnabled, getLatestActivity],
  (areActivitiesEnabled, latestActivity) => {
    if (
      !areActivitiesEnabled ||
      !latestActivity ||
      latestActivity.end !== null
    ) {
      return null;
    }
    return latestActivity;
  }
);

const getOpenActivityTypeRef = createSelector(
  [getOpenActivity],
  (openActivity) => {
    return openActivity?.activityTypeRef;
  }
);

const getOpenActivityTypeMode = createSelector(
  [getOpenActivity, getActivityTypes],
  (openActivity, activityTypes) => {
    return openActivity && activityTypes?.length
      ? activityTypes.find(({ activityTypeRef }) => {
          return activityTypeRef === openActivity?.activityTypeRef;
        })?.activityMode
      : null;
  }
);

export { getOpenActivity, getOpenActivityTypeRef, getOpenActivityTypeMode };
