import { createSelector } from 'reselect';

import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getOEEMetrics } from 'lib/selectors/getOEEMetrics';

/*
  calculating perf: https://machinemetrics.quip.com/bDM9AOj7yo6q
  performance: idealPartTime / (scheduledTimeInCycle / scheduledTotalParts)

  Also see canonical performance definition in report builder @
    https://github.com/machinemetrics/Dashboard/blob/master/apis/warehouse/production/production.data.js)

  We are using the OEE metrics from the production API
  so we no longer need to calculate it here
*/

const getPerformanceDecimal = createSelector(
  [getHasOpenActivity, getOEEMetrics],
  (hasOpenActivity, oeeMetrics) => {
    if (
      // metric is only relevant when active / job is running and unpaused
      !hasOpenActivity
    ) {
      return null;
    }

    return oeeMetrics?.performance || null;
  }
);

export { getPerformanceDecimal };
