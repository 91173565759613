import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getLabsAuthorized } from 'lib/selectors/getLabsAuthorized';
import { LABS_URL } from 'lib/appConfig';
import { getMachine } from 'lib/selectors/getMachine';
import { getIsInitialActivitySetPollLoading } from 'lib/selectors/getIsInitialActivitySetPollLoading';

const Frame = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
  position: relative;
`;

const HTTP = /^http/i;

const CustomTabBasic = ({ url }) => {
  const job = useSelector(getLatestJob);
  const activitiesLoading = useSelector(getIsInitialActivitySetPollLoading);
  const labAuthCompleted = useSelector(getLabsAuthorized);
  const machine = useSelector(getMachine);
  const isLabUrl = url.includes(LABS_URL?.replace('https://', ''));
  const authCompleted = !isLabUrl || labAuthCompleted;
  const tabDataLoaded = authCompleted && !activitiesLoading;

  const HTTPCheckedURL = (HTTP.test(url) ? url : `https://${url}`)
    .replace(
      'includeOperationInfo=true',
      `operationName=${job.name}&operationId=${job.jobId}&operationDisplayName=${job.displayName}`
    )
    .replace('includeMachineInfo=true', `machineId=${machine.id}`);

  if (!tabDataLoaded) {
    return <></>;
  }

  return (
    <Frame
      src={HTTPCheckedURL}
      allowFullScreen
      sandbox="allow-same-origin allow-scripts allow-forms"
    />
  );
};

export { CustomTabBasic };
