// React and Hooks
import React from 'react';
import { useTranslation } from 'react-i18next';
// Actions
// Selectors
// Utils
import PropTypes from 'prop-types';
// Components
import { palette } from '@m12s/component-library';
import LaborTicketBaseActionModal from 'components/LaborTicket/LaborTicketBaseActionModal';

import { ModalButton } from './styled';

/**
 * SetupConfirmStopJobModal component.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.isOpen - Determines if the modal is open.
 * @param {Function} props.handleCancel - Function to handle cancel action.
 * @param {Function} props.handleStopJob - Function to handle stop job action.
 * @param {Function} props.handleClose - Function to handle close action.
 * @param {boolean} props.isLogout - Determines if the user is logging out.
 * @returns {JSX.Element|null} The rendered component.
 */
const SetupConfirmStopJobModal = ({
  isOpen,
  handleCancel,
  handleStopJob,
  handleClose,
  isLogout,
}) => {
  const { t } = useTranslation();

  return (
    isOpen && (
      <LaborTicketBaseActionModal
        isOpen={isOpen}
        header={t('setupConfirmStopJobModal|header')}
        onClose={handleClose}
        details={<div>{t('setupConfirmStopJobModal|details')}</div>}
        buttons={[
          <ModalButton
            key="cancelButton"
            customStyles={{
              backgroundColor: palette.Grey200,
              color: palette.Grey700,
            }}
            hoverStyles={{
              backgroundColor: palette.Grey300,
            }}
            onClick={handleCancel}
          >
            {t('setupConfirmStopJobModal|buttons|cancel')}
          </ModalButton>,
          <ModalButton
            key="endSetupButton"
            customStyles={{
              backgroundColor: palette.Red700,
              color: palette.White,
            }}
            hoverStyles={{
              backgroundColor: palette.Red800,
            }}
            onClick={handleStopJob}
          >
            {isLogout
              ? t('setupConfirmStopJobModal|buttons|endSetupAndSignOut')
              : t('setupConfirmStopJobModal|buttons|endSetup')}
          </ModalButton>,
        ]}
      />
    )
  );
};

SetupConfirmStopJobModal.propTypes = {
  isOpen: PropTypes.bool,
  handleStopJob: PropTypes.func,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  isLogout: PropTypes.bool,
};

export default SetupConfirmStopJobModal;
