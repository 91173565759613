import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Routes from 'lib/utils/routes';
import {
  palette,
  Timetable,
  Thead,
  Th,
  Tbody,
  Td,
  Icon,
} from '@m12s/component-library';
import { P } from 'components/Text';
import {
  Cell,
  LoadingTbody,
  LoadingTd,
  LoadingText,
  Tag,
} from 'components/Table';
import { ErrorInline } from 'components/ErrorInline';
import { Empty } from 'components/Empty';
import { CREATE_ACTIVITY_SET } from 'lib/api/mutations';
import { now, toISO } from 'lib/utils/date';
import { angleRight } from 'lib/icons';
import { getMachine } from 'lib/selectors/getMachine';
import { ACTIVITY_MODES, TEMP_REFS } from 'lib/constants';
import {
  actionSetJobTotal,
  actionOptimisticActivitySetOpen,
  actionCanonicalActivitySetOpen,
  actionCanonicalActivitySetDelete,
} from 'lib/actions';
import { throwErrorToast, throwSuccessToast } from 'lib/utils/toast';
import { getActivitySetStandardsFromJob } from 'lib/utils/job';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getLatestJobId } from 'lib/selectors/getLatestJobId';
import { getOpenActivityTypeRef } from 'lib/selectors/getOpenActivity';
import { getSwitchActivityTypesOptions } from 'lib/selectors/getSwitchActivityTypesOptions';

const activityModes = {
  [ACTIVITY_MODES.SETUP]: {
    color: palette.Yellow500,
    name: 'Setup',
    order: 0,
  },
  [ACTIVITY_MODES.PRODUCTION]: {
    color: palette.Teal500,
    name: 'Production',
    order: 1,
  },
};

const {
  ACTIVITY_SET_REF: optimisticActivitySetRef,
  ACTIVITY_REF: optimisticActivityRef,
} = TEMP_REFS;

const Table = ({
  loading,
  error,
  intervals = [1],
  handleOnSelect = () => {},
  disallowSetup,
  isStopAndStartRun,
  newWorkOrderId,
  showAllTypes = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const activityTypes = useSelector(getSwitchActivityTypesOptions);
  const openActivityTypeRef = useSelector(getOpenActivityTypeRef);
  const latestJob = useSelector(getLatestJob);
  const latestJobId = useSelector(getLatestJobId);
  latestJob.jobId = latestJobId;
  const activitySetStandards = getActivitySetStandardsFromJob(latestJob);
  const machine = useSelector(getMachine);
  const nowISO = toISO(now().valueOf());

  const [requestStopAndStartRun] = useMutation(CREATE_ACTIVITY_SET, {
    fetchPolicy: 'no-cache',
    update: (
      _cache,
      {
        data: {
          createActivitySetResponse: {
            activitySet: {
              activities: [activity],
              ...activitySet
            },
          },
        },
      }
    ) => {
      dispatch(
        actionOptimisticActivitySetOpen({
          activitySet,
          activity,
          job: latestJob,
        })
      );
      dispatch(actionSetJobTotal(undefined));
      history.push(Routes.machineIdPath(machine.id));
    },
    onCompleted: ({
      createActivitySetResponse: {
        activitySet: {
          activities: [activity], // should be sorted start ASC
          ...activitySet
        },
      },
    }) => {
      throwSuccessToast('Started new Production Order');
      dispatch(
        actionCanonicalActivitySetOpen({
          activitySet,
          activity,
          optimisticActivitySetRef,
          optimisticActivityRef,
        })
      );
    },
    onError: () => {
      dispatch(
        actionCanonicalActivitySetDelete({
          activitySetRef: optimisticActivitySetRef,
        })
      );
      throwErrorToast('Could not start new Production Order');
    },
  });

  const onClick = useCallback(
    (at, disabled) => {
      return (e) => {
        e.preventDefault();
        if (isStopAndStartRun) {
          return requestStopAndStartRun({
            variables: {
              input: {
                machineRef: machine.machineRef,
                activities: [
                  { activityTypeRef: at.activityTypeRef, startAt: nowISO },
                ],
                workOrderId: newWorkOrderId || null,
                expectedUnitDuration: activitySetStandards.expectedUnitDuration,
                idealUnitDuration: activitySetStandards.idealUnitDuration,
                expectedSetupDuration:
                  activitySetStandards.expectedSetupDuration,
                optimalUnitDuration: activitySetStandards.optimalUnitDuration,
                partCountMultiplier: latestJob.partCountMultiplier,
                actualParts: latestJob.actualParts,
                jobId: latestJobId,
                closedAt: null,
              },
              query: {
                shouldCloseLatestSet: true,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              createActivitySetResponse: {
                __typename: 'ActivitySet',
                activitySet: {
                  expectedUnitDuration:
                    activitySetStandards.expectedUnitDuration,
                  idealUnitDuration: activitySetStandards.idealUnitDuration,
                  expectedSetupDuration:
                    activitySetStandards.expectedSetupDuration,
                  optimalUnitDuration: activitySetStandards.optimalUnitDuration,
                  partCountMultiplier: latestJob.partCountMultiplier,
                  actualParts: latestJob.actualParts,
                  jobId: latestJobId,
                  activitySetRef: optimisticActivitySetRef,
                  jobScheduleGroupId: null,
                  closedAt: null,
                  workOrderId: newWorkOrderId || null,
                  activities: [
                    {
                      activityRef: optimisticActivityRef,
                      activitySetRef: optimisticActivitySetRef,
                      activityTypeRef: at.activityTypeRef,
                      start: nowISO,
                      end: null,
                      updatedAt: nowISO,
                      jobScheduleGroupId: null,
                    },
                  ],
                },
              },
            },
          });
        }
        if (!disabled) {
          handleOnSelect(at.activityTypeRef, at.activityMode);
        }
        return null;
      };
    },
    [
      handleOnSelect,
      // followings are for stop and start run
      requestStopAndStartRun,
      isStopAndStartRun,
      activitySetStandards,
      nowISO,
      latestJob,
      latestJobId,
      newWorkOrderId,
      machine,
    ]
  );

  const filteredActivityTypes = useMemo(() => {
    return activityTypes
      .filter((at) => {
        return !disallowSetup || at.activityMode !== ACTIVITY_MODES.SETUP;
      })
      .sort((a, b) => {
        return `${activityModes[a.activityMode].order}${a.name}` >
          `${activityModes[b.activityMode].order}${b.name}`
          ? 1
          : -1;
      });
  }, [activityTypes, disallowSetup]);

  const [selected, setSelected] = useState(false);

  const defaultActivityType =
    filteredActivityTypes.length === 1 && !selected && filteredActivityTypes[0];

  useEffect(() => {
    if (defaultActivityType) {
      setSelected(true);
      handleOnSelect(
        defaultActivityType.activityTypeRef,
        defaultActivityType.activityMode
      );
    }
  }, [defaultActivityType, openActivityTypeRef, onClick, handleOnSelect]);

  return (
    <Timetable templateColumns="1fr minmax(8rem, 0.2fr) 3rem">
      <Thead>
        <Th color={palette.Grey400}>{t('Activity')}</Th>
        <Th color={palette.Grey400}>{t('Activity Mode')}</Th>
        <Th></Th>
      </Thead>
      {loading ? (
        <LoadingTbody
          rows={5}
          row={
            <>
              <LoadingTd>
                <LoadingText />
                <LoadingText />
              </LoadingTd>
              <LoadingTd>
                <LoadingText />
                <LoadingText />
              </LoadingTd>
              <LoadingTd>
                <LoadingText />
                <LoadingText />
              </LoadingTd>
            </>
          }
        />
      ) : error ? (
        <ErrorInline
          title={t('There was an error loading the Activities list')}
          {...error}
          reload
        />
      ) : intervals.length === 0 ? (
        <Empty variant="?" />
      ) : (
        <Tbody>
          {filteredActivityTypes.map((at) => {
            // if stopAndStartRun, we allow user to select any activity
            const disabled =
              !showAllTypes &&
              (isStopAndStartRun
                ? false
                : at.activityTypeRef === openActivityTypeRef);

            return (
              <React.Fragment key={at.activityTypeRef}>
                <Td
                  onClick={onClick(at, disabled)}
                  interactive
                  disabled={disabled}
                >
                  <Cell>
                    <P bold>{at.name}</P>
                  </Cell>
                </Td>
                <Td
                  onClick={onClick(at, disabled)}
                  interactive
                  disabled={disabled}
                >
                  <Cell>
                    <Tag color={activityModes[at.activityMode].color}>
                      {activityModes[at.activityMode].name}
                    </Tag>
                  </Cell>
                </Td>
                <Td
                  onClick={onClick(at, disabled)}
                  interactive
                  disabled={disabled}
                >
                  <Cell>
                    <Icon
                      icon={angleRight}
                      size="1.25rem"
                      color={palette.Grey400}
                    />
                  </Cell>
                </Td>
              </React.Fragment>
            );
          })}
        </Tbody>
      )}
    </Timetable>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  intervals: PropTypes.array,
  handleOnSelect: PropTypes.func.isRequired,
  disallowSetup: PropTypes.bool,
  newWorkOrderId: PropTypes.string,
  isStopAndStartRun: PropTypes.bool,
};

export { Table };
