import { createSelector } from 'reselect';
import { getHasOpenActivitySetInSetup } from 'lib/selectors/getHasOpenActivitySetInSetup';
import { getIsPaused } from 'lib/selectors/getIsPaused';

export const getIsSetupPaused = createSelector(
  [getHasOpenActivitySetInSetup, getIsPaused],
  (hasOpenActivitySetInSetup, isPaused) => {
    return hasOpenActivitySetInSetup && isPaused;
  }
);
