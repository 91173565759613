import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H6, Small, Text } from 'components/Text';
import { Button, palette } from '@m12s/component-library';
import { DefaultCardBorder } from 'components/MachineHeader/styled';

const MOBILE_WIDTH = '720px';

export const IconWrapper = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
`;

export const ModalDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 0.65fr auto 1fr;
  grid-gap: 2rem;
  @media (max-width: ${MOBILE_WIDTH}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

export const VerticalDivider = styled.div`
  width: 1px;
  background: ${palette.Grey400};
`;

export const ModalButton = styled(Button)`
  font-size: 0.875rem;
  text-shadow: none;

  ${({ customStyles }) => {
    return customStyles;
  }}
  &:hover {
    ${({ hoverStyles }) => {
      return hoverStyles;
    }}
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  ${DefaultCardBorder}
  background: ${palette.White};
  flex: 1;
  height: 100%;
`;

export const CardHead = styled.div`
  margin: 0 1.5rem;
  padding: 1.5rem 0;
  font-weight: 600;
  color: ${palette.Indigo600};
  border-bottom: 1px solid ${palette.Grey300};
`;

export const CardHeader = styled(H6)`
  color: ${palette.Indigo600};
  font-weight: 600;
`;

export const CardBody = styled.div`
  padding: 1.5rem;
`;

export const CardFoot = styled.div`
  padding: 1rem 1.5rem;
  border-top: 1px solid ${palette.Grey300};
`;

const LaborTicketItemContainer = styled.div`
  display: grid;
`;

const Label = styled(Small)`
  font-weight: 400;
`;

const Field = styled(Text)`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LaborTicketHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
  row-gap: 1rem;
  color: ${palette.Grey700};
  font-size: 0.875rem;
  line-height: 125%;
  margin-top: 0.25rem;
`;

export const LaborTicketHeaderItem = ({ label, value }) => {
  return (
    <LaborTicketHeaderContainer>
      <div>{label}</div>
      <div>{value}</div>
    </LaborTicketHeaderContainer>
  );
};

export const LaborTicketItem = ({ label, value }) => {
  return (
    <LaborTicketItemContainer>
      <Label>{label}</Label>
      <Field>{value}</Field>
    </LaborTicketItemContainer>
  );
};

LaborTicketItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const modalStyles = {
  padding: 0,
};

export const buttonStyles = {
  padding: '1rem 1.5rem',
  background: palette.White,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '1rem',
};

export const headerStyles = {
  color: palette.Grey700,
  background: palette.White,
  borderBottom: `1px solid ${palette.Grey400}`,
  fontWeight: '500',
};

export const detailsStyles = {
  fontWeight: '400',
  fontSize: '0.875rem', // 14px to rem
  lineHeight: '1.25rem', // 20px to rem
  padding: '1.5rem',
  borderBottom: `1px solid ${palette.Grey400}`,
};

export const ModalButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
`;
