import React, { useMemo, useState, useEffect } from 'react';
import _startCase from 'lodash/startCase';
import _get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { palette } from '@m12s/component-library';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ERROR_KEYS } from 'lib/constants';

import { HeaderText, HeaderLayout } from 'components/AppNav/styled';
import { AppNav } from 'components/AppNav';
import { JobDetails } from 'components/JobDetails';
import MainLayout from 'components/Layouts/MainLayout';
import { getMachine } from 'lib/selectors/getMachine';
import { GET_JOB_QUANTITY } from 'lib/api/queries';
import { useNetworkError } from 'lib/hooks';
import Routes from 'lib/utils/routes';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';

const JobInfo = ({
  startJob,
  startJobAsJSG,
  job,
  workOrderId,
  previousStep,
  nextStep,
  handleOnClose = (history, machine) => {
    return history.push({ pathname: Routes.machineIdHomePath(machine.id) });
  },
  location = {},
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const workOrderFlowEnabled = useIsWorkOrderFlow();
  /* eslint-disable no-param-reassign */
  job = job || location?.state?.job || {};
  workOrderId = workOrderId || location?.state?.workOrderId;
  const jobId = _get(job, 'jobId', undefined);
  const machine = useSelector(getMachine);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);

  const isStartingNewRun = location?.state?.isStartingNewRun;

  const [quantityProduced, setQuantityProduced] = useState();

  const jobQuantity = useQuery(GET_JOB_QUANTITY, {
    variables: { jobId },
    skip: !jobId,
    onCompleted: ({ jobQuantity: { quantity } }) => {
      setQuantityProduced(quantity);
    },
  });

  useNetworkError(jobQuantity, ERROR_KEYS.JOB_QUANTITY);

  const jobWithQuantityProduced = useMemo(() => {
    return {
      ...job,
      quantityProduced,
    };
  }, [job, quantityProduced]);

  useEffect(() => {
    if (machine.id && !jobId && previousStep) {
      // safety check if job does not exist on props - kick back into select job flow
      previousStep();
    }
  }, [jobId, previousStep, machine.id]);

  const useWorkOrderOpData = workOrderFlowEnabled && currentWorkOrderOp;

  const partOperation = currentWorkOrderOp?.partOperation;

  const workOrderHeaderText =
    useWorkOrderOpData &&
    partOperation &&
    `${partOperation?.partNumber} Op ${currentWorkOrderOp.sequenceNumber}`;

  return (
    <MainLayout.Container
      id="job-details-page"
      direction="column"
      style={{ background: '#F7FAFC' }}
      overlay
    >
      <AppNav
        onBack={previousStep}
        onClose={() => {
          return handleOnClose(history, machine);
        }}
      >
        <HeaderLayout>
          <HeaderText bold color={palette.Yellow400}>
            {workOrderHeaderText || job?.name}
          </HeaderText>
          <HeaderText semibold color={palette.White}>
            {useWorkOrderOpData
              ? 'Overview'
              : _startCase(t('operation overview'))}
          </HeaderText>
        </HeaderLayout>
      </AppNav>
      <JobDetails
        machine={machine}
        workOrderId={workOrderId}
        job={jobWithQuantityProduced}
        startJobAsJSG={startJobAsJSG}
        startJob={startJob}
        nextStep={nextStep}
        previousStep={previousStep}
        isStartingNewRun={isStartingNewRun}
        handleOnClose={() => {
          return handleOnClose(history, machine);
        }}
      />
    </MainLayout.Container>
  );
};

export default JobInfo;
