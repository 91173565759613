import React, { useReducer } from 'react';
import _min from 'lodash/min';
import _max from 'lodash/max';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DefaultLoading } from 'components/Loading';
import ErrorPage from 'pages/Error';
import { Step1, Step2, Step3 } from 'components/RejectParts';
import { CREATE_PART_ADJUSTMENT } from 'lib/api/mutations';
import Routes from 'lib/utils/routes';
import { getCompany } from 'lib/selectors/getCompany';
import { getMachine } from 'lib/selectors/getMachine';
import { actionCreatePartAdjustmentSuccess } from 'lib/actions';

import { usePartAdjustmentType } from './usePartAdjustmentType';

const isTypeEnabled = (partAdjustmentType) => {
  return partAdjustmentType.rejectBehavior === 'operator';
};

const rejectBehaviorToValue = (rejectBehavior) => {
  if (rejectBehavior === 'operator' || rejectBehavior === 'scrap')
    return 'scrap';
  return 'nonconform';
};

function reducer(state, action) {
  const { value, type } = action;

  switch (type) {
    case 'NEXT_STEP':
      return { ...state, step: _min([state.step + 1, 3]) };
    case 'PREVIOUS_STEP':
      return { ...state, step: _max([state.step - 1, 0]) };
    case 'SET_PARTS':
      return { ...state, parts: value };
    case 'SET_NOTES':
      return { ...state, notes: value };
    case 'SET_TYPE':
      return { ...state, type: value };
    case 'SET_CATEGORY':
      return {
        ...state,
        category: value,
        typeEnabled: isTypeEnabled(value),
        type: rejectBehaviorToValue(value.rejectBehavior),
        step: action.nextStep ? state.step + 1 : state.step,
      };
    case 'UNSET_CATEGORY':
      return {
        ...state,
        type: 'scrap',
        typeEnabled: true,
        category: null,
      };
    case 'SET_REMOVE_FROM_GOOD_PARTS':
      return { ...state, removeFromGoodParts: value };
    case 'RESET':
      return initalState;
    default:
      /* eslint-disable no-console */
      console.error(`Unknown action type ${type}`);
      return state;
  }
}

const initalState = {
  step: 1,
  parts: 1,
  notes: '',
  type: 'scrap',
  typeEnabled: true,
  removeFromGoodParts: true,
  category: null,
};

const RejectPartsPage = () => {
  const machine = useSelector(getMachine);
  const { loading, error, node, setNode } = usePartAdjustmentType();
  const [{ step, ...state }, dispatch] = useReducer(reducer, initalState);
  const history = useHistory();
  const company = useSelector(getCompany);
  const storeDispatch = useDispatch();

  const [
    createPartAdjustment,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_PART_ADJUSTMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ createPartAdjustmentResponse }) => {
      storeDispatch(
        actionCreatePartAdjustmentSuccess(createPartAdjustmentResponse)
      );
      history.push(Routes.machineIdPath(machine.id));
    },
  });

  const onSubmit = () => {
    const { parts, category, notes, type, removeFromGoodParts } = state;

    const payload = {
      scrapcount: parts,
      machine_id: machine.id,
      count: removeFromGoodParts ? 0 : parts,
      reject_behavior: type,
      category_id: category.id,
      message: notes || null,
    };

    return createPartAdjustment({ variables: { input: payload } });
  };

  if (mutationLoading) return <DefaultLoading />;

  if (error || mutationError)
    return <ErrorPage error={error || mutationError} />;

  if (step === 1)
    return <Step1 {...state} dispatch={dispatch} machineId={machine.id} />;
  if (step === 2 && !loading)
    return (
      <Step2
        {...state}
        node={node}
        dispatch={dispatch}
        machineId={machine.id}
        onBack={() => {
          return node.root
            ? dispatch({ type: 'PREVIOUS_STEP' })
            : setNode(node.parent);
        }}
        onSelect={(child) => {
          const { children, data: category } = child;

          if (children.length) {
            dispatch({ type: 'UNSET_CATEGORY' });
            setNode(child);
          } else {
            dispatch({
              type: 'SET_CATEGORY',
              value: category,
              nextStep: true,
            });
          }
        }}
      />
    );
  if (step === 3)
    return (
      <Step3
        {...state}
        onSubmit={onSubmit}
        dispatch={dispatch}
        machineId={machine.id}
        showRemoveFromGoodParts={!company.hideRemoveRejectedPartsCheckbox}
      />
    );

  return <DefaultLoading />;
};

export default RejectPartsPage;
