import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { now, toISO } from 'lib/utils/date';
import _get from 'lodash/get';
import queryString from 'query-string';
import routes from 'lib/utils/routes';

import { getLaborTicketAction } from 'lib/selectors/getLaborTicketAction';
import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getMachine } from 'lib/selectors/getMachine';
import { getCurrentLaborTicket } from 'lib/selectors/getLaborTickets';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import {
  actionSetLaborTicketActionLoading,
  actionSetLaborTicketParts,
  actionSetPendingActivityTypeRef,
} from 'lib/actions';
import { throwErrorToast } from 'lib/utils/toast';
import { getLatestOperatorId } from 'lib/selectors/getLatestOperatorId';
import { getOperators } from 'lib/selectors/getOperators';
import { getLatestActivityMode } from 'lib/selectors/getLatestActivityMode';
import { getWorkOrderManagementRedirectPath } from 'lib/selectors/getWorkOrderRedirectPath';
import { getPendingActivityTypeRef } from 'lib/selectors/getPendingActivityTypeRef';
import { getActivityTypes } from 'lib/selectors/getActivityTypes';
import { getUnattendedOperatorPersonId } from 'lib/selectors/getUnattendedOperatorPersonId';
import { getIsSetupPaused } from 'lib/selectors/getIsSetupPaused';

import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';
import usePauseOperation from './useHandlePauseOperation';
import usePartAdjustment from './hooks/usePartAdjustment';
import useEditLaborTicket from './hooks/useEditLaborTicket';
import useCreateLaborTicket from './hooks/useCreateLaborTicket';
import useOperatorLogout from './hooks/useOperatorLogout';
import useStopRun from './hooks/useStopRun';
import useChangeActivities from './hooks/useChangeActivities';
import useCloseOrderOperaiton from './hooks/useCloseOrderOperation';

const useSubmitLaborTicket = ({ form } = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search);

  const editLaborTicket = useEditLaborTicket();
  const createLaborTicket = useCreateLaborTicket();
  const workOrderManagementRedirectPath = useSelector(
    getWorkOrderManagementRedirectPath
  );
  const requestOperatorLogout = useOperatorLogout();
  const requestStopRun = useStopRun();
  const requestChangeActivities = useChangeActivities();
  const closeOrderOperation = useCloseOrderOperaiton();
  const { submitPartAdjustment: requestPartAdjustment } = usePartAdjustment({
    form,
  });
  const requestPauseOperation = usePauseOperation({ onPaused: false });

  const machine = useSelector(getMachine);
  const latestActivityMode = useSelector(getLatestActivityMode);
  const latestOperatorId = useSelector(getLatestOperatorId);
  const operators = useSelector(getOperators);

  const laborTicketAction = useSelector(getLaborTicketAction);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);
  const workOrderId = _get(currentWorkOrderOp, 'workOrderId');

  const closeOrder = form?.workOrderComplete.value && !hasOpenSetupActivity;
  const shouldStopCurrentRun = closeOrder || queryParams.startAndStop;
  const currentLaborTicket = useSelector(getCurrentLaborTicket);

  const pendingActivityTypeRef = useSelector(getPendingActivityTypeRef);
  const activityTypes = useSelector(getActivityTypes);
  const unattendedPersonId = useSelector(getUnattendedOperatorPersonId);

  const isSetupPaused = useSelector(getIsSetupPaused);

  /**
   * Submits a labor ticket with the given action and options
   * @param {Object} params - The parameters object
   * @param {string} params.action - The labor ticket action to perform
   * @param {Object} [params.opts={}] - Additional options for the labor ticket submission
   * @param {(string|number)} [params.opts.pendingActivityTypeRef] - Reference to the pending activity type
   * @param {string} [params.opts.redirectPath] - Path to redirect after submission
   * @param {boolean} [params.opts.shouldStopCurrentRun] - Whether to stop the current run
   * @param {string} [params.opts.loggingInOperator] - ID of the operator logging in
   * @param {boolean} [params.opts.switchToUnattendedOperator] - Whether to switch to unattended operator
   * @param {boolean} [params.opts.closeOrderOperation] - Whether to close the order operation via override
   * @returns {Promise<void|any>} Returns undefined in some cases, history.push() result in others, or the result of submitLaborTicket
   */
  const submitLaborTicket = async ({ action, opts = {} }) => {
    const activityTypeRef =
      opts?.pendingActivityTypeRef || pendingActivityTypeRef;
    const redirectPath = opts.redirectPath || workOrderManagementRedirectPath;
    const isStopCurrentRun = opts?.shouldStopCurrentRun ?? shouldStopCurrentRun;
    const isCloseOrderOperation = opts?.closeOrderOperation ?? closeOrder;

    if (!currentWorkOrderOp) {
      return;
    }
    const nowISO = toISO(now().valueOf());
    try {
      if (currentLaborTicket && form) {
        await requestPartAdjustment();

        const rejectedParts = form.rejectedParts.value || 0;
        const goodParts = form.totalParts.value - rejectedParts;
        const reason = form.rejectReason.value;

        await editLaborTicket(
          {
            clockOut: nowISO,
            state: 'CLOSED',
            goodParts,
            badParts: rejectedParts,
            comment: form.comment.value || null,
          },
          currentLaborTicket.laborTicketRef,
          reason?.reasonRef
        );
      }

      if (isCloseOrderOperation) {
        await closeOrderOperation(
          { closedDate: nowISO },
          currentWorkOrderOp.workOrderOperationRef
        );
      }

      const laborTicketOperatorId = opts.loggingInOperator || latestOperatorId;
      let personId = operators.find((op) => {
        return op.id === laborTicketOperatorId;
      })?.erpId;

      if (opts.switchToUnattendedOperator) {
        personId = unattendedPersonId;
      }

      if (
        !(action === LABOR_TICKET_ACTIONS.OPERATOR_LOGIN && isSetupPaused) &&
        !isStopCurrentRun &&
        action !== LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT &&
        action !== LABOR_TICKET_ACTIONS.STOP_RUN &&
        action !== LABOR_TICKET_ACTIONS.PAUSE_SETUP &&
        personId
      ) {
        const { split = '', lot = '', sub = '' } =
          currentWorkOrderOp.workOrder || {};

        let laborType = latestActivityMode;
        if (activityTypeRef) {
          laborType = activityTypes?.find((type) => {
            return type.activityTypeRef === activityTypeRef;
          })?.activityMode;
        }

        await createLaborTicket({
          clockIn: nowISO,
          clockOut: null,
          lot,
          sequenceNumber: currentWorkOrderOp.sequenceNumber,
          split,
          sub,
          state: 'OPEN',
          resourceId: machine.erpResourceId,
          personId,
          workOrderId,
          type: laborType,
          goodParts: 0,
          badParts: 0,
        });
      }

      if (redirectPath) {
        history.push(redirectPath);
      }
      dispatch(actionSetLaborTicketParts(0));
    } catch (e) {
      dispatch(actionSetLaborTicketActionLoading(false));
      throwErrorToast(`Could not submit labor ticket: ${e}`);
    }
  };

  const requestLaborTicketAction = async ({ action, opts = {} } = {}) => {
    const activityTypeRef =
      opts?.pendingActivityTypeRef || pendingActivityTypeRef;
    const isStopCurrentRun = opts?.shouldStopCurrentRun ?? shouldStopCurrentRun;

    switch (action) {
      case LABOR_TICKET_ACTIONS.STOP_RUN:
        await requestStopRun();
        return submitLaborTicket({ action, opts });

      case LABOR_TICKET_ACTIONS.PAUSE_SETUP: {
        await requestPauseOperation();
        return submitLaborTicket({ action, opts });
      }

      case LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT: {
        await requestOperatorLogout();
        if (isStopCurrentRun) {
          await requestStopRun();
        }
        if (opts?.shouldPauseSetup) {
          await requestPauseOperation();
        }
        await submitLaborTicket({ action, opts });
        return history.push({
          pathname: routes.machineIdHomePath(machine.id),
        });
      }

      case LABOR_TICKET_ACTIONS.OPERATOR_LOGIN: {
        const startAt = _get(opts, 'startAt');
        const operatorId = _get(opts, 'loggingInOperator');
        await submitLaborTicket({
          action,
          opts: {
            startAt,
            loggingInOperator: operatorId,
          },
        });
        if (isStopCurrentRun) {
          await requestStopRun();
          return history.push(routes.machineIdSelectJobPath(machine.id));
        }
        return history.push({
          pathname: routes.machineIdHomePath(machine.id),
        });
      }

      case LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES: {
        if (isStopCurrentRun) {
          await requestStopRun();
          await submitLaborTicket({ action, opts });
          return history.push(routes.machineIdSelectJobPath(machine.id));
        }

        if (activityTypeRef) {
          await requestChangeActivities({
            pendingActivityTypeRef: activityTypeRef,
          });
        } else {
          await requestChangeActivities();
        }

        return submitLaborTicket({
          action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
          opts: {
            pendingActivityTypeRef: activityTypeRef,
            ...opts,
          },
        });
      }

      default:
        return null;
    }
  };

  return async ({ action, opts = {} } = {}) => {
    dispatch(actionSetLaborTicketActionLoading(true));

    if (_get(opts, 'pendingActivityTypeRef')) {
      await dispatch(
        actionSetPendingActivityTypeRef(_get(opts, 'pendingActivityTypeRef'))
      );
    }

    return requestLaborTicketAction({
      action: action || laborTicketAction,
      opts,
    });
  };
};

export default useSubmitLaborTicket;
