import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { now, toISO } from 'lib/utils/date';
import { useSelector } from 'react-redux';

import { getCurrentLaborTicket } from 'lib/selectors/getLaborTickets';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getLatestActivityType } from 'lib/selectors/getLatestActivityType';

import { getOperators } from 'lib/selectors/getOperators';

import {
  LaborTicketHeaderItem,
  LaborTicketItem,
} from 'components/LaborTicket/styled';
import Card from '../Card';
import { BodyContainer } from '../styled';

const LaborTicketCurrent = ({ form }) => {
  const latestActivityType = useSelector(getLatestActivityType);
  const currentLaborTicket = useSelector(getCurrentLaborTicket);

  const [initialState, setInitialState] = useState({
    activityType: latestActivityType,
    laborTicket: currentLaborTicket,
  });

  useEffect(() => {
    setInitialState({
      activityType: latestActivityType,
      laborTicket: currentLaborTicket,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { activityType, laborTicket } = initialState;
  const scopeStart = useSelector(getScopeStart);
  const operators = useSelector(getOperators);
  const erpPerson = currentLaborTicket?.personId;
  const operator =
    operators.find((op) => {
      return op.erpId === erpPerson;
    })?.name || 'Not Found';

  const rejectedParts = form.rejectedParts?.value || 0;
  const goodParts = form.totalParts.value - rejectedParts;

  const nowISO = toISO(now().valueOf());

  return (
    <Card
      header={
        <>
          Current Labor Ticket
          <LaborTicketHeaderItem
            label="Start:"
            value={
              laborTicket?.clockIn
                ? `${moment(laborTicket.clockIn).format('L')} ${moment(
                    laborTicket.clockIn
                  ).format('LT')}`
                : `${moment(scopeStart).format('L')} ${moment(
                    scopeStart
                  ).format('LT')}`
            }
          />
          <LaborTicketHeaderItem
            label="End:"
            value={`Today ${moment(nowISO).format('LT')}`}
          />
        </>
      }
      body={
        <BodyContainer>
          <LaborTicketItem label="Total Parts" value={form.totalParts.value} />
          <LaborTicketItem label="Good Parts" value={goodParts} />
          <LaborTicketItem label="Rejected Parts" value={rejectedParts} />
          <LaborTicketItem label="Operator" value={operator} />
          <LaborTicketItem label="Activity" value={activityType.name} />
        </BodyContainer>
      }
    />
  );
};

export default LaborTicketCurrent;

LaborTicketCurrent.propTypes = {
  form: PropTypes.object,
};
