import React from 'react';
import {
  CardBody,
  CardContainer,
  CardFoot,
  CardHead,
  CardHeader,
} from '../styled';

const Card = ({ header, body, footer }) => {
  return (
    <CardContainer>
      {header && (
        <CardHead>
          <CardHeader>{header}</CardHeader>
        </CardHead>
      )}
      <CardBody>{body}</CardBody>
      {footer && <CardFoot>{footer}</CardFoot>}
    </CardContainer>
  );
};

export default Card;
