import styled from 'styled-components';

export const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.75rem;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Row = styled.div`
  display: flex;
  gap: 0.5rem;

  > * {
    min-width: 38px;
  }
`;

export const TicketInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
