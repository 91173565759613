import { createSelector } from 'reselect';
import { getActivityTypes } from './getActivityTypes';
import { getIsERPJobControlBarFlow } from './getIsERPJobControlBarFlow';
import { getOpenActivityTypeMode } from './getOpenActivity';

export const getSwitchActivityTypesOptions = createSelector(
  [getActivityTypes, getOpenActivityTypeMode, getIsERPJobControlBarFlow],
  (activityTypes, openActivityTypeMode, isERPJobControlBarFlow) => {
    if (
      !isERPJobControlBarFlow ||
      !openActivityTypeMode ||
      !activityTypes?.length
    ) {
      return activityTypes;
    }

    return activityTypes.filter(({ activityMode }) => {
      return activityMode === openActivityTypeMode;
    });
  }
);
