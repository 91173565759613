import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formatValue } from 'lib/utils/text';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getOrderProgress } from 'lib/selectors/getOrderProgress';
import { getLaborScopeTotalParts } from 'lib/selectors/getScopeTotalParts';

/**
 * @typedef {Object} FormData
 * @property {Object} totalParts - Total parts form field
 * @property {number} totalParts.value - Value of total parts
 * @property {Object} [rejectedParts] - Rejected parts form field
 * @property {number} [rejectedParts.value] - Value of rejected parts
 */

/**
 * Displays the progress of an order as a fraction (current/total)
 * Takes into account form adjustments for total parts and rejected parts if provided
 *
 * @param {Object} props
 * @param {FormData} [props.form] - Optional form data to adjust the order progress calculation
 * @returns {JSX.Element} Span containing the order progress text
 */
const OrderProgress = ({ form }) => {
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const orderProgress = useSelector(getOrderProgress);
  const laborTicketPartsMade = useSelector(getLaborScopeTotalParts);

  const finishQuantity = currentWorkOrderOp?.finishQuantity;

  const orderTotalParts = useMemo(() => {
    if (form) {
      const partAdjustment = form.totalParts.value - laborTicketPartsMade;
      const rejectedParts = form.rejectedParts?.value || 0;
      return orderProgress + partAdjustment - rejectedParts;
    }

    return orderProgress;
  }, [form, laborTicketPartsMade, orderProgress]);

  const orderProgressText = [orderTotalParts, finishQuantity]
    .map((data) => {
      return formatValue(data)?.toLocaleString() || '-';
    })
    .join(' / ');

  return <span>{orderProgressText}</span>;
};

OrderProgress.propTypes = {
  form: PropTypes.shape({
    totalParts: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
    rejectedParts: PropTypes.shape({
      value: PropTypes.number,
    }),
  }),
};

export default OrderProgress;
