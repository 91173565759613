import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
import {
  theme,
  ThemeProvider as LibThemeProvider,
} from '@m12s/component-library';
import { I18nextProvider } from 'react-i18next';

import './App.css';
import i18n from 'lib/translations/i18n';
import Routes from 'lib/utils/routes';
import { ApolloProvider } from '@apollo/client';
import { client } from 'lib/api';
import { useDocumentTitle, useAnalytics, useConnectionStatus } from 'lib/hooks';

import { UpdatePopup } from 'components/UpdatePopup';
import CategorizePage from 'pages/Categorize';
import MachinePage from 'pages/Machine';
import SelectJobPage from 'pages/SelectJob';
import RejectPartsPage from 'pages/RejectParts';
import SwitchActivities from 'pages/SwitchActivities';
import LoginPage from 'pages/Login';
import SwitchMachinesPage from 'pages/SwitchMachines';
import OAuthLogin from 'pages/OAuthLogin';
import Authorize from 'pages/Authorize';
import WorkflowTriggerPage from 'pages/WorkflowTrigger';
import StartActivityNewOperation from 'pages/StartActivityNewOperation';
import JobInfo from 'pages/SelectJob/JobInfo';

import { TickerProvider } from 'components/TickerProvider';
import LaunchDarklyProvider from 'components/LaunchDarklyProvider';
import { PendingStates } from 'components/PendingStates';
import { UnauthenticatedRoute } from 'components/route/UnauthenticatedRoute';
import { PrivateRoute } from 'components/route/PrivateRoute';

import { useSelector } from 'react-redux';
import { Reconnect } from 'components/Reconnect';
import { HFLockout } from 'components/HFLockout';
import { DataProvider } from 'components/DataProvider/DataProvider';
import { AccountProvider } from 'components/AccountProvider';
import { getHomeTabRoute } from 'lib/selectors/getTabs';
import { useTimesync } from 'lib/hooks/useTimesync';
import { useStatusPage } from 'lib/hooks/useStatusPage';
import { useLocale } from 'lib/hooks/useLocale';

import NoSleep from 'nosleep.js';

import JobInfoERP from 'pages/SelectJob/JobInfoERP/index';
import { LaborTicketModal } from 'components/LaborTicket';
import { CustomToastContainer } from 'lib/utils/toast';
import { getWorkOrderFlowEnabled } from 'lib/selectors/getWorkOrderFlowEnabled';

const RedirectToMachineHomePage = () => {
  const homeTabRoute = useSelector(getHomeTabRoute);
  return <Redirect to={homeTabRoute} />;
};

const App = () => {
  const noSleep = new NoSleep();

  const workOrdeFlowEnabled = useSelector(getWorkOrderFlowEnabled);

  const JobInfoComponent = workOrdeFlowEnabled ? JobInfoERP : JobInfo;

  useEffect(() => {
    document.addEventListener(
      'click',
      function enableNoSleep() {
        document.removeEventListener('click', enableNoSleep, false);
        noSleep.enable();
      },
      false
    );

    return noSleep.disable();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useDocumentTitle();
  useAnalytics();
  useConnectionStatus();
  useTimesync();
  useStatusPage();
  useLocale();
  return (
    <ThemeProvider theme={theme}>
      <LibThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <TickerProvider>
            <CustomToastContainer closeButton={false} hideProgressBar />
            <LoginPage />
            <UpdatePopup />
            <Reconnect />
            <Switch>
              <PrivateRoute exact path={Routes.rootPath()}>
                <Redirect to={Routes.switchMachinesPath()} />
              </PrivateRoute>
              <UnauthenticatedRoute exact path={Routes.loginPath()}>
                <OAuthLogin />
              </UnauthenticatedRoute>
              <UnauthenticatedRoute exact path={Routes.authorizePath()}>
                <Authorize />
              </UnauthenticatedRoute>
              <PrivateRoute path="/">
                <AccountProvider>
                  <Route exact path={Routes.switchMachinesPath()}>
                    <SwitchMachinesPage />
                  </Route>
                  <Route path={Routes.machineIdPath.pattern}>
                    <DataProvider>
                      <HFLockout />
                      <LaborTicketModal />
                      <PendingStates>
                        <Switch>
                          <Route
                            exact
                            path={Routes.machineIdPath.pattern}
                            component={RedirectToMachineHomePage}
                          />
                          <Route
                            exact
                            path={Routes.machineIdRejectPartsPath.pattern}
                            component={RejectPartsPage}
                          />
                          <Route
                            path={Routes.machineIdHomePath.pattern}
                            component={MachinePage}
                          />
                          <Route
                            path={Routes.machineIdCategorizePath.pattern}
                            component={CategorizePage}
                          />
                          <Route
                            exact
                            path={Routes.machineIdSelectJobPath.pattern}
                            component={SelectJobPage}
                          />
                          <Route
                            exact
                            path={
                              Routes.machineIdCurrentOperationInfoPath.pattern
                            }
                            component={JobInfoComponent}
                          />
                          <Route
                            path={
                              Routes.machineIdStartActivityNewOperationPath
                                .pattern
                            }
                            component={StartActivityNewOperation}
                          />
                          <Route
                            path={Routes.machineIdSwitchActivitiesPath.pattern}
                            component={SwitchActivities}
                          />
                          <Route
                            path={Routes.machineIdSelectWorkflowPath.pattern}
                            component={WorkflowTriggerPage}
                          />
                        </Switch>
                      </PendingStates>
                    </DataProvider>
                  </Route>
                </AccountProvider>
              </PrivateRoute>
            </Switch>
          </TickerProvider>
        </I18nextProvider>
      </LibThemeProvider>
    </ThemeProvider>
  );
};

const HostMiddleware = () => {
  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};

export default () => {
  return (
    <Router>
      <LaunchDarklyProvider>
        <HostMiddleware />
      </LaunchDarklyProvider>
    </Router>
  );
};
