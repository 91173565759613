import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatTimeAndDate } from 'lib/utils/date';
import { DurationTicker } from 'components/DurationTicker';
import { clock } from 'lib/icons';
import { getHasOpenActivitySetInSetup } from 'lib/selectors/getHasOpenActivitySetInSetup';
import { getIsPaused } from 'lib/selectors/getIsPaused';
// import { getPartsMadeDuringCurrentPause } from 'lib/selectors/getPartsMadeDuringCurrentPause';
import { getLatestActivityEndAt } from 'lib/selectors/getLatestActivityEndAt';
import { Portal } from './Portal';
import {
  Overlay,
  MainContainer,
  TogglePromptContainer,
  ToggleIcon,
  TogglePromptTextWrapper,
  PauseDetailsContainer,
  PauseMetricsContainer,
  HideDetailsText,
  GeneralPauseText,
  InlineP,
} from './styled';

const PauseDetailsToggle = () => {
  const { t } = useTranslation();
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const isPaused = useSelector(getIsPaused);
  const hasOpenActivitySetInSetup = useSelector(getHasOpenActivitySetInSetup);
  const latestActivityEndAt = useSelector(getLatestActivityEndAt);
  // hiding pause part count for now until we can implement an optimal lookup for this -
  // we don't want to query raw part count events based on pause start, as there is no implicit
  // limit on pause length
  // const partsMadeDuringCurrentPause = useSelector(getPartsMadeDuringCurrentPause);
  const [isOpen, setIsOpen] = useState(false);
  return (
    isPaused && (
      <Portal>
        <Overlay
          isOpen={isOpen}
          onClick={() => {
            return setIsOpen(false);
          }}
        />
        <MainContainer
          isOpen={isOpen}
          hasOpenActivitySetInSetup={hasOpenActivitySetInSetup}
        >
          {!hasOpenActivitySetInSetup && (
            <TogglePromptContainer
              id="pause-details-toggle-container"
              isOpen={isOpen}
              hasBeenOpened={hasBeenOpened}
              onClick={() => {
                setHasBeenOpened(true);
                setIsOpen(!isOpen);
              }}
            >
              <TogglePromptTextWrapper>
                <ToggleIcon icon={clock} />
                {t('Paused')}
              </TogglePromptTextWrapper>
              <HideDetailsText isOpen={isOpen}>
                {t('Hide Details')}
              </HideDetailsText>
            </TogglePromptContainer>
          )}
          <PauseDetailsContainer
            isOpen={isOpen}
            onClick={() => {
              return setIsOpen(!isOpen);
            }}
            hasOpenActivitySetInSetup={hasOpenActivitySetInSetup}
          >
            <div>
              {!hasOpenActivitySetInSetup && (
                <GeneralPauseText bold>
                  {t('This Production Run has been paused.')}
                </GeneralPauseText>
              )}
              <GeneralPauseText>
                {t(
                  hasOpenActivitySetInSetup
                    ? 'This Operation is paused, which means it is no longer associating any Machine Data to the Operation running. Resume the Operation to continue associating Machine Data with this Operation or Stop the Operation if you are finished running it.'
                    : 'Machine Data will not be associated with the Production Run while paused. Press the resume button to override the Scheduled Pause to continue associating Machine Data with this Production Run. Press the stop button if you have finished this Production Run.'
                )}
              </GeneralPauseText>
            </div>
            <PauseMetricsContainer
              isOpen={isOpen}
              hasOpenActivitySetInSetup={hasOpenActivitySetInSetup}
            >
              <div>
                <InlineP>{t('Triggered At:')}</InlineP>
                &nbsp;
                <InlineP bold>{formatTimeAndDate(latestActivityEndAt)}</InlineP>
              </div>
              <div>
                <InlineP>{t('Duration:')}</InlineP>
                &nbsp;
                <InlineP bold>
                  {latestActivityEndAt && (
                    <DurationTicker startDate={latestActivityEndAt} />
                  )}
                </InlineP>
              </div>
              {/* <div>
              <InlineP>{t('Parts Created While Paused:')}</InlineP>
              &nbsp;
              <InlineP bold>{partsMadeDuringCurrentPause.length}</InlineP>
            </div> */}
            </PauseMetricsContainer>
          </PauseDetailsContainer>
        </MainContainer>
      </Portal>
    )
  );
};

export { PauseDetailsToggle };
