import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  useEmbeddedEffect,
  MMEmbeddableZone,
} from '@machinemetrics/mm-react-embeddable';
import { throwSuccessToast } from 'lib/utils/toast';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetPopupStack } from 'lib/actions';
import { getLatestJobId } from 'lib/selectors/getLatestJobId';
import { getMachine } from 'lib/selectors/getMachine';
import { getScopeNetPartsMade } from 'lib/selectors/getScopeNetPartsMade';

const CustomTabZone = ({ id, src, base }) => {
  const jobId = useSelector(getLatestJobId);
  const machine = useSelector(getMachine);
  const scopeNetPartsMade = useSelector(getScopeNetPartsMade);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [path, setPath] = useState();

  const context = useMemo(() => {
    return {
      operationId: jobId,
      machineId: machine?.id,
      machineRef: machine?.machineRef,
      partCount: scopeNetPartsMade,
    };
  }, [jobId, machine, scopeNetPartsMade]);

  useEmbeddedEffect(id, 'set-path', (data) => {
    if (data.path.indexOf('/') === 0) {
      const newPath = `${base}${data.path}`.replace(/\/$/, '');
      history.push(newPath);
    } else {
      const u = new URL(data.path);
      const p = u.pathname.replace(/^\/app/, '');
      history.push(`${p}${u.search}`);
    }
  });

  useEmbeddedEffect(id, 'toast', ({ message }) => {
    throwSuccessToast(message);
  });

  useEmbeddedEffect(id, 'popup', ({ message }) => {
    dispatch(
      actionSetPopupStack({
        params: {
          message,
          popupType: 'message',
          order: 3,
        },
      })
    );
  });

  useEffect(() => {
    if (!location || !src) {
      return;
    }

    const u = new URL(src);
    const regex = new RegExp(`^${base}`);

    if (location.pathname.indexOf(base) !== 0) {
      return;
    }

    const p = location.pathname.replace(regex, u.pathname.replace(/\/$/, ''));
    setPath(p + location.search);
  }, [src, location, base, id]);

  return (
    <MMEmbeddableZone
      isVisibilityForced={window.Android}
      id={id}
      src={src}
      path={path}
      context={context}
    />
  );
};

export { CustomTabZone };
