import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorPage from 'pages/Error';
import { SelectActivityType } from 'components/SelectActivityType';
import { SwitchActivityTypeReview } from 'components/SwitchActivityTypeReview';
import { CONTROL_ACTIONS, LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import useGoToHome from 'lib/hooks/useGoToHome';
import { useDispatch, useSelector } from 'react-redux';
import { AddWorkOrderModal } from 'components/JobTable/AddWorkOrderModal';
import {
  actionOpenLaborTicketModal,
  actionSetPendingActivityTypeRef,
} from 'lib/actions';
import Routes from 'lib/utils/routes';
import { getMachine } from 'lib/selectors/getMachine';
import useSubmitLaborTicket from 'components/LaborTicket/useSubmitLaborTicket';
import {
  getCanCloseLaborTicket,
  getCanOpenLaborTicket,
} from 'lib/selectors/getCanOpenLaborTicket';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';
import { getOpenActivityTypeMode } from 'lib/selectors/getOpenActivity';

const steps = {
  SELECT_ACTIVITY_TYPE: 0,
  SELECT_PARTS: 1,
  SUBMIT: 2,
};

const MultiStep = ({
  inSetup,
  controlAction,
  handleOnSelectActivityType,
  submit,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const laborTicketEnabled = useIsLaborTicketFlow();

  const canCloseLaborTicket = useSelector(getCanCloseLaborTicket);
  const canOpenLaborTicket = useSelector(getCanOpenLaborTicket);
  const isAPMEnabled = useSelector(getIsAPMEnabled);

  const requestLaborTicketAction = useSubmitLaborTicket();

  const [nextActivityTypeRef, setNextActivityTypeRef] = useState(null);
  const [openWorkOrderModal, setOpenWorkOrderModal] = useState(false);
  const isPaused = useSelector(getIsPaused);
  const machine = useSelector(getMachine);
  const currentActivityMode = useSelector(getOpenActivityTypeMode);

  const pausing = controlAction === CONTROL_ACTIONS.PAUSE;
  const [step, setStep] = useState(
    pausing ? steps.SELECT_PARTS : steps.SELECT_ACTIVITY_TYPE
  );
  const nextStep = () => {
    return setStep(step + 1);
  };

  const goToHome = useGoToHome();

  switch (step) {
    case steps.SELECT_ACTIVITY_TYPE:
      return (
        <>
          {openWorkOrderModal && !laborTicketEnabled && (
            <AddWorkOrderModal
              handleOnClose={() => {
                return setOpenWorkOrderModal(false);
              }}
              isStopStartNewRun
              onSubmit={submit}
              activityTypeRef={nextActivityTypeRef}
            />
          )}
          <SelectActivityType
            {...props}
            handleOnBack={goToHome}
            handleOnClose={goToHome}
            inSetup={inSetup}
            handleOnSelect={async (activityTypeRef, activityMode) => {
              handleOnSelectActivityType(activityTypeRef);
              setNextActivityTypeRef(activityTypeRef);

              if (
                currentActivityMode === activityMode ||
                isPaused ||
                (!currentActivityMode && !canOpenLaborTicket)
              ) {
                return submit(activityTypeRef);
              }

              if (laborTicketEnabled && !isAPMEnabled) {
                history.push(Routes.machineIdHomeTabPath(machine.id));

                if (canCloseLaborTicket) {
                  return dispatch(
                    actionOpenLaborTicketModal(
                      LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
                      { pendingActivityTypeRef: activityTypeRef }
                    )
                  );
                }

                if (canOpenLaborTicket) {
                  await dispatch(
                    actionSetPendingActivityTypeRef(activityTypeRef)
                  );
                  return requestLaborTicketAction({
                    action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
                  });
                }
              }
              nextStep();
              return null;
            }}
          />
        </>
      );
    case steps.SELECT_PARTS:
      return (
        <SwitchActivityTypeReview
          {...props}
          inSetup={inSetup}
          submit={submit}
          activityTypeRef={nextActivityTypeRef}
        />
      );
    default:
      return (
        /* Haven't updated translation for this */
        <ErrorPage
          error={new Error('Invalid step while changing activities')}
        />
      );
  }
};

export { MultiStep };
