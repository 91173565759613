import React from 'react';
import PropTypes from 'prop-types';
import { palette } from '@m12s/component-library';
import LaborTicketBaseActionModal from 'components/LaborTicket/LaborTicketBaseActionModal';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { getEnableStopJobAndSignOut } from 'lib/selectors/getEnableStopJobAndSignOut';
import { getLaborTicketAction } from 'lib/selectors/getLaborTicketAction';
import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import { ModalButton } from './styled';

// TODO: missing translations
const LaborTicketStopOrLeaveModal = ({
  isSetup = false,
  isOpen,
  handleClose,
  handleStopJob,
  handleLeaveOpen,
  handleStopJobAndSignOut,
}) => {
  const { t } = useTranslation();
  const enableStopJobAndSignOut = useSelector(getEnableStopJobAndSignOut);
  const laborTicketAction = useSelector(getLaborTicketAction);

  const shouldStopJobAndSignOut =
    LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT ===
      laborTicketAction && enableStopJobAndSignOut;

  const getHeaderText = () => {
    if (isSetup) return t('Leave the current setup open?');
    if (shouldStopJobAndSignOut) return t('Leave the current job running?');
    return t('Are you still working on this job?');
  };

  const getLeaveOrContinueText = () => {
    if (isSetup) return t('Leave Open');

    if (shouldStopJobAndSignOut) return t('Leave running');

    return t('Continue Working');
  };

  const getModalText = () => {
    if (isSetup) {
      return t(`Please confirm whether you would leave the current
        setup running on this machine in the MachineMetrics system, or if
        you would like to stop the job upon sign out (paused setup time
        will not be counted against expected).`);
    }

    return t(`Please confirm whether you would like to submit this labor ticket 
              and continue working on this job, or if you would like to stop submit 
              the labor ticket and stop the current job.`);
  };

  return (
    <>
      {isOpen && (
        <LaborTicketBaseActionModal
          isOpen={isOpen}
          onClose={handleClose}
          header={getHeaderText()}
          details={<div>{getModalText()}</div>}
          buttons={[
            shouldStopJobAndSignOut ? (
              <ModalButton
                key="sumbit-and-sign"
                variant="inferior"
                onClick={() => {
                  return handleStopJobAndSignOut();
                }}
              >
                {t('Submit Work and Sign Out')}
              </ModalButton>
            ) : (
              <ModalButton
                key="stop job"
                variant="inferior"
                onClick={() => {
                  return handleStopJob();
                }}
              >
                {t('Stop Job')}
              </ModalButton>
            ),
            <ModalButton
              key="leave-or-continue"
              customStyles={{
                backgroundColor: palette.Indigo600,
              }}
              hoverStyles={{
                backgroundColor: palette.Indigo700,
              }}
              onClick={() => {
                return handleLeaveOpen();
              }}
            >
              {getLeaveOrContinueText()}
            </ModalButton>,
          ]}
        />
      )}
    </>
  );
};

LaborTicketStopOrLeaveModal.propTypes = {
  isOpen: PropTypes.bool,
  handleStopJob: PropTypes.func,
  handleLeaveOpen: PropTypes.func,
  handleClose: PropTypes.func,
  isSetup: PropTypes.bool,
  handleStopJobAndSignOut: PropTypes.func,
};

export default LaborTicketStopOrLeaveModal;
