import { getLaborScopeTotalParts } from 'lib/selectors/getScopeTotalParts';
import { getIsERPJobControlBarFlow } from 'lib/selectors/getIsERPJobControlBarFlow';
import { getRejectedParts } from 'lib/selectors/getRejectedParts';
import { getScopeNetPartsMade } from 'lib/selectors/getScopeNetPartsMade';
import { createSelector } from 'reselect';

const getProductionPartsDisplay = createSelector(
  [
    getScopeNetPartsMade,
    getIsERPJobControlBarFlow,
    getRejectedParts,
    getLaborScopeTotalParts,
  ],
  (
    scopeNetPartsMade,
    showERPJobControlBar,
    rejectedParts,
    laborTicketPartsMade
  ) => {
    const displayParts = scopeNetPartsMade;

    return {
      isERPFlow: showERPJobControlBar,
      displayParts,
      rejectedParts,
      unsubmittedParts: laborTicketPartsMade,
    };
  }
);

export { getProductionPartsDisplay };
