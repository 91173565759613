import styled from 'styled-components';

import { palette } from '@m12s/component-library';
import { LoadingTextPlaceholder } from 'components/LoadingTextPlaceholder';

import { Display, Small } from 'components/Text';

const ProductionPartsSubtext = styled(Small).attrs({
  muted: true,
})`
  color: ${palette.Grey700};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
`;

const ProductionPartsDisplayContainer = styled(Display)`
  display: flex;
  flex-direction: column;
`;

const ProductionPartsDisplayText = styled.span`
  color: ${palette.Grey700};
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const LoadingCounterValue = styled(LoadingTextPlaceholder)`
  height: 2rem;
  width: 90%;
  border-radius: 2px;
`;

export {
  ProductionPartsSubtext,
  ProductionPartsDisplayContainer,
  ProductionPartsDisplayText,
  FlexRow,
  LoadingCounterValue,
};
