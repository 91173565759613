import { createSelector } from 'reselect';
import { getMachine } from 'lib/selectors/getMachine';
import _get from 'lodash/get';
import { getIsLaborTicketEnabled } from './getIsLaborTicketEnabled';

const getIsERPJobControlBarFlow = createSelector(
  [getMachine, getIsLaborTicketEnabled],
  (machine, laborTicketsEnabled) => {
    const erpResourceId = _get(machine, 'erpResourceId');
    const erpAssociatedResources = _get(machine, 'erpResources');

    return (
      laborTicketsEnabled && !!(erpResourceId || erpAssociatedResources?.length)
    );
  }
);

export { getIsERPJobControlBarFlow };
