// React and Hooks
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { bugsnag } from 'lib/external/bugsnag';
// Actions
import {
  actionCreatePartAdjustmentSuccess,
  actionSetloadingPartAdjustment,
} from 'lib/actions';
// Selectors
import { getMachine } from 'lib/selectors/getMachine';
import { getPartAdjustmentTypes } from 'lib/selectors/getPartAdjustmentTypes';
import { getLaborScopeTotalParts } from 'lib/selectors/getScopeTotalParts';
import { getBugsnagUserInfo } from 'lib/selectors/getBugsnagUserInfo';
// Utils
import { CREATE_PART_ADJUSTMENT } from 'lib/api/mutations';
import { throwErrorToast } from 'lib/utils/toast';
// Components

/**
 * Custom hook to handle part adjustments.
 * @param {Object} params - Parameters for the hook.
 * @param {Object} params.form - Form object from the LaborTicket modal.
 * @returns {Object} - Returns an object with the submitPartAdjustment function.
 */

const defaultForm = {
  totalParts: { value: 0 },
  rejectedParts: { value: 0 },
  rejectReason: { value: 0 },
};

const usePartAdjustment = ({ form = defaultForm }) => {
  const dispatch = useDispatch();
  const bugsnagUserInfo = useSelector(getBugsnagUserInfo);
  const machine = useSelector(getMachine);
  const partAdjustmentTypes = useSelector(getPartAdjustmentTypes);
  const laborTicketPartsMade = useSelector(getLaborScopeTotalParts);

  const [createPartAdjustment] = useMutation(CREATE_PART_ADJUSTMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ createPartAdjustmentResponse }) => {
      dispatch(actionCreatePartAdjustmentSuccess(createPartAdjustmentResponse));
    },
    onMutate: () => {
      dispatch(actionSetloadingPartAdjustment(true));
    },
    onError: (err) => {
      bugsnag.notify(err, (event) => {
        dispatch(actionSetloadingPartAdjustment(false));
        event.addMetadata('user', bugsnagUserInfo);
      });
      throwErrorToast('Failed to create part adjustment');
    },
  });

  const partAdjustment = form.totalParts.value - laborTicketPartsMade;
  const rejectedParts = form.rejectedParts?.value || 0;
  const rejectReason = form.rejectReason?.value || 0;
  const clockOut = form.clockOut?.value || null;
  // buffer adjustment time to within the labor ticket
  const adjustmenttime = clockOut
    ? new Date(clockOut.getTime() - 100).toISOString()
    : new Date(new Date().getTime() - 100).toISOString();

  /**
   * Get the reject behavior based on the part adjustment type.
   * @param {Object} partAdjustmentType - The part adjustment type object.
   * @returns {string} - The reject behavior.
   */
  const getRejectBehavior = (partAdjustmentType) => {
    return partAdjustmentType.rejectBehavior === 'operator'
      ? 'scrap'
      : partAdjustmentType.rejectBehavior;
  };

  /**
   * Submit the rejected parts adjustment.
   * @returns {Promise<void>} - A promise that resolves when the adjustment is submitted.
   */
  const submitRejectParts = async () => {
    const partAdjustmentTypeId = rejectReason.rejectReasonRef;
    const partAdjustmentType = partAdjustmentTypes.find((type) => {
      return type.id === rejectReason.rejectReasonRef;
    });
    const rejectBehavior = getRejectBehavior(partAdjustmentType);
    await createPartAdjustment({
      variables: {
        input: {
          scrapcount: rejectedParts,
          machine_id: machine.id,
          count: 0,
          reject_behavior: rejectBehavior,
          category_id: partAdjustmentTypeId,
          message: null,
          adjustmenttime,
        },
      },
    });
  };

  /**
   * Submit the part adjustment.
   * @returns {Promise<void>} - A promise that resolves when the adjustment is submitted.
   */
  const submitPartAdjustment = async () => {
    if (partAdjustment) {
      await createPartAdjustment({
        variables: {
          input: {
            count: partAdjustment,
            machine_id: machine.id,
            adjustmenttime,
          },
        },
      });
    }

    if (rejectedParts) {
      await submitRejectParts();
    }
  };

  return { submitPartAdjustment };
};

export default usePartAdjustment;
