import { createSelector } from 'reselect';

import { FLAG_KEYS } from 'lib/constants';
import { getLaunchDarklyFlags } from './getLaunchDarklyFlags';

export const getWorkOrderFlowEnabled = createSelector(
  [getLaunchDarklyFlags],
  (flags) => {
    return flags?.[FLAG_KEYS.WORK_ORDER_FLOW_ENABLED];
  }
);
