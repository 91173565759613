import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ErrorPage from 'pages/Error';
import { Loading } from 'components/Loading';
import MainLayout from 'components/Layouts/MainLayout';
import { AppNav } from 'components/AppNav';
import { WorkflowGrid } from 'components/WorkflowGrid';
import { H5 } from 'components/Text';

import _startCase from 'lodash/startCase';
import { palette } from '@m12s/component-library';

import { getMachine } from 'lib/selectors/getMachine';
import { getWorkflows } from 'lib/selectors/getWorkflows';
import { getNeedsForceLogin } from 'lib/selectors/getCanOpenLaborTicket';
import Routes from 'lib/utils/routes';
import { actionOpenSelectOperator } from 'lib/actions';
import { getSelectOperatorOpen } from 'lib/selectors/getSelectOperator';

const assignedWorkflows = (workflows, machineId) => {
  return workflows.filter((workflow) => {
    return (
      (machineId === workflow.machineId || workflow.machineId === null) &&
      workflow.monitorState === 'enabled'
    );
  });
};

const WorkflowTriggerPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const machine = useSelector(getMachine);
  const forceLogin = useSelector(getNeedsForceLogin);
  const selectOperatorOpen = useSelector(getSelectOperatorOpen);
  const workflows = useSelector(getWorkflows);
  const { loading, data } = workflows;

  const redirectPath = Routes.machineIdSelectWorkflowPath(machine.id);

  const goBack = useCallback(() => {
    return history.push(Routes.machineIdHomePath(machine.id));
  }, [history, machine]);

  useEffect(() => {
    if (forceLogin && !selectOperatorOpen) {
      dispatch(
        actionOpenSelectOperator({
          redirectPath,
        })
      );
    }
  }, [forceLogin, selectOperatorOpen, dispatch, redirectPath]);

  if (loading) return <Loading />;
  if (!data) return <ErrorPage />;
  if (forceLogin && !selectOperatorOpen) {
    return <Loading />;
  }

  const filteredData = assignedWorkflows(data, machine.id);

  return (
    <MainLayout.Container id="workflow-trigger" direction="column" overlay>
      <AppNav onBack={goBack} onClose={goBack}>
        <H5 color={palette.Yellow400} bold>
          {_startCase(t('select a workflow'))}
        </H5>
      </AppNav>
      {machine && <WorkflowGrid machine={machine} data={filteredData} t={t} />}
    </MainLayout.Container>
  );
};

export default WorkflowTriggerPage;
