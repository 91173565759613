import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';

import { DowntimeSelector } from 'components/DowntimeSelector';
import { DowntimeReasonSelector } from 'components/DowntimeReasonSelector';

import { DowntimeSubmit } from 'components/DowntimeSubmit';
import ErrorPage from 'pages/Error';
import { Loading } from 'components/Loading';
import MainLayout from 'components/Layouts/MainLayout';

import { useCategorization } from './useCategorization';
import { useAnnotationTypes } from './useAnnotationTypes';

const steps = {
  SELECT_DOWNTIME: 0,
  SELECT_REASON: 1,
  SUBMIT: 2,
};

const MultiStep = ({ node, setNode, preselected, ...props }) => {
  const [step, setStep] = useState(
    preselected ? steps.SELECT_REASON : steps.SELECT_DOWNTIME
  );
  const onBack = () => {
    return node.root ? previousStep() : setNode(node.parent);
  };
  const nextStep = () => {
    return setStep(step + 1);
  };
  const previousStep = () => {
    return setStep(step - 1);
  };
  const [filters, setFilters] = useState({ uncategorized: true, long: true });

  switch (step) {
    case steps.SELECT_DOWNTIME:
      return (
        <DowntimeSelector
          {...props}
          nextStep={nextStep}
          previousStep={previousStep}
          filters={filters}
          setFilters={setFilters}
        />
      );
    case steps.SELECT_REASON:
      return (
        <DowntimeReasonSelector
          {...props}
          node={node}
          onBack={preselected && node.root ? null : onBack}
          onNext={() => {
            return nextStep();
          }}
          onSelect={(child) => {
            const { children, data: annotationType } = child;

            if (children.length) {
              props.dispatch({ type: 'UNSET_ANNOTATION_TYPE' });
              setNode(child);
            } else {
              props.dispatch({
                type: 'SET_ANNOTATION_TYPE',
                annotationType,
              });
              nextStep();
            }
          }}
        />
      );
    case steps.SUBMIT:
      return (
        <DowntimeSubmit
          {...props}
          nextStep={nextStep}
          previousStep={previousStep}
        />
      );
    default:
      return (
        <ErrorPage
          error={new Error('Invalid step in categorize downtime flow')}
        />
      );
  }
};

const CategorizePage = ({ location }) => {
  const { preselected, splitting: shouldSplit, forced } = _get(
    location,
    'state',
    false
  );

  const returnPath = _get(location, ['state', 'returnPath'], '/');

  const { data, loading: categorizationLoading } = useCategorization({
    returnPath,
  });

  const { loading, node, setNode } = useAnnotationTypes();

  const { dispatch, splitting } = data;

  useEffect(() => {
    if (preselected) {
      if (shouldSplit) {
        dispatch({
          type: 'SELECT_DOWNTIME_TO_SPLIT',
          downtime: preselected,
        });
      } else {
        dispatch({
          type: 'TOGGLE_DOWNTIME',
          downtime: preselected,
        });
      }
    }
  }, [dispatch, preselected, shouldSplit]);

  if (loading || categorizationLoading) return <Loading />;

  return (
    <MainLayout.Container
      id="categorize-downtime-flow"
      direction="column"
      overlay
    >
      <MultiStep
        {...data}
        node={node}
        setNode={setNode}
        preselected={preselected}
        splitting={splitting}
        forced={forced}
        returnPath={returnPath}
      />
    </MainLayout.Container>
  );
};

export default CategorizePage;
