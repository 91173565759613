import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Icon, palette } from '@m12s/component-library';
import PropTypes from 'prop-types';
import { falTimes } from 'lib/icons';
import styled from 'styled-components';

export const IconWrapper = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
`;

const defaultModalStyles = {
  width: '100vw',
  height: '100vh',
  background: palette.Grey200,
  padding: 0,
  marginTop: '1.5rem',
};

const defaultHeaderStyles = {
  color: palette.Grey700,
  background: palette.White,
  borderBottom: `1px solid ${palette.Grey400}`,
  fontWeight: '500',
};

const defaultDetailsStyles = {
  height: '100%',
  padding: '1.5rem',
  overflow: 'auto',
};

const defaultButtonsStyles = {
  width: '100%',
  justifyContent: 'flex-end',
  borderTop: `1px solid ${palette.Grey400}`,
  background: palette.White,
  padding: '2rem',
};

const SwipeUpModal = (props) => {
  const {
    isOpen,
    animationDurationMs = 250,
    modalStyles = {},
    headerStyles = {},
    detailsStyles = {},
    buttonsStyles = {},
    onClose,
  } = props;

  const customHeaderStyles = {
    ...defaultHeaderStyles,
    ...(headerStyles ?? {}),
  };

  const customDetailsStyles = {
    ...defaultDetailsStyles,
    ...(detailsStyles ?? {}),
  };

  const customButtonsStyles = {
    ...defaultButtonsStyles,
    ...(buttonsStyles ?? {}),
  };

  const customModalStyles = useMemo(() => {
    const animation = isOpen
      ? `slide-up ${animationDurationMs / 1000}s ease-out forwards`
      : `slide-up-dismiss ${animationDurationMs / 1000}s ease-out forwards`;

    return {
      ...defaultModalStyles,
      ...(modalStyles ?? {}),
      animation,
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isOpen, modalStyles, animationDurationMs]);

  const [isOpenedDebounced, setIsOpenedDebounced] = useState(false);

  useEffect(() => {
    /**
     * Sets a timeout to update the debounced state of the modal's open status.
     * If the modal is opening, the state is updated immediately.
     * If the modal is closing, the state is updated after a delay of 250ms.
     *
     * @constant
     * @type {number}
     */
    const timeout = setTimeout(
      () => {
        setIsOpenedDebounced(isOpen);
      },
      isOpen ? 0 : animationDurationMs
    );
    return () => {
      return clearTimeout(timeout);
    };
  }, [isOpen]);

  return isOpenedDebounced ? (
    <Modal
      headerIcon={
        <IconWrapper
          onClick={() => {
            onClose();
          }}
        >
          <Icon
            size="2rem"
            color={palette.Grey700}
            icon={falTimes}
            cursor="pointer"
          />
        </IconWrapper>
      }
      {...props}
      isOpen={isOpenedDebounced}
      modalStyles={customModalStyles}
      headerStyles={customHeaderStyles}
      detailsStyles={customDetailsStyles}
      buttonsStyles={customButtonsStyles}
    />
  ) : null;
};

SwipeUpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  animationDurationMs: PropTypes.number,
  modalStyles: PropTypes.object,
  headerStyles: PropTypes.object,
  detailsStyles: PropTypes.object,
  buttonsStyles: PropTypes.object,
  onClose: PropTypes.func,
};

SwipeUpModal.defaultProps = {
  animationDurationMs: 250,
  modalStyles: {},
  headerStyles: {},
  detailsStyles: {},
  buttonsStyles: {},
  onClose: () => {},
};

export default SwipeUpModal;
