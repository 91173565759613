// React and Hooks
import React from 'react';
// Actions
// Selectors
// Utils
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
// Components
import { palette, Icon } from '@m12s/component-library';
import { check, exclamationCircle } from 'lib/icons';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';
import { useSelector } from 'react-redux';

const OrderStatusContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  color: ${palette.Grey700};
  font-size: 0.875rem;
  font-weight: 500;
`;

const OrderStatusLabel = styled.label`
  font-size: '0.875rem';

  span {
    color: ${palette.Red600};
    margin-left: 0.25rem;
`;

const OrderStatusOptionsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const OrderStatusOption = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex: 1; // Added to take up full width
`;

const HiddenRadio = styled.input.attrs({
  'aria-hidden': 'true',
})`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const IconContainer = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const OrderStatusError = styled.div`
  display: flex;
  gap: 0.5rem;
  color: ${palette.Red600};
  font-family: 'Noto Sans';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.003rem;
  transition: all 0.2s ease;
`;

// Style for the span inside StatusIndicator
const StatusIndicatorText = styled.span`
  font-family: 'Noto Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 114.286% */
  letter-spacing: 0.00438rem;
  color: ${palette.Grey500};
`;

const StatusIndicatorChecked = css`
  background-color: ${palette.Indigo100};
  border-color: ${palette.Indigo600};
  color: ${palette.Indigo600};

  // colors for the check icon
  ${IconContainer} {
    color: ${palette.Indigo600};
  }

  // Text color when selected
  ${StatusIndicatorText} {
    color: ${palette.Indigo600};
  }
`;

const StatusIndicatorDisabled = css`
  opacity: 0.5;
  cursor: not-allowed;
`;

const StatusIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: ${palette.Grey100};
  border: 1px solid ${palette.Grey400};
  flex: 1; // Added to take up full width

  ${({ checked }) => {
    return checked && StatusIndicatorChecked;
  }}
  ${({ disabled }) => {
    return disabled && StatusIndicatorDisabled;
  }}
`;

const StatusInput = ({ form, handleFormChange, loading }) => {
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);

  return (
    <OrderStatusContainer>
      <OrderStatusLabel>
        {hasOpenSetupActivity ? 'Setup Status' : 'Job Status'}
        <span>*</span>
      </OrderStatusLabel>
      <OrderStatusOptionsRow>
        <OrderStatusOption>
          <HiddenRadio
            type="radio"
            name="workOrderStatus"
            value="incomplete"
            checked={form.workOrderComplete.value === false}
            onChange={() => {
              handleFormChange('workOrderComplete', false, null);
            }}
            disabled={loading}
          />
          <StatusIndicator
            style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
            checked={form.workOrderComplete.value === false}
            disabled={loading}
          >
            {form.workOrderComplete.value === false && (
              <IconContainer>
                <Icon icon={check} />
              </IconContainer>
            )}

            <StatusIndicatorText>Incomplete</StatusIndicatorText>
          </StatusIndicator>
        </OrderStatusOption>
        <OrderStatusOption>
          <HiddenRadio
            type="radio"
            name="workOrderStatus"
            value="complete"
            checked={form.workOrderComplete.value === true}
            onChange={() => {
              handleFormChange('workOrderComplete', true, null);
            }}
            disabled={loading}
          />
          <StatusIndicator
            style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
            checked={form.workOrderComplete.value === true}
            disabled={loading}
          >
            {form.workOrderComplete.value === true && (
              <IconContainer>
                <Icon icon={check} />
              </IconContainer>
            )}
            <StatusIndicatorText>Complete</StatusIndicatorText>
          </StatusIndicator>
        </OrderStatusOption>
      </OrderStatusOptionsRow>
      {form.workOrderComplete.error !== null && (
        <OrderStatusError>
          <Icon icon={exclamationCircle} />
          {form.workOrderComplete.error}
        </OrderStatusError>
      )}
    </OrderStatusContainer>
  );
};

StatusInput.propTypes = {
  form: PropTypes.object,
  handleFormChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default StatusInput;
