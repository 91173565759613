import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Icon, CardGrid } from '@m12s/component-library';
import Downshift from 'downshift';
import { chevronRight, questionCircle } from 'lib/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useOperatorLogout from 'components/LaborTicket/hooks/useOperatorLogout';
import Routes from 'lib/utils/routes';

import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import {
  getActiveOperators,
  getHasUnmappedActiveOperators,
} from 'lib/selectors/getActiveOperators';

import { SignInModal } from 'components/SignInModal';
import { SignOutModal } from 'components/SignOutModal';
import { SearchInput } from 'components/SearchInput';
import { EmptyWithGraphic } from 'components/Empty/EmptyWithGraphic';
import NoOperators from 'graphics/NoOperators';
import { InlineAlert } from 'components/InlineAlert/InlineAlert';
import _startCase from 'lodash/startCase';

import { getIsERPJobControlBarFlow } from 'lib/selectors/getIsERPJobControlBarFlow';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';
import { getMachine } from 'lib/selectors/getMachine';
import { getCanCloseLaborTicket } from 'lib/selectors/getCanOpenLaborTicket';
import { actionOpenLaborTicketModal } from 'lib/actions';
import { LABOR_TICKET_ACTIONS } from 'lib/constants';

import SwipeUpModal from 'components/SwipeUpModal';
import { Root, GridWrapper, InputWrapper, CardGridItem } from './styled';

function stateReducer(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.blurInput:
    case Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem:
    case Downshift.stateChangeTypes.mouseUp:
    case Downshift.stateChangeTypes.touchEnd:
    case Downshift.stateChangeTypes.clickItem:
      return state;
    default:
      return changes;
  }
}

const selectedInitialValue = { operator: null, confirmed: false };

/**
 * UserSelect component allows operators to sign in/out and switch between operator profiles
 *
 * @component
 * @param {Object} props
 * @param {Function} props.onSubmit - Callback function called when an operator successfully signs in
 * @param {boolean} props.isOpen - Controls visibility of the modal
 * @param {Function} [props.onClose] - Optional callback function when modal is closed
 */
const UserSelect = ({ onSubmit, isOpen, onClose = () => {} }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const currentOperator = useSelector(getCurrentOperator);

  const operators = useSelector(getActiveOperators);
  const hasUnmappedActiveOperators = useSelector(getHasUnmappedActiveOperators);
  const [selected, setSelected] = useState(selectedInitialValue);

  const unconfirmed =
    !!currentOperator && !!selected.operator && !selected.confirmed;
  const confirmed =
    !!selected.operator && (!currentOperator || selected.confirmed);

  // Signout flow
  const showERPJobControlBar = useSelector(getIsERPJobControlBarFlow);
  const laborTicketEnabled = useIsLaborTicketFlow();
  const canCloseLaborTicket = useSelector(getCanCloseLaborTicket);
  const machine = useSelector(getMachine);
  const dispatch = useDispatch();

  const stopOperator = useOperatorLogout(() => {
    onClose();
  });

  // End Signout flow

  return (
    <Root id="user-select-flow">
      {unconfirmed && isOpen && (
        <SignOutModal
          operator={selected.operator}
          currentOperator={currentOperator}
          onYes={() => {
            return setSelected({ ...selected, confirmed: true });
          }}
          onNo={() => {
            return setSelected(selectedInitialValue);
          }}
          onClose={() => {
            return setSelected(selectedInitialValue);
          }}
        />
      )}
      {confirmed && isOpen && (
        <SignInModal
          currentOperator={currentOperator}
          operator={selected.operator}
          onSubmit={(...args) => {
            return onSubmit(...args) && setSelected(selectedInitialValue);
          }}
          onClose={() => {
            return setSelected(selectedInitialValue);
          }}
        />
      )}
      <SwipeUpModal
        isOpen={isOpen}
        header={t('Select Operator')}
        onClose={() => {
          return onClose();
        }}
        details={
          <Downshift
            isOpen
            selectedItem={selected.operator}
            stateReducer={stateReducer}
            itemToString={(item) => {
              return item ? item.name : '';
            }}
          >
            {({ getInputProps, getMenuProps, inputValue, getRootProps }) => {
              const filteredOperators = operators.filter((item) => {
                const isCurrentOperator = !!(
                  currentOperator && item?.id === currentOperator?.id
                );
                if (isCurrentOperator) {
                  // exclude active operator from card list
                  return false;
                }
                return item.name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              });
              const hasOperatorProfiles = operators.length > 0;

              return (
                <>
                  <InputWrapper hidden={operators.length === 0}>
                    <SearchInput
                      rootProps={getRootProps({}, { suppressRefError: true })}
                      inputProps={getInputProps({
                        onBlur: (fn) => {
                          return fn;
                        },
                      })}
                    />
                  </InputWrapper>
                  {hasUnmappedActiveOperators && (
                    <InlineAlert text={t('operatorUnlistedOnERPCopy')} />
                  )}
                  {hasOperatorProfiles ? (
                    <GridWrapper>
                      <CardGrid {...getMenuProps()}>
                        {!showERPJobControlBar && currentOperator && (
                          <CardGridItem
                            color={null}
                            key="signout"
                            type="pill"
                            icon={<Icon icon={chevronRight} />}
                            onClick={() => {
                              if (laborTicketEnabled && canCloseLaborTicket) {
                                history.push(
                                  Routes.machineIdHomePath(machine.id)
                                );
                                return dispatch(
                                  actionOpenLaborTicketModal(
                                    LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT
                                  )
                                );
                              }
                              return stopOperator();
                            }}
                          >
                            {_startCase(t(`sign out`))}
                          </CardGridItem>
                        )}

                        {filteredOperators.map((item) => {
                          return (
                            <CardGridItem
                              color={null}
                              key={item.id}
                              type="pill"
                              icon={<Icon icon={chevronRight} />}
                              onClick={() => {
                                return setSelected({
                                  operator: item,
                                  confirmed: false,
                                });
                              }}
                            >
                              {item.name}
                            </CardGridItem>
                          );
                        })}
                      </CardGrid>
                    </GridWrapper>
                  ) : (
                    <EmptyWithGraphic
                      icon={hasOperatorProfiles && questionCircle}
                      text={
                        hasOperatorProfiles
                          ? t('No Results Found')
                          : t('No Operators')
                      }
                      subtext={
                        hasOperatorProfiles
                          ? t('noOperatorFound')
                          : t('noOperatorResult')
                      }
                      Graphic={
                        !hasOperatorProfiles && <NoOperators margin="1rem" />
                      }
                    />
                  )}
                </>
              );
            }}
          </Downshift>
        }
      />
    </Root>
  );
};

UserSelect.propTypes = {
  /** Callback function called when an operator successfully signs in */
  onSubmit: PropTypes.func.isRequired,
  /** Controls visibility of the modal */
  isOpen: PropTypes.bool.isRequired,
  /** Optional callback function when modal is closed */
  onClose: PropTypes.func,
};

export { UserSelect };
