import { gql } from '@apollo/client';

export const ACTIVITY_FRAGMENT = gql`
  fragment ActivityFields on Activity {
    activityRef
    activitySetRef
    activityTypeRef
    start: startAt
    end: endAt
    updatedAt
    jobScheduleGroupId
  }
`;

export const ACTIVITY_SET_FRAGMENT = gql`
  fragment ActivitySetFields on ActivitySet {
    activitySetRef
    companyId
    machineRef
    closedAt
    updatedAt
    expectedSetupDuration
    idealUnitDuration
    expectedUnitDuration
    jobId
    partCountMultiplier
    actualParts
    workOrderId
    workOrderOperationRef
  }
`;

export const PART_ADJUSTMENT_FRAGMENT = gql`
  fragment PartAdjustmentFields on PartAdjustment {
    id
    message
    adjustmenttime
    scrapcount
    count
    dataitemname
    scrap
    jobName
  }
`;

export const WORK_ORDER_FRAGMENT = gql`
  fragment WorkOrderFields on erpWorkOrder {
    partNumber
    lot
    workOrderId
    sub
    split
  }
`;

export const WORK_ORDER_OPERATIONS_FRAGMENT = gql`
  fragment WorkOrderOperationsFields on erpWorkOrderOperations {
    closedDate
    finishQuantity
    workOrderOperationRef
    workOrderId
    resourceId
    scheduledFinishDate
    scheduledStartDate
    setupTimeMs
    currentQuantity: startQuantity
    status
    sequenceNumber
    lot
    sub
    split
    cycleTimeMs
    quantityPerPart
  }
`;

export const RESOURCE_FRAGMENT = gql`
  fragment ResourceFields on erpResources {
    machineRef
    resourceId
    isResourceGroup
  }
`;

export const BASE_LABOR_TICKET_FIELDS_FRAGMENT = gql`
  fragment BaseLaborTicketFields on erpLaborTickets {
    badParts
    clockIn
    clockOut
    goodParts
    laborTicketId
    laborTicketRef
    lot
    personId
    resourceId
    sequenceNumber
    state
    split
    sub
    transactionDate
    type
    workOrderId
  }
`;

export const LABOR_TICKET_REASON_FIELDS = gql`
  fragment LaborTicketReasonFields on erpLaborTicketReasons {
    reasonRef
    reason {
      annotationTypeRef
    }
  }
`;

export const OPERATOR_RUN_FRAGMENT = gql`
  fragment OperatorRunFields on OperatorRun {
    id: operatorRunId
    startAt
    endAt
    companyId
    operator @type(name: "Operator") {
      id
      name
    }
    machine @type(name: "Machine") {
      id
      name
    }
    createdAt
    updatedAt
  }
`;
