import React from 'react';
import { getIsERPJobControlBarFlow } from 'lib/selectors/getIsERPJobControlBarFlow';
import { useSelector } from 'react-redux';
import { StandardOperatorPill } from './StandardOperatorPill';

const OperatorPill = () => {
  const showERPJobControlBar = useSelector(getIsERPJobControlBarFlow);

  return !showERPJobControlBar && <StandardOperatorPill />;
};

export { OperatorPill };
