import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ControlBarButton } from '@m12s/component-library';
import { sync } from 'lib/icons';
import Routes from 'lib/utils/routes';
import { useSelector } from 'react-redux';
import { getSwitchActivityTypesOptions } from 'lib/selectors/getSwitchActivityTypesOptions';
import { MobileIcon, ResponsiveText } from '../styled';

/**
 * SwitchActivity component renders a button to switch between different activity types
 * for a machine. The button is only shown if there are multiple activity types available
 * and the machine is not paused.
 *
 * @param {Object} props
 * @param {Object} props.history - React Router history object for navigation
 * @param {Object} props.machine - Machine object containing machine details
 * @param {string} props.machine.id - Unique identifier for the machine
 * @param {boolean} props.isPaused - Flag indicating if the machine is in paused state
 * @returns {React.ReactElement|null} Returns the switch activity button or null if only one activity type is available or machine is paused
 */
const SwitchActivity = ({ history, machine, isPaused }) => {
  const { t } = useTranslation();

  const activityTypes = useSelector(getSwitchActivityTypesOptions);

  // if there are multiple activity types options, show the switch activity button
  if (activityTypes?.length > 1 && !isPaused) {
    return (
      <ControlBarButton
        id="job-control-switch-activity"
        onClick={() => {
          return history.push(Routes.machineIdSwitchActivitiesPath(machine.id));
        }}
      >
        <MobileIcon icon={sync} />
        <ResponsiveText
          full={t('switchActivity|full')}
          medium={t('switchActivity|medium')}
          short={t('switchActivity|short')}
        />
      </ControlBarButton>
    );
  }

  return null;
};

SwitchActivity.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  machine: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  isPaused: PropTypes.bool,
};

SwitchActivity.defaultProps = {
  isPaused: false,
};

export { SwitchActivity };
