import React from 'react';
import moment from 'moment-timezone';
import { palette, theme } from '@m12s/component-library';
import { useSelector } from 'react-redux';

import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getScopeEnd } from 'lib/selectors/getScopeEnd';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { LONG_SCOPE_HOURS } from 'lib/constants';
import useRemPx from 'lib/hooks/useRemPx';
import { formatTime } from 'lib/utils/date';

import { getScopeNetPartCountHourlies } from 'lib/selectors/getScopeNetPartCountHourlies';
import { Chart } from './Chart';

const getItemStyle = ({ sum, time }) => {
  if (moment.utc().isSame(time, 'hour')) return { color: palette.Purple400 };
  if (sum === 0) return { color: palette.Red600 };

  return {};
};

const PartsMadeChart = ({ calculateValue }) => {
  const remPx = useRemPx();
  const scopeStart = useSelector(getScopeStart);
  const scopeEnd = useSelector(getScopeEnd);
  const data = useSelector(getScopeNetPartCountHourlies);


  const longScope =
    moment.utc(scopeEnd).diff(scopeStart, 'hours') > LONG_SCOPE_HOURS;

  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);

  if (!scopeStart || !scopeEnd) {
    // prevent renders for invalid scope
    return null;
  }

  const loadingOption = {
    xAxis: {
      data: ['-'],
      axisLabel: {
        margin: remPx / 2,
      },
    },
    yAxis: {
      data: ['0', '-', '-', '-'],
      axisLabel: {
        margin: remPx * 1.5,
      },
    },
    grid: {
      backgroundColor: theme.colors.loader.textPlaceholder,
    },
  };

  const option = {
    xAxis: {
      type: 'category',
      axisLabel: {
        margin: 6,
        formatter: (value) => {
          return formatTime(value);
        },
        fontSize: remPx / 1.75,
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        interval: 0,
        lineStyle: {
          color: palette.Grey200,
        },
      },
    },
    yAxis: {
      min: 0,
      axisLabel: {
        margin: remPx * 1.5,
        fontSize: remPx / 1.75,
      },
    },
    series: [
      {
        type: 'bar',
        animation: false,
        data: data.map(item => {return calculateValue(item, getItemStyle)}),
        label: {
          fontSize: 10,
          formatter: ({ value, dataIndex }) => {
            const label = value[1] ? value[1] : '';

            if (!longScope) return label;

            if (dataIndex % 2 !== 0) return label;

            return '';
          },
        },
        barWidth: data.length > 12 ? remPx / 2 : remPx / 1.25,
      },
    ],
    itemStyle: {
      color: palette.Grey300,
    },
  };

  return (
    <Chart
      option={arePartCountBucketsLoading ? loadingOption : option}
      loading={arePartCountBucketsLoading}
    />
  );
};

export { PartsMadeChart };
