import { createSelector } from 'reselect';
import { FLAG_KEYS } from 'lib/constants';
import { getLaunchDarklyFlags } from './getLaunchDarklyFlags';

export const getScheduledTime = (state) => {
  return state?.scheduledTime;
};

export const getScheduledTimeEnabled = createSelector(
  [getLaunchDarklyFlags],
  (flags) => {
    return flags?.[FLAG_KEYS.SCHEDULED_TIME_ENABLED];
  }
);

export const getScheduledTimeMs = createSelector(
  [getScheduledTime, getScheduledTimeEnabled],
  (scheduledTime, isEnabled) => {
    return isEnabled && !scheduledTime.loading
      ? scheduledTime.data.stats.scheduledTimeMs
      : null;
  }
);
