import React from 'react';
import styled from 'styled-components';
import { palette } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';

const InSetupDisplayTextStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: ${palette.Grey700};
  font-size: 1.5rem;
  font-weight: 700;
`;

const InSetupDisplayText = () => {
  const { t } = useTranslation();
  return <InSetupDisplayTextStyle>{t('In Setup')}</InSetupDisplayTextStyle>;
};

export { InSetupDisplayText };
