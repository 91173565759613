import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import {
  actionSetSetupRawParts,
  actionSetShiftSetupRawParts,
} from 'lib/actions';
import { GET_PART_BUCKETS_BY_WINDOW } from 'lib/api/queries';
import { useNetworkError } from 'lib/hooks';
import { ERROR_KEYS } from 'lib/constants';
import { getHasOpenActivitySetInSetup } from 'lib/selectors/getHasOpenActivitySetInSetup';
import { getMachine } from 'lib/selectors/getMachine';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getPartKeys } from 'lib/selectors/getPartKeys';
import { getLastPartTimestamp } from 'lib/selectors/getLastPartTimestamp';
import { formatIntervalsAsTSRange } from 'lib/utils/date';
import { getLatestActivityStartAt } from 'lib/selectors/getLatestActivityStartAt';
import { getShiftEnd } from 'lib/selectors/getShiftEnd';
import _sumBy from 'lodash/sumBy';
import { getCurrentSetupModeActivities } from 'lib/selectors/getCurrentSetupModeActivities';
import { chopBefore } from 'lib/utils/intervals';
import { getSetupModeSpansShift } from 'lib/selectors/getSetupModeSpansShift';
import { getShiftStart } from 'lib/selectors/getShiftStart';
import { getSetupModeStart } from 'lib/selectors/getSetupModeStart';

export const useSetupData = () => {
  const dispatch = useDispatch();
  const hasOpenActivitySetInSetup = useSelector(getHasOpenActivitySetInSetup);
  const machine = useSelector(getMachine);
  const scopeStart = useSelector(getScopeStart);
  const partKeys = useSelector(getPartKeys);
  const lastPartTimestamp = useSelector(getLastPartTimestamp);
  const latestActivityStartAt = useSelector(getLatestActivityStartAt);
  const shiftEnd = useSelector(getShiftEnd);
  const shiftStart = useSelector(getShiftStart);
  const currentSetupModeActivities = useSelector(getCurrentSetupModeActivities);
  const setupModeSpansShift = useSelector(getSetupModeSpansShift);
  const setupModeStart = useSelector(getSetupModeStart);

  // Parts since start of setup mode
  const requestPartBuckets = useQuery(GET_PART_BUCKETS_BY_WINDOW, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      machineRef: machine.machineRef,
      startDate: setupModeStart,
      partKeys,
      lastPartTimestamp,
      bucketWidth: '24h',
      intervals: formatIntervalsAsTSRange(currentSetupModeActivities),
    },
    skip:
      !hasOpenActivitySetInSetup ||
      !machine.machineRef ||
      !partKeys ||
      !latestActivityStartAt,
    onCompleted: ({ aggregatedCountsByActivitySet: partCountBuckets }) => {
      const sum = _sumBy(partCountBuckets, 'sum');
      dispatch(actionSetSetupRawParts({ sum }));
    },
  });

  useNetworkError(requestPartBuckets, ERROR_KEYS.SETUP_PART_BUCKETS);

  // Part Buckets for this shift setup only.
  // Requested only if setup spans multiple shifts.
  const requestShiftParts = useQuery(GET_PART_BUCKETS_BY_WINDOW, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      machineRef: machine.machineRef,
      startDate: scopeStart,
      partKeys,
      lastPartTimestamp,
      bucketWidth: '24h',
      intervals: formatIntervalsAsTSRange(
        chopBefore(currentSetupModeActivities, shiftStart, true)
      ),
    },
    skip:
      !setupModeSpansShift ||
      !hasOpenActivitySetInSetup ||
      !machine.machineRef ||
      !partKeys ||
      !shiftStart ||
      !shiftEnd ||
      !latestActivityStartAt,
    onCompleted: ({ aggregatedCountsByActivitySet: partCountBuckets }) => {
      const sum = _sumBy(partCountBuckets, 'sum');
      dispatch(actionSetShiftSetupRawParts({ sum }));
    },
  });

  useNetworkError(requestShiftParts, ERROR_KEYS.SHIFT_SETUP_PART_BUCKETS);
};
