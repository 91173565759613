import { createSelector } from 'reselect';
import { getIsOperatorTrackingEnabled } from 'lib/selectors/getIsOperatorTrackingEnabled';
import { getIsOperatorRunAutoSignoutEnabled } from 'lib/selectors/getIsOperatorRunAutoSignoutEnabled';
import { getWorkOrderFlowEnabled } from './getWorkOrderFlowEnabled';

const getShouldUseOpRunOverrideScope = createSelector(
  [
    getIsOperatorTrackingEnabled,
    getIsOperatorRunAutoSignoutEnabled,
    getWorkOrderFlowEnabled,
  ],
  (
    isOperatorTrackingEnabled,
    isOperatorRunAutoSignoutEnabled,
    workOrderFlowEnabled
  ) => {
    const shouldUseOpRunOverrideScope = !!(
      isOperatorTrackingEnabled &&
      (!isOperatorRunAutoSignoutEnabled || workOrderFlowEnabled)
    );
    return shouldUseOpRunOverrideScope;
  }
);

export { getShouldUseOpRunOverrideScope };
