// React and Hooks
import React from 'react';
import { useTranslation } from 'react-i18next';
// Actions
// Selectors
// Utils
import PropTypes from 'prop-types';
import { palette, Checkbox } from '@m12s/component-library';
// Components
import LaborTicketBaseActionModal from 'components/LaborTicket/LaborTicketBaseActionModal';
import { ModalButton } from 'components/LaborTicket/styled';

/**
 * SetupIncompleteModal component renders a modal for stopping or pausing a job setup.
 * This is used if FLAG_KEYS.STOP_INCOMPLETE_JOB_AND_SIGN_OUT_ENABLED is true.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showIncompleteSetupModal - Flag to show or hide the modal.
 * @param {Function} props.setShowIncompleteSetupModal - Function to set the visibility of the modal.
 * @param {boolean} props.isLogout - Flag indicating if the user is logging out.
 * @param {Function} props.setIsLogout - Function to set the logout flag.
 * @param {Function} props.handleConfirmStopJob - Function to handle the stop job confirmation.
 * @param {Function} props.handlePause - Function to handle the pause action.
 * @returns {JSX.Element} The rendered component.
 */
const SetupIncompleteModal = ({
  showIncompleteSetupModal,
  setShowIncompleteSetupModal,
  isLogout,
  setIsLogout,
  handleConfirmStopJob,
  handlePause,
}) => {
  const { t } = useTranslation();
  const inputProps = {
    type: 'checkbox',
    id: 'incompleteSetupCheckbox',
    name: 'incompleteSetupCheckbox',
    checked: isLogout,
    onChange: (e) => {
      setIsLogout(e.target.checked);
    },
    backgroundColor: palette.Indigo600,
    borderColor: palette.Indigo600,
  };
  const labelProps = {
    for: inputProps.id,
    handleLabelClick: () => {
      setIsLogout(!isLogout);
    },
    style: {
      color: 'inherit',
    },
  };
  return (
    <LaborTicketBaseActionModal
      isOpen={showIncompleteSetupModal}
      onClose={() => {
        setShowIncompleteSetupModal(false);
      }}
      header={t('setupIncompleteModal|header')}
      details={
        <>
          <div
            style={{
              marginBottom: '1rem',
            }}
          >
            {t('setupIncompleteModal|body')}
          </div>
          <Checkbox labelProps={labelProps} inputProps={inputProps}>
            {t('setupIncompleteModal|checkboxLabel')}
          </Checkbox>
        </>
      }
      buttons={[
        <ModalButton
          key="endSetupButton"
          customStyles={{
            backgroundColor: palette.Grey200,
            color: palette.Grey700,
          }}
          hoverStyles={{
            backgroundColor: palette.Grey300,
          }}
          onClick={handleConfirmStopJob}
        >
          {t('setupIncompleteModal|buttons|endSetup')}
        </ModalButton>,
        <ModalButton
          key="pauseButton"
          customStyles={{
            backgroundColor: palette.Indigo600,
            color: palette.White,
          }}
          hoverStyles={{
            backgroundColor: palette.Indigo700,
          }}
          onClick={handlePause}
        >
          {t('setupIncompleteModal|buttons|pause')}
        </ModalButton>,
      ]}
    />
  );
};

SetupIncompleteModal.propTypes = {
  showIncompleteSetupModal: PropTypes.bool,
  setShowIncompleteSetupModal: PropTypes.func,
  isLogout: PropTypes.bool,
  setIsLogout: PropTypes.func,
  handleConfirmStopJob: PropTypes.func,
  handlePause: PropTypes.func,
};

export default SetupIncompleteModal;
