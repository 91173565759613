import _get from 'lodash/get';

import {
  LAUNCH_DARKLY_CLIENT_ID_DEV,
  LAUNCH_DARKLY_CLIENT_ID_STAGING,
  LAUNCH_DARKLY_CLIENT_ID_DEMO,
  LAUNCH_DARKLY_CLIENT_ID_PROD,
  LAUNCH_DARKLY_CLIENT_ID_GOV,
} from 'lib/clientIds';

const DEV_GRAPHQL = 'https://development.graphql.machinemetrics.com/v1/graphql';
const PROD_GRAPHQL = 'https://graphql.machinemetrics.com/v1/graphql';
const GOVCLOUD_GRAPHQL = 'https://graphql.machinemetrics-us-gov.com/v1/graphql';
const DEV_HOST = 'development.machinemetrics.com';
const DEV_SSL_HOST = 'operator.development.machinemetrics.com';
const DEMO_HOST = 'operator.demo.machinemetrics.com';
const STAGING_HOST = 'operator.staging.machinemetrics.com';
const PREVIEW_HOST = 'operator.preview.machinemetrics.com';
const PRODUCTION_HOST = 'operator.machinemetrics.com';
const GOVCLOUD_HOST = 'operator.machinemetrics-us-gov.com';

export const isTest = _get(process, ['env', 'NODE_ENV']) === 'test';

const DEFAULT_CLIENT_ID = 'b79676fa0f62a6963c7ac3e21387acda';
const DEFAULT_CLIENT_SECRET =
  '4a8b3c011beb41d47fe7d604c389d40d8569a0da9f5b1a90';
const GOV_CLIENT_ID = 'aad45c262ff0d6c7033d0de035d3eb49';
const GOV_CLIENT_SECRET = '92e920a46b426c0723136180ba7cb4f2a228a5814b904a0d';

const envCheck = (hostnameIn = DEV_HOST) => {
  let hostname = hostnameIn;
  if (hostname === 'localhost') {
    hostname = DEV_HOST;
  }

  const baseConfig = {
    client_id: DEFAULT_CLIENT_ID,
    client_secret: DEFAULT_CLIENT_SECRET,
  };

  switch (hostname) {
    case DEV_SSL_HOST:
      // NOTE (jacob l): This requires a special case in haproxy to work
      return {
        ...baseConfig,
        releaseStage: isTest ? 'test' : 'development',
        app: `https://${DEV_SSL_HOST}`,
        api: 'https://api.development.machinemetrics.com',
        login: 'https://login.development.machinemetrics.com',
        graphql: DEV_GRAPHQL,
        labs: null,
        launchDarklyClientId: LAUNCH_DARKLY_CLIENT_ID_DEV,
      };
    case DEV_HOST:
      return {
        ...baseConfig,
        releaseStage: isTest ? 'test' : 'development',
        app: `http://${DEV_HOST}:3000`,
        api: 'https://api.development.machinemetrics.com',
        login: 'https://login.development.machinemetrics.com',
        graphql: DEV_GRAPHQL,
        labs: null,
        launchDarklyClientId: LAUNCH_DARKLY_CLIENT_ID_DEV,
      };
    case PRODUCTION_HOST:
      return {
        ...baseConfig,
        releaseStage: 'production',
        app: `https://${PRODUCTION_HOST}`,
        api: 'https://api.machinemetrics.com',
        login: 'https://login.machinemetrics.com',
        graphql: PROD_GRAPHQL,
        labs: 'https://labs.machinemetrics.com',
        launchDarklyClientId: LAUNCH_DARKLY_CLIENT_ID_PROD,
      };
    case STAGING_HOST:
      return {
        ...baseConfig,
        releaseStage: 'staging',
        app: `https://${STAGING_HOST}`,
        api: 'https://api-staging.machinemetrics.com',
        login: 'https://login-staging.machinemetrics.com',
        graphql: DEV_GRAPHQL,
        labs: 'https://labs.staging-machinemetrics.com',
        launchDarklyClientId: LAUNCH_DARKLY_CLIENT_ID_STAGING,
      };
    case PREVIEW_HOST:
      return {
        ...baseConfig,
        releaseStage: 'preview',
        app: `https://${PREVIEW_HOST}`,
        api: 'https://api-preview.machinemetrics.com',
        login: 'https://login-preview.machinemetrics.com',
        graphql: DEV_GRAPHQL,
        labs: 'https://labs.preview.machinemetrics.com',
        launchDarklyClientId: LAUNCH_DARKLY_CLIENT_ID_STAGING,
      };
    case DEMO_HOST:
      return {
        ...baseConfig,
        releaseStage: 'demo',
        app: `https://${DEMO_HOST}`,
        api: 'https://api-demo.machinemetrics.com',
        login: 'https://login-demo.machinemetrics.com',
        graphql: DEV_GRAPHQL,
        labs: null,
        launchDarklyClientId: LAUNCH_DARKLY_CLIENT_ID_DEMO,
      };
    case GOVCLOUD_HOST:
      return {
        releaseStage: 'govcloud',
        app: `https://${GOVCLOUD_HOST}`,
        api: 'https://api.machinemetrics-us-gov.com',
        login: 'https://login.machinemetrics-us-gov.com',
        graphql: GOVCLOUD_GRAPHQL,
        labs: null,
        launchDarklyClientId: LAUNCH_DARKLY_CLIENT_ID_GOV,
        client_id: GOV_CLIENT_ID,
        client_secret: GOV_CLIENT_SECRET,
      };
    default:
      return null;
  }
};

const stageMap = window.location.hostname
  ? envCheck(window.location.hostname)
  : envCheck(DEV_HOST);

export const RELEASE_STAGE = stageMap.releaseStage;
export const APP_URI = stageMap.app;
export const REST_URI = stageMap.api;
export const LOGIN_URL = stageMap.login;
export const GRAPHQL_URI = stageMap.graphql;
export const LABS_URL = stageMap.labs;
export const LAUNCH_DARKLY_CLIENT_ID = stageMap.launchDarklyClientId;
export const CLIENT_ID = stageMap.client_id;
export const CLIENT_SECRET = stageMap.client_secret;
export const isDev = RELEASE_STAGE === 'development';
export const isStaging = RELEASE_STAGE === 'staging';
export const isProd = RELEASE_STAGE === 'production';
export const isDemo = RELEASE_STAGE === 'demo';
export const isDevOrStaging = isDev || isStaging;
