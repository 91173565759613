import React from 'react';
import { TouchArea } from './TouchArea';
import { BottomLeftInfoGroup } from './styled';

const MachineHeaderBottomLeft = ({
  variant,
  onClickToOverview,
  setTouchPointRight,
}) => {
  return (
    <BottomLeftInfoGroup>
      <TouchArea
        onClickToOverview={onClickToOverview}
        variant={variant}
        setTouchPointRight={setTouchPointRight}
      />
    </BottomLeftInfoGroup>
  );
};

export { MachineHeaderBottomLeft };
