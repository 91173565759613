import React from 'react';
import styled from 'styled-components';
import {
  Notification,
  toast,
  palette,
  ToastContainer,
} from '@m12s/component-library';

const CustomToastContainer = styled(ToastContainer)`
  &.Toastify__toast-container--bottom-center {
    width: 20rem !important;
    left: 50% !important;
    margin-left: -10rem !important;
  }

  .mm-notification-toast {
    box-shadow: none;

    [class^='Notification__Wrapper-sc'] {
      border-radius: 0.25rem;
      text-align: center;
      background: ${palette.Grey800};
    }

    &.error {
      [class^='Notification__Wrapper-sc'] {
        background: ${palette.Red600};
      }
    }

    & .Toastify__toast-body {
      display: flex;
      justify-content: center;
      width: fit-content;

      & [class^='Notification__Wrapper'] {
        min-height: unset;
        width: fit-content;
        padding: 0 1rem;
        height: 3rem;
      }

      & [class^='Notification__TextWrapper'] {
        width: fit-content;
        padding: 0;
        margin: 0;
      }
    }
  }
`;

const PreWrap = styled.span`
  white-space: pre-wrap;
  font-weight: 400;
  color: ${palette.White};
  font-size: 1rem;
`;

const throwErrorToast = (message, opts = {}) => {
  return toast(
    <Notification text={<PreWrap>{message}</PreWrap>} status="error" />,
    {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 8000,
      ...opts,
      className: 'mm-notification-toast error',
      ariaLabel: message,
    }
  );
};

const throwSuccessToast = (message, opts = {}) => {
  return toast(
    <Notification text={<PreWrap>{message}</PreWrap>} status="success" />,
    {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 3000,
      ...opts,
      className: 'mm-notification-toast success',
      ariaLabel: message,
    }
  );
};

const throwStatusToast = (message, status, opts = {}) => {
  return toast(
    <Notification text={<PreWrap>{message}</PreWrap>} status={status} />,
    {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 30 * 60 * 1000,
      ...opts,
      className: `mm-notification-toast ${status}`,
      ariaLabel: message,
    }
  );
};

export {
  throwErrorToast,
  throwSuccessToast,
  throwStatusToast,
  CustomToastContainer,
};
