import { getMachine } from 'lib/selectors/getMachine';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { getWorkOrderFlowEnabled } from 'lib/selectors/getWorkOrderFlowEnabled';

const useIsWorkOrderFlow = () => {
  const machine = useSelector(getMachine);
  const erpResourceId = _get(machine, 'erpResourceId');
  const erpAssociatedResources = _get(machine, 'erpResources');

  const workOrderFlowEnabled = useSelector(getWorkOrderFlowEnabled);

  return (
    workOrderFlowEnabled && !!(erpResourceId || erpAssociatedResources?.length)
  );
};

export { useIsWorkOrderFlow };
