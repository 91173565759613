import React from 'react';
import { Button, CounterBlock, Icon } from '@m12s/component-library';
import { useSelector } from 'react-redux';

import { formatTime, formatTimeAndDate } from 'lib/utils/date';
import { pencil } from 'lib/icons';
import { getSetupModeStart } from 'lib/selectors/getSetupModeStart';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import _startCase from 'lodash/startCase';
import { getSetupPartsDisplay } from 'lib/selectors/getSetupPartsDisplay';
import { getIsLaborTicketEnabled } from 'lib/selectors/getIsLaborTicketEnabled';
import {
  SetupPartsSubtext,
  SetupPartsDisplayContainer,
  SetupPartsDisplayText,
  FlexRow,
} from './styled';

// SetupPartsDisplayContainer, SetupPartsDisplayText

const AdjustButton = ({ t, setAdjustPartsModalOpen }) => {
  const isPaused = useSelector(getIsPaused);
  return (
    <Button
      variant="inferior"
      rounded
      onClick={() => {
        setAdjustPartsModalOpen(true);
      }}
      style={{ marginLeft: '1rem' }}
      disabled={isPaused}
    >
      <Icon icon={pencil} style={{ marginRight: '0.5rem' }} />
      {t('Adjust')}
    </Button>
  );
};

/* Shows part total since start of setup mode.
Can extend before scope. */
const SetupPartsDisplay = ({
  setAdjustPartsModalOpen,
  setupModeSpansShift,
  t,
}) => {
  const laborTicketsEnabled = useSelector(getIsLaborTicketEnabled);

  const setupModeStart = useSelector(getSetupModeStart);
  const onLeft = !setupModeSpansShift;

  const {
    isERPFlow,
    unsubmittedParts,
    rejectedParts,
    displayParts,
  } = useSelector(getSetupPartsDisplay);

  // TODO: Assuming the label changes only for ERP FLOW. Need to confirm.
  const label = isERPFlow
    ? _startCase(t('you have made'))
    : t(onLeft ? 'Setup Parts' : 'Total Setup Parts');

  return (
    <CounterBlock
      align={onLeft ? 'left' : 'right'}
      label={label}
      value={
        <SetupPartsDisplayContainer>
          <FlexRow>
            <SetupPartsDisplayText>{displayParts}</SetupPartsDisplayText>
            {onLeft && !laborTicketsEnabled && (
              <AdjustButton
                t={t}
                setAdjustPartsModalOpen={setAdjustPartsModalOpen}
              />
            )}
          </FlexRow>
          {isERPFlow ? (
            <SetupPartsSubtext>
              {t('Rejected and unsubmitted', {
                rejected: rejectedParts,
                unsubmitted: unsubmittedParts,
              })}
            </SetupPartsSubtext>
          ) : (
            <SetupPartsSubtext>
              {t('Since entering setup')}:{' '}
              {onLeft
                ? formatTime(setupModeStart)
                : formatTimeAndDate(setupModeStart)}
            </SetupPartsSubtext>
          )}
        </SetupPartsDisplayContainer>
      }
    />
  );
};

/* Shows part total only since start of shift. 
This is only shown if setup extends before shift start. */
const ShiftSetupPartsDisplay = ({ setAdjustPartsModalOpen, t, shiftStart }) => {
  const laborTicketsEnabled = useSelector(getIsLaborTicketEnabled);

  const {
    isERPFlow,
    unsubmittedParts,
    rejectedParts,
    displayParts,
  } = useSelector(getSetupPartsDisplay);

  // TODO: Assuming the label changes only for ERP FLOW. Need to confirm.
  const label = isERPFlow
    ? _startCase(t('you have made'))
    : t('Setup Parts This Shift');

  return (
    <CounterBlock
      label={label}
      value={
        <SetupPartsDisplayContainer>
          <FlexRow>
            <SetupPartsDisplayText>
              {displayParts?.toLocaleString()}
            </SetupPartsDisplayText>
            {!laborTicketsEnabled && (
              <AdjustButton
                t={t}
                setAdjustPartsModalOpen={setAdjustPartsModalOpen}
              />
            )}
          </FlexRow>
          {isERPFlow ? (
            <SetupPartsSubtext>
              {t('Rejected and unsubmitted', {
                rejected: rejectedParts,
                unsubmitted: unsubmittedParts,
              })}
            </SetupPartsSubtext>
          ) : (
            <SetupPartsSubtext>
              {t('Since start of shift')}: {formatTime(shiftStart)}
            </SetupPartsSubtext>
          )}
        </SetupPartsDisplayContainer>
      }
    />
  );
};

export { SetupPartsDisplay, ShiftSetupPartsDisplay };
