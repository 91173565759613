import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import _startCase from 'lodash/startCase';

import { useSelector } from 'react-redux';

import { DurationTicker } from 'components/DurationTicker';
import { getActiveUncategorizedInterval } from 'lib/selectors/getActiveUncategorizedInterval';
import { getActiveCategorizedInterval } from 'lib/selectors/getActiveCategorizedInterval';
import { getConnection } from 'lib/selectors/getConnection';
import Routes from 'lib/utils/routes';
import { JOB_STATUSES, MACHINE_STATUSES } from 'lib/constants';
import { exclamationCircle, minus } from 'lib/icons';
import { getFaultStartAt } from 'lib/selectors/getFaultStartAt';
import { getJobStatus } from 'lib/selectors/getJobStatus';
import { getStatusLabel } from 'lib/selectors/getStatusLabel';
import { getStatusStartAt } from 'lib/selectors/getStatusStartAt';
import { getUnavailableStartAt } from 'lib/selectors/getUnavailableStartAt';
import { getMachine } from 'lib/selectors/getMachine';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getOffline } from 'lib/selectors/getOffline';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getWorkOrderId } from 'lib/selectors/getWorkOrderId';
import { getIsLatestActivitySetOpen } from 'lib/selectors/getIsLatestActivitySetOpen';
import { getIsAgentConnected } from 'lib/selectors/getIsAgentConnected';
// import { ActiveShimmer } from 'components/ActiveShimmer';

import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import {
  Button,
  MachineStatusIcon,
  Text,
  TouchTarget,
  TouchTargetPosition,
  MachineAndOperatorNameContainer,
  TouchPointArea,
} from './styled';

import Layout from './Layout';
import { MachineHeaderBottomLeft } from './MachineHeaderBottomLeft';

function labelFor(t, status) {
  switch (status) {
    case MACHINE_STATUSES.UNAVAILABLE:
      return _startCase(t(_lowerCase('machine connection error')));
    default:
      return _startCase(t(_lowerCase(status)));
  }
}

function getMachineStatusIcon(status) {
  switch (status) {
    case MACHINE_STATUSES.UNAVAILABLE:
      return exclamationCircle;
    default:
      return minus;
  }
}

const MachineHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [touchPointRight, setTouchPointRight] = useState(0);
  const machine = useSelector(getMachine);
  const operator = useSelector(getCurrentOperator);
  const faultStartAt = useSelector(getFaultStartAt);
  const status = useSelector(getStatusLabel);
  const statusStartAt = useSelector(getStatusStartAt);
  const unavailableStartAt = useSelector(getUnavailableStartAt);
  const jobStatus = useSelector(getJobStatus);
  const areActivitiesEnabled = useSelector(getAreActivitiesEnabled);
  const isPaused = useSelector(getIsPaused);
  const job = useSelector(getLatestJob);
  const workOrderId = useSelector(getWorkOrderId);
  const offline = useSelector(getOffline);
  const { disconnectedAt } = useSelector(getConnection);
  const isLatestActivitySetOpen = useSelector(getIsLatestActivitySetOpen);
  const isAgentConnected = useSelector(getIsAgentConnected);

  const fault = !!faultStartAt;
  const state = (() => {
    if (offline) {
      return 'no internet connection';
    }
    if (!isAgentConnected && unavailableStartAt) {
      return 'edge device connection error';
    }
    if (fault) {
      return 'in fault';
    }
    return status;
  })();
  const variant = (() => {
    if (offline || !isAgentConnected || unavailableStartAt) {
      return 'unavailable';
    }
    if (isPaused) {
      return 'pause';
    }
    if (jobStatus === JOB_STATUSES.SETUP) {
      return 'setup';
    }
    if (fault) {
      return 'fault';
    }
    return status;
  })();
  const time =
    (offline && disconnectedAt) ||
    unavailableStartAt ||
    faultStartAt ||
    statusStartAt;
  const currentUncategorizedInterval = useSelector(
    getActiveUncategorizedInterval
  );
  const currentCategorizedInterval = useSelector(getActiveCategorizedInterval);

  const onClickToOverview = () => {
    // if machine has error / unavailable, will disbale touchpoint and work order edit
    if (variant !== 'unavailable') {
      return history.push({
        pathname: Routes.machineIdCurrentOperationInfoPath(machine.id),
        state: {
          job,
          isStartingNewRun: true,
          workOrderId,
        },
      });
    }
    return null;
  };

  const machineAndOperatorNameText = operator?.name
    ? `${machine.name} | ${operator.name}`
    : machine.name;

  return (
    <>
      {isLatestActivitySetOpen && (
        <>
          <TouchPointArea
            width={touchPointRight}
            id="touch-point"
            onTouchEnd={onClickToOverview}
          ></TouchPointArea>
        </>
      )}
      <Layout
        variant={variant}
        TopLeft={
          <MachineAndOperatorNameContainer variant={variant}>
            {machineAndOperatorNameText}
          </MachineAndOperatorNameContainer>
        }
        TopRight={
          currentUncategorizedInterval ? (
            <>
              <Text variant={variant} categorize>
                {`${_capitalize(t('uncategorized'))} : `}
                <DurationTicker
                  startDate={currentUncategorizedInterval.start}
                />
              </Text>
              <TouchTargetPosition>
                <TouchTarget
                  onClick={() => {
                    return history.push({
                      pathname: Routes.machineIdCategorizePath(machine.id),
                      state: {
                        preselected: currentUncategorizedInterval,
                        returnPath: history.location.pathname,
                      },
                    });
                  }}
                >
                  <Button
                    id="categorize"
                    variant="auxiliary"
                    headerVariant={variant}
                  >
                    {t('categorize|full')}
                  </Button>
                </TouchTarget>
              </TouchTargetPosition>
            </>
          ) : currentCategorizedInterval ? (
            // filter out "artificial" setup annos for companies using jsgs
            (areActivitiesEnabled || !currentCategorizedInterval.setup) && (
              <>
                <Text variant={variant} categorize>
                  {currentCategorizedInterval.annotationType.name}:{' '}
                  <DurationTicker
                    startDate={currentCategorizedInterval.start}
                  />
                </Text>
                <TouchTargetPosition>
                  <TouchTarget
                    onClick={() => {
                      return history.push({
                        pathname: Routes.machineIdCategorizePath(machine.id),
                        state: {
                          preselected: currentCategorizedInterval,
                          splitting: true,
                          returnPath: history.location.pathname,
                        },
                      });
                    }}
                  >
                    <Button
                      id="split-downtime"
                      variant="auxiliary"
                      headerVariant={variant}
                    >
                      {t('split downtime')}
                    </Button>
                  </TouchTarget>
                </TouchTargetPosition>
              </>
            )
          ) : null
        }
        BottomLeft={
          <MachineHeaderBottomLeft
            variant={variant}
            onClickToOverview={onClickToOverview}
            setTouchPointRight={setTouchPointRight}
          />
        }
        BottomRight={
          <>
            <Text variant={variant} noWrap>
              {labelFor(t, state)} :&nbsp;
              {time && <DurationTicker startDate={time} />}
            </Text>
            {status === MACHINE_STATUSES.ACTIVE ? (
              // <ActiveShimmer status={variant} />
              <span />
            ) : (
              <MachineStatusIcon
                variant={variant}
                icon={getMachineStatusIcon(status)}
              />
            )}
          </>
        }
      />
    </>
  );
};

export { MachineHeader };
