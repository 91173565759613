import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';

import { getWorkOrderOpBadParts } from 'lib/selectors/getWorkOrderOpBadParts';
import { getWorkOrderOpTotalParts } from 'lib/selectors/getWorkOrderOpTotalParts';

import {
  LaborTicketHeaderItem,
  LaborTicketItem,
} from 'components/LaborTicket/styled';
import Card from '../Card';
import { BodyContainer } from '../styled';

const LaborTicketJobTotal = ({ form }) => {
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);

  const [initialState, setInitialState] = useState({
    workOrderOp: currentWorkOrderOp,
  });

  useEffect(() => {
    setInitialState({
      workOrderOp: currentWorkOrderOp,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { workOrderOp } = initialState;

  const workOrderId = _get(workOrderOp, 'workOrderId');

  const partOperation = _get(workOrderOp, 'partOperation');

  const workOrderBadParts = useSelector(getWorkOrderOpBadParts);
  const workOrderGoodParts = useSelector(getWorkOrderOpTotalParts);

  const rejectedParts = form.rejectedParts?.value || 0;
  const goodParts = form.totalParts.value - rejectedParts;

  const totalBadParts = workOrderBadParts + rejectedParts;
  const totalGoodParts = workOrderGoodParts + goodParts;
  const totalParts = totalBadParts + totalGoodParts;

  return (
    <Card
      header={
        <>
          Job Total
          <LaborTicketHeaderItem label="Order:" value={workOrderId} />
          <LaborTicketHeaderItem
            label="Part Op: "
            value={`${partOperation?.partNumber} Op ${partOperation?.sequenceNumber}`}
          />
        </>
      }
      body={
        <BodyContainer>
          <LaborTicketItem label="Total Parts Produced" value={totalParts} />
          <LaborTicketItem label="Total Good Parts" value={totalGoodParts} />
          <LaborTicketItem label="Total Rejected Parts" value={totalBadParts} />
          <LaborTicketItem
            label="Quantity Required"
            value={workOrderOp?.finishQuantity}
          />
        </BodyContainer>
      }
    />
  );
};

export default LaborTicketJobTotal;
