import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, palette } from '@m12s/component-library';
import {
  Body,
  Buttons,
  Dialog,
  Header,
  HeaderIcon,
  Title,
} from 'components/Dialog';
import debounce from 'lodash/debounce';
import _startCase from 'lodash/startCase';
import { signIn } from 'lib/icons';
import { H5, P, Text } from 'components/Text';
import styled from 'styled-components';
import {
  getAppInfo,
  useEmbeddedEffect,
} from '@machinemetrics/mm-react-embeddable';
import { Drawer, DrawerBody } from 'components/Drawer';

const HStack = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
`;

const CustomTabAuthorizationModal = () => {
  const { t } = useTranslation();
  const [loginPayloads, setLoginPayloads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPayload, setSelectedPayload] = useState(null);

  const debouncedSetLoginPayloads = useCallback(
    debounce((payload) => {
      (async () => {
        const appInfo = await getAppInfo(payload.authUrl);
        setLoginPayloads((prev) => {
          const exists = prev.some((p) => {
            return p.authUrl === payload.authUrl;
          });

          if (exists) {
            return prev;
          }

          return [
            ...prev,
            {
              ...appInfo,
              ...payload,
            },
          ];
        });
      })();
    }, 1000),
    []
  );

  useEffect(() => {
    if (loginPayloads.length > 0) {
      setIsModalOpen(true);
    }
  }, [loginPayloads]);

  useEmbeddedEffect('*', 'login', (data) => {
    debouncedSetLoginPayloads(data);
  });

  useEmbeddedEffect('*', 'authorize-failed', () => {
    setSelectedPayload(null);
  });

  useEmbeddedEffect('*', 'authorized', (data) => {
    setLoginPayloads((prev) => {
      return prev.filter((p) => {
        return p.authUrl !== data?.authUrl;
      });
    });
    setSelectedPayload(null);
  });

  const handleAuthorize = (payload) => {
    if (window.location.protocol === 'https:') {
      setSelectedPayload(payload);
    } else {
      const popup = window.open(payload.authUrl, '_blank');

      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
          setLoginPayloads((prev) => {
            return prev.filter((p) => {
              return p.authUrl !== payload.authUrl;
            });
          });
        }
      }, 1000);
    }
  };

  return (
    <>
      <Dialog
        isOpen={isModalOpen}
        onClose={() => {
          return setIsModalOpen(false);
        }}
      >
        <Header>
          <Title color={palette.White}>
            {_startCase(t(`authorization required`))}
          </Title>
          <HeaderIcon color={palette.White} icon={signIn} />
        </Header>
        <Body>
          <P>
            {loginPayloads.length > 0 &&
              t(`The following apps require authorization. Authorize each app
            below to continue.`)}
            {loginPayloads.length === 0 && t(`You're ready to go!`)}
          </P>
          {loginPayloads.map((payload) => {
            return (
              <HStack style={{ padding: '1rem 0' }} key={payload.origin}>
                <div style={{ marginRight: '2rem' }}>
                  <H5 style={{ margin: '0 0 1rem 0' }}>{payload.name}</H5>
                  <Text>{payload.description}</Text>
                </div>
                <div style={{ display: 'flex' }}>
                  <Button
                    style={{ margin: 'auto' }}
                    onClick={() => {
                      handleAuthorize(payload);
                    }}
                  >
                    {_startCase(t(`authorize`))}
                  </Button>
                </div>
              </HStack>
            );
          })}
        </Body>
        <Buttons>
          <Button
            disabled={loginPayloads.length > 0}
            onClick={() => {
              window.location.reload();
            }}
          >
            Done
          </Button>
        </Buttons>
      </Dialog>
      <Drawer isOpen={selectedPayload !== null}>
        <DrawerBody style={{ padding: '0' }}>
          <iframe
            title="authorize"
            style={{ border: 'none', width: '100%', height: '100%' }}
            src={selectedPayload?.authUrl}
          />
        </DrawerBody>
      </Drawer>
    </>
  );
};

export { CustomTabAuthorizationModal };
