import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { palette, LoadingCircle } from '@m12s/component-library';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { spinner } from 'lib/icons';

import { ModalButton } from 'components/LaborTicket/styled';

import { getLoadingPartAdjustment } from 'lib/selectors/getLoadingPartAdjustment';
import { actionCloseLaborTicketModal } from 'lib/actions';
import { getLaborTicketActionLoading } from 'lib/selectors/getLaborTicketActionLoading';

const FooterContainer = styled.div`
  position: absolute;
  border-top: 1px solid ${palette.Grey400};
  height: 5rem;
  margin-left: -1.5rem;
  margin-bottom: 1rem;
  width: 100%;
  background-color: ${palette.White};
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 1rem;
`;

const FooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const Footer = ({ form, loading, submitToErp }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const laborTicketActionLoading = useSelector(getLaborTicketActionLoading);
  const loadingPartAdjustment = useSelector(getLoadingPartAdjustment);

  const rejectedParts = form.rejectedParts?.value || 0;
  const rejectReason = form.rejectReason?.value || 0;

  return (
    <FooterContainer>
      <FooterButtonContainer>
        <ModalButton
          customStyles={{
            background: palette.White,
            color: palette.Black,
            border: `1px solid ${palette.Grey400}`,
          }}
          hoverStyles={{
            background: palette.Grey200,
          }}
          appearance="danger"
          onClick={() => {
            if (!laborTicketActionLoading) {
              dispatch(actionCloseLaborTicketModal());
            }
          }}
        >
          {t('Cancel')}
        </ModalButton>

        <ModalButton
          customStyles={{
            background: palette.Indigo600,
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
          }}
          hoverStyles={{
            background: palette.Indigo700,
          }}
          disabled={
            loading || (!rejectReason && rejectedParts) || loadingPartAdjustment
          }
          // eslint-disable-next-line consistent-return
          onClick={submitToErp}
        >
          {loading && <LoadingCircle icon={spinner} size="1.25rem" />}
          {t('Submit')}
        </ModalButton>
      </FooterButtonContainer>
    </FooterContainer>
  );
};

export default Footer;

Footer.propTypes = {
  form: PropTypes.object,
  loading: PropTypes.bool,
  submitToErp: PropTypes.func,
};
