import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { STOP_OPERATOR } from 'lib/api/mutations';
import { getMachine } from 'lib/selectors/getMachine';
import { getOperatorIsLoggingOut } from 'lib/selectors/getOperatorIsLoggingOut';
import {
  actionSetRecentOperatorRuns,
  actionSetOperatorIsLoggingOut,
} from 'lib/actions';

const useOperatorLogout = (onComplete = () => {}) => {
  const dispatch = useDispatch();
  const machine = useSelector(getMachine);
  const isLoggingOut = useSelector(getOperatorIsLoggingOut);

  const [stopOperator, { loading }] = useMutation(STOP_OPERATOR, {
    fetchPolicy: 'no-cache',
    onCompleted: async ({ stopOperatorResponse }) => {
      if (stopOperatorResponse) {
        const {
          machine: localMachine,
          operator,
          ...operatorRun
        } = stopOperatorResponse;
        const mappedPayload = {
          operatorRuns: [
            {
              ...operatorRun,
              machineId: localMachine?.id,
              operatorId: operator?.id,
            },
          ],
        };
        dispatch(actionSetRecentOperatorRuns(mappedPayload));
        onComplete();
      }
    },
  });

  useEffect(() => {
    dispatch(actionSetOperatorIsLoggingOut(loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return async () => {
    if (isLoggingOut || loading) {
      return;
    }
    await stopOperator({
      variables: {
        input: {
          machineId: machine.id,
        },
      },
    });
  };
};

export default useOperatorLogout;
