import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _capitalize from 'lodash/capitalize';
import { Icon, palette, Button, Modal } from '@m12s/component-library';
import Routes from 'lib/utils/routes';
import { getMachine } from 'lib/selectors/getMachine';
import { cube, exclamationTriangle, checkCircleRegular } from 'lib/icons';
import { H5, P, Small } from 'components/Text';
import { Loading } from 'components/Loading';
import { useMutation } from '@apollo/client';
import { CREATE_OPERATION } from 'lib/api/mutations';
import { getShouldShowWorkOrderIdField } from 'lib/selectors/getShouldShowWorkOrderIdField';

import {
  ModalRow,
  ModalInputText,
  ModalHeaderText,
  CreateOperationInput,
  CreateOperationResultWrapper,
  MessageWrapper,
  DividerBox,
  ErrorIcon,
  CreateSubmitButton,
} from './styled';

const CreateOperationModal = ({ filter, onClickCloseModal }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const machine = useSelector(getMachine);
  const allowWorkOrder = useSelector(getShouldShowWorkOrderIdField);
  const onChange = (e) => {
    if (e.target.name === 'newOperationName') {
      setNewOperationName(e.target.value);
    } else if (e.target.name === 'workOrderId') {
      setWorkOrderId(e.target.value);
    }
  };
  const [newOperationName, setNewOperationName] = useState('');
  const [workOrderId, setWorkOrderId] = useState('');
  const [successfullyCreated, setSuccessfullyCreated] = useState(false);
  useEffect(() => {
    setNewOperationName(filter);
  }, [filter]);

  const [requestCreateOperation, { loading: isLoading, error }] = useMutation(
    CREATE_OPERATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ createOperationResponse }) => {
        setSuccessfullyCreated(true);
        history.push({
          pathname: Routes.machineIdStartActivityNewOperationPath(machine.id),
          state: {
            job: createOperationResponse,
            jobName: createOperationResponse.name,
            workOrderId,
          },
        });
      },
    }
  );

  let errorMessage = '.';
  if (error?.networkError) {
    errorMessage = ` because ${error?.networkError?.result?.message.toLowerCase()}`;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    return requestCreateOperation({
      variables: {
        input: {
          name: newOperationName,
          machine_group_association: 'all',
          setuptime: 0,
          partCountMultiplier: 1,
          actualParts: 1,
        },
      },
    });
  };

  return (
    <Modal
      isOpen
      details={
        <>
          <ModalRow>
            <Icon icon={cube} />
            <ModalHeaderText>{t('Create New Operation')}</ModalHeaderText>
          </ModalRow>
          {!isLoading && !error && !successfullyCreated && (
            <>
              <ModalInputText>
                {t('Enter the name of this Operation')}
              </ModalInputText>
              <CreateOperationInput
                name="newOperationName"
                value={newOperationName}
                onChange={onChange}
                placeholder="Ex. 8675-309"
              />
              {allowWorkOrder && (
                <>
                  <ModalInputText>
                    Production Order for initial production run
                  </ModalInputText>
                  <CreateOperationInput
                    name="workOrderId"
                    value={workOrderId}
                    onChange={onChange}
                    placeholder={t(
                      'Enter the Production Order number (optional)'
                    )}
                  />
                </>
              )}
            </>
          )}

          {isLoading && !error && (
            <CreateOperationResultWrapper>
              <Loading size="2rem" />
              <H5>{t('Creating the operation', { filter })}</H5>
              <MessageWrapper>
                <P>{t('Please wait one moment.')}</P>
              </MessageWrapper>
            </CreateOperationResultWrapper>
          )}

          {error && !isLoading && (
            <CreateOperationResultWrapper>
              <DividerBox heightSize={1} />
              <ErrorIcon icon={exclamationTriangle} />
              <H5>{t('Error creating operation ', { filter })}</H5>
              <MessageWrapper>
                <Small>
                  {t('error for creating operation', {
                    errorMessage,
                  })}
                </Small>
              </MessageWrapper>
            </CreateOperationResultWrapper>
          )}
          {!isLoading && !error && successfullyCreated && (
            <CreateOperationResultWrapper>
              <DividerBox heightSize={1} />
              <div style={{ marginBottom: '0.5rem' }}>
                <Icon
                  icon={checkCircleRegular}
                  size="2rem"
                  color={palette.Green500}
                />
              </div>

              <H5>{t('Operation created successfully', { filter })}</H5>
              <MessageWrapper>
                <Small>
                  {t(
                    'The operation was created successfully. Please wait while you are redirected.',
                    { filter }
                  )}
                </Small>
              </MessageWrapper>
            </CreateOperationResultWrapper>
          )}
        </>
      }
      buttons={
        <>
          <Button variant="tertiary" onClick={onClickCloseModal}>
            {_capitalize(t('cancel'))}
          </Button>
          {!error && !successfullyCreated && (
            <CreateSubmitButton
              disabled={!newOperationName || isLoading}
              onClick={onSubmit}
            >
              {t('Create & Start Run')}
            </CreateSubmitButton>
          )}
          {successfullyCreated && (
            <Button disabled>{t('Redirecting...')}</Button>
          )}
          {error && <Button onClick={onSubmit}>{t('Try Again')}</Button>}
        </>
      }
    />
  );
};

export { CreateOperationModal };
