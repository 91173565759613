import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { palette } from '@m12s/component-library';
import routes from 'lib/utils/routes';
import styled from 'styled-components';

import { getMachine } from 'lib/selectors/getMachine';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getLatestJob } from 'lib/selectors/getLatestJob';

import { AppNav } from 'components/AppNav';
import MainLayout from 'components/Layouts/MainLayout';
import { H5 } from 'components/Text';

import NoOrderOpContent from './NoOrderOpContent';
import OrderOpContent from './OrderOpContent';
import { ContentContainer } from './styled';

const Title = styled(H5)`
  width: 100%;
  text-align: left;
  padding-left: 1.5rem;
  font-weight: 500;
  margin-left: -8rem;
`;

const JobInfoERP = ({
  handleOnClose = (history, machine) => {
    return history.push({ pathname: routes.machineIdHomePath(machine.id) });
  },
}) => {
  const history = useHistory();

  const job = useSelector(getLatestJob);
  const machine = useSelector(getMachine);

  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);

  return (
    <MainLayout.Container id="job-details-page" direction="column" overlay>
      <AppNav
        iconColor={palette.Grey700}
        color={palette.White}
        onClose={() => {
          handleOnClose(history, machine);
        }}
        style={{
          borderBottom: `1px solid ${palette.Grey400}`,
        }}
      >
        <Title>
          {!currentWorkOrderOp
            ? `${job.name} | Operation overview`
            : 'Current production order operation'}
        </Title>
      </AppNav>
      <ContentContainer>
        {currentWorkOrderOp?.workOrderId ? (
          <OrderOpContent />
        ) : (
          <NoOrderOpContent job={job} />
        )}
      </ContentContainer>
    </MainLayout.Container>
  );
};

JobInfoERP.propTypes = {
  handleOnClose: PropTypes.func,
};

export default JobInfoERP;
