import { createSelector } from 'reselect';

import { FLAG_KEYS } from 'lib/constants';
import { getLaunchDarklyFlags } from './getLaunchDarklyFlags';

const getIsLaborTicketEnabled = createSelector(
  [getLaunchDarklyFlags],
  (flags) => {
    return flags?.[FLAG_KEYS.LABOR_TICKET_ENABLED];
  }
);

export { getIsLaborTicketEnabled };
