import React from 'react';
import Modal from 'react-modal';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const StyledModal = styled(Modal)`
  &.ReactModal__Content--after-open {
    animation: ${slideIn} 0.25s ease-out forwards;
  }
  &.ReactModal__Content--before-close {
    animation: ${slideOut} 0.25s ease-out forwards;
  }
`;

const customStyles = {
  overlay: {
    background: 'rgb(26, 32, 44, 0.7)',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    border: 0,
    borderRadius: '0.25rem 0.25rem 0 0',
    padding: 0,
    width: '100%',
    height: '97vh',
    maxWidth: '100%',
    overflow: 'hidden',
    boxShadow:
      '0px 1px 1px rgba(42, 54, 57, 0.04), 0px 1px 3px rgba(42, 54, 57, 0.08)',
  },
};

const Drawer = ({ children, getStyle, ...props }) => {
  return (
    <StyledModal
      {...props}
      closeTimeoutMS={250}
      style={getStyle ? getStyle(customStyles) : customStyles}
    >
      {children}
    </StyledModal>
  );
};

export { Drawer };
