// React and Hooks
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSubmitLaborTicket from 'components/LaborTicket/useSubmitLaborTicket';
import useOperatorLogout from 'components/LaborTicket/hooks/useOperatorLogout';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { actionOpenLaborTicketModal } from 'lib/actions';
// Selectors
import { getCompany } from 'lib/selectors/getCompany';
import { getIsChangeActivitiesLoading } from 'lib/selectors/getIsChangeActivitiesLoading';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';
// Utils
// Components
import { ControlBarButton } from '@m12s/component-library';
import { check, playRegular, signOut } from 'lib/icons';
import { UtilityMenu } from 'components/UtilityMenu';
import Routes from 'lib/utils/routes';
import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import {
  ControlBarResponsive,
  MobileIcon,
  ResponsiveText,
  Scrollable,
} from './styled';
import { CategorizeDowntime } from './ERPJobControlBar/CategorizeDowntime';
import { SignInAndSelectJob } from './ERPJobControlBar/SignInAndSelectJob';
import { SwitchActivity } from './ERPJobControlBar/SwitchActivity';

const ERPJobControlBar = ({
  history,
  machine,
  hasOpenActivitySet,
  isPaused,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const requestLaborTicketAction = useSubmitLaborTicket();
  const requestOperatorLogout = useOperatorLogout();

  const operator = useSelector(getCurrentOperator);

  // const hasActivityToSwitchTo = useSelector(getHasActivityToSwitchTo);
  // const hasThreeOrMoreActivities = useSelector(getHasThreeOrMoreActivities);
  // {hasActivityToSwitchTo && hasThreeOrMoreActivities && (
  // )}

  const company = useSelector(getCompany);
  const isChangeActivitiesLoading = useSelector(getIsChangeActivitiesLoading);
  const latestActivity = useSelector(getLatestActivity);
  const enableMachineSwitching = company?.machineSwitching === true;

  if (!operator || !hasOpenActivitySet) {
    return (
      <SignInAndSelectJob
        history={history}
        machine={machine}
        operator={operator}
        hasOpenActivitySet={hasOpenActivitySet}
        enableMachineSwitching={enableMachineSwitching}
      />
    );
  }

  return (
    <ControlBarResponsive id="job-control-bar">
      <UtilityMenu />
      <Scrollable>
        {isPaused ? (
          <ControlBarButton
            id="job-control-resume-job"
            disabled={isChangeActivitiesLoading}
            onClick={() => {
              if (isChangeActivitiesLoading) {
                return null;
              }
              return requestLaborTicketAction({
                action: LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES,
                opts: {
                  pendingActivityTypeRef: latestActivity.activityTypeRef,
                },
              });
            }}
          >
            <MobileIcon icon={playRegular} />
            <ResponsiveText
              full={t('resumeJob|full')}
              medium={t('resumeJob|medium')}
              short={t('resumeJob|short')}
            />
          </ControlBarButton>
        ) : (
          <ControlBarButton
            id="job-control-bar-submit"
            onClick={() => {
              return dispatch(
                actionOpenLaborTicketModal(LABOR_TICKET_ACTIONS.STOP_AND_CREATE)
              );
            }}
          >
            <MobileIcon icon={check} />
            <ResponsiveText
              full={t('submitWork|full')}
              medium={t('submitWork|medium')}
              short={t('submitWork|short')}
            />
          </ControlBarButton>
        )}

        <SwitchActivity
          history={history}
          machine={machine}
          isPaused={isPaused}
        />

        <CategorizeDowntime history={history} machine={machine} />

        <ControlBarButton
          id="signout"
          onClick={() => {
            if (isPaused) {
              return requestOperatorLogout();
            }
            history.push(Routes.machineIdHomePath(machine.id));
            return dispatch(
              actionOpenLaborTicketModal(
                LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT
              )
            );
          }}
        >
          <MobileIcon icon={signOut} />
          <ResponsiveText
            full={t('signout|full')}
            medium={t('signout|medium')}
            short={t('signout|short')}
          />
        </ControlBarButton>
      </Scrollable>
    </ControlBarResponsive>
  );
};

export { ERPJobControlBar };
