import React from 'react';
import { useSelector } from 'react-redux';
import { WheelLayout } from 'components/WheelLayout';
import { OperatorPill } from 'components/OperatorPill';
import {
  PauseDetailsToggle,
  PauseDetailsOverlayContainer,
} from 'components/PauseDetails';
import { getScopePartsGoal } from 'lib/selectors/getScopePartsGoal';
import { getIsOperatorTrackingEnabled } from 'lib/selectors/getIsOperatorTrackingEnabled';
import { ExpectedItems } from 'components/TabsCommon';
import {
  WheelComponent,
  QualityMeter,
  CycleHistory,
  CyclesFinished,
} from './components';

export const CycleCount = () => {
  const { expectedCyclesNow } = useSelector(getScopePartsGoal);
  const isOperatorTrackingEnabled = useSelector(getIsOperatorTrackingEnabled);

  return (
    <PauseDetailsOverlayContainer>
      <WheelLayout
        id="cycle-count-page"
        topLeft={<CyclesFinished />}
        topRight={<ExpectedItems value={expectedCyclesNow} />}
        bottomRight={<CycleHistory />}
        bottomLeft={<QualityMeter />}
        wheel={<WheelComponent />}
        login={isOperatorTrackingEnabled && <OperatorPill />}
      />
      <PauseDetailsToggle />
    </PauseDetailsOverlayContainer>
  );
};
