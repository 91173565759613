import React from 'react';
import PropTypes from 'prop-types';

import {
  Root,
  TopLeftContent,
  WheelWrapper,
  LoginLandscape,
  LoginPortrait,
  TopRightContent,
  BottomLeftContent,
  BottomRightContent,
} from './styled';

const WheelLayout = ({
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  wheel,
  login,
  id,
  isPaused = false,
}) => {
  return (
    <Root id={id}>
      <TopLeftContent id={`top-left-${id}`} isPaused={isPaused}>
        {topLeft}
      </TopLeftContent>
      <TopRightContent id={`top-right-${id}`} isPaused={isPaused}>
        {topRight}
      </TopRightContent>
      <WheelWrapper>
        {wheel}
        <LoginLandscape>{login}</LoginLandscape>
      </WheelWrapper>
      <LoginPortrait>{login}</LoginPortrait>
      <BottomLeftContent id={`bottom-left-${id}`} isPaused={isPaused}>
        {bottomLeft}
      </BottomLeftContent>
      <BottomRightContent id={`bottom-right-${id}`} isPaused={isPaused}>
        {bottomRight}
      </BottomRightContent>
    </Root>
  );
};

PropTypes.WheelLayout = {
  topLeft: PropTypes.node,
  topRight: PropTypes.node,
  bottomLeft: PropTypes.node,
  bottomRight: PropTypes.node,
  wheel: PropTypes.node,
  login: PropTypes.node,
  id: PropTypes.string,
  isPaused: PropTypes.bool,
};

export { WheelLayout };
