import { createSelector } from 'reselect';
import { getOEEMetrics } from 'lib/selectors/getOEEMetrics';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';

/*
  calculating OEE: https://machinemetrics.quip.com/bDM9AOj7yo6q
  OEE: availability * performance * quality
  HOWEVER, we are now using the OEE metrics from the production API
  so we no longer need to calculate it here
*/

const getOEEDecimal = createSelector(
  [getHasOpenActivity, getOEEMetrics],
  (hasOpenActivity, oeeMetrics) => {
    if (
      // metric is only relevant when active / job is running and unpaused
      !hasOpenActivity
    ) {
      return null;
    }

    return oeeMetrics?.oee || null;
  }
);

export { getOEEDecimal };
