import { createSelector } from 'reselect';
import { getWorkOrderManagement } from './getWorkOrderManagement';
import { getIsLaborTicketEnabled } from './getIsLaborTicketEnabled';

export const getWorkOrderOperationProgress = createSelector(
  [getWorkOrderManagement, getIsLaborTicketEnabled],
  (workOrderManagement, isLaborTicketEnabled) => {
    let workOrderOpTotalParts = 0;
    let workOrderOpStartAt = null;
    let workOrderOpBadParts = 0;
    const {
      workOrderOperationLaborTickets,
      workOrderOperationParts,
    } = workOrderManagement;

    if (isLaborTicketEnabled && workOrderOperationLaborTickets?.length) {
      const erpLaborTickets = workOrderOperationLaborTickets;
      const { totalParts, badParts, earliestClockIn } = erpLaborTickets.reduce(
        (acc, ticket) => {
          if (ticket.clockIn < acc.earliestClockIn) {
            acc.earliestClockIn = ticket.clockIn;
          }
          acc.totalParts += ticket.goodParts;
          acc.badParts += ticket.badParts;
          return acc;
        },
        {
          totalParts: 0,
          badParts: 0,
          earliestClockIn: erpLaborTickets[0].clockIn,
        }
      );

      workOrderOpTotalParts = totalParts;
      workOrderOpStartAt = earliestClockIn;
      workOrderOpBadParts = badParts;
    }

    if (!isLaborTicketEnabled && workOrderOperationParts) {
      workOrderOpTotalParts = workOrderOperationParts.goodParts;
      workOrderOpStartAt = workOrderOperationParts.startAt;
      workOrderOpBadParts = workOrderOperationParts.rejectedParts;
    }

    return {
      workOrderOpTotalParts,
      workOrderOpStartAt,
      workOrderOpBadParts,
    };
  }
);
