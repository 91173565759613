import { createSelector } from 'reselect';

import { getCompany } from 'lib/selectors/getCompany';
import { getIsLaborTicketEnabled } from './getIsLaborTicketEnabled';
import { getIsOperatorTrackingEnabled } from './getIsOperatorTrackingEnabled';

const getIsOperatorRunAutoSignoutEnabled = createSelector(
  [getCompany, getIsOperatorTrackingEnabled, getIsLaborTicketEnabled],
  (
    { isOperatorRunAutoSignoutEnabled },
    hasOperatorTracking,
    isLaborTicketEnabled
  ) => {
    if (hasOperatorTracking && !isLaborTicketEnabled) {
      return !!isOperatorRunAutoSignoutEnabled;
    }

    return false;
  }
);

export { getIsOperatorRunAutoSignoutEnabled };
