import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionCloseLaborTicketModal } from 'lib/actions';
import { getLaborScopeTotalParts } from 'lib/selectors/getScopeTotalParts';
import { getLaborTicketActionLoading } from 'lib/selectors/getLaborTicketActionLoading';
import { usePartAdjustmentType } from 'pages/RejectParts/usePartAdjustmentType';

import { getLaborTicketModalOpen } from 'lib/selectors/getLaborTicketModalOpen';
import SwipeUpModal from 'components/SwipeUpModal';
import { getIsLaborTicketEnabled } from 'lib/selectors/getIsLaborTicketEnabled';
import { ModalDetailsContainer, VerticalDivider } from './styled';
import LaborTicketCard from './LaborTicketCard';
import LaborTicketForm from './LaborTicketForm';

const formConfig = {
  totalParts: {
    value: 0,
  },
  rejectedParts: {
    value: 0,
  },
  rejectReason: {
    value: null,
  },
  comment: {
    value: null,
  },
  workOrderComplete: {
    value: null,
    error: null,
  },
};

const LaborTicket = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(formConfig);
  const [prevOpen, setPrevOpen] = useState(false);
  const [prevLaborTicketPartsMade, setPrevLaborTicketPartsMade] = useState(0);

  const laborTicketEnabled = useSelector(getIsLaborTicketEnabled);
  const laborTicketModalOpen = useSelector(getLaborTicketModalOpen);

  const isOpen = useMemo(() => {
    return laborTicketEnabled && laborTicketModalOpen;
  }, [laborTicketEnabled, laborTicketModalOpen]);

  usePartAdjustmentType();

  const laborTicketPartsMade = useSelector(getLaborScopeTotalParts);
  const laborTicketActionLoading = useSelector(getLaborTicketActionLoading);

  useEffect(() => {
    // Initial form setup when the modal is opened
    if (isOpen && !prevOpen) {
      setForm({
        ...formConfig,
        totalParts: { value: laborTicketPartsMade || 0 },
      });
      if (laborTicketPartsMade !== prevLaborTicketPartsMade) {
        setPrevLaborTicketPartsMade(laborTicketPartsMade || 0);
      }
    }
    // Update only when part count changes and modal is already open
    else if (
      isOpen &&
      prevOpen &&
      laborTicketPartsMade !== prevLaborTicketPartsMade
    ) {
      const partDifference =
        (laborTicketPartsMade || 0) - prevLaborTicketPartsMade;
      if (partDifference > 0) {
        setForm((prevForm) => {
          return {
            ...prevForm,
            totalParts: {
              ...prevForm.totalParts,
              value: prevForm.totalParts.value + partDifference,
            },
          };
        });
      }
      setPrevLaborTicketPartsMade(laborTicketPartsMade || 0);
    }
    // Reset form when modal is closed
    else if (!isOpen && prevOpen) {
      setForm(formConfig);
    }

    if (isOpen !== prevOpen) {
      setPrevOpen(isOpen);
    }
  }, [laborTicketPartsMade, isOpen, prevOpen, prevLaborTicketPartsMade]);

  useEffect(() => {
    return () => {
      return setForm(formConfig);
    };
  }, []);

  const handleFormChange = useCallback((name, value, error) => {
    return setForm((prevForm) => {
      return {
        ...prevForm,
        [name]: {
          ...prevForm[name],
          value,
          ...(error !== undefined && { error }),
        },
      };
    });
  }, []);

  return (
    <SwipeUpModal
      isOpen={isOpen}
      header="Confirm work produced"
      onClose={() => {
        if (!laborTicketActionLoading) {
          dispatch(actionCloseLaborTicketModal());
        }
      }}
      detailsStyles={{
        height: `calc(100% - 10.5rem)`,
      }}
      details={
        <>
          <ModalDetailsContainer>
            <LaborTicketForm
              form={form}
              handleFormChange={handleFormChange}
              loading={laborTicketActionLoading}
            />
            <VerticalDivider />
            <LaborTicketCard
              form={form}
              loading={laborTicketActionLoading}
              handleFormChange={handleFormChange}
            />
          </ModalDetailsContainer>
        </>
      }
    />
  );
};

export default LaborTicket;
