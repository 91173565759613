import { createSelector } from 'reselect';
import { getWorkOrderOpTotalParts } from './getWorkOrderOpTotalParts';
import { getLaborScopeTotalParts } from './getScopeTotalParts';
import { getIsLaborTicketEnabled } from './getIsLaborTicketEnabled';

export const getOrderProgress = createSelector(
  [getWorkOrderOpTotalParts, getLaborScopeTotalParts, getIsLaborTicketEnabled],
  (workOrderOpTotalParts, laborScopeTotalParts, isLaborTicketEnabled) => {
    if (isLaborTicketEnabled) {
      return workOrderOpTotalParts + laborScopeTotalParts;
    }
    return workOrderOpTotalParts;
  }
);
