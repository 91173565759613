import React from 'react';
import { Icon } from '@m12s/component-library';

import { falPlus, falMinus } from 'lib/icons';

import {
  AdjustmentButton,
  Input,
  InputContainer,
  NumberInputContainer,
} from './styled';

const NumberInput = ({
  label,
  path,
  form,
  handleFormChange,
  disabled,
  max,
}) => {
  return (
    <InputContainer>
      {label}
      <NumberInputContainer>
        <Input
          value={form[path].value}
          disabled={disabled}
          onChange={(e) => {
            const value = parseInt(e.target.value, 10) || 0;
            const cappedValue = Math.min(
              Math.max(value, 0),
              max !== undefined ? max : Infinity
            );

            handleFormChange(path, cappedValue);
          }}
        />
        <AdjustmentButton
          disabled={form[path].value === 0 || disabled}
          onClick={() => {
            handleFormChange(
              path,
              Math.max(parseInt(form[path].value, 10) - 1, 0)
            );
          }}
        >
          <Icon icon={falMinus} size="1rem" />
        </AdjustmentButton>
        <AdjustmentButton
          disabled={disabled || form[path].value >= max}
          onClick={() => {
            handleFormChange(path, parseInt(form[path].value, 10) + 1);
          }}
        >
          <Icon icon={falPlus} size="1rem" />
        </AdjustmentButton>
      </NumberInputContainer>
    </InputContainer>
  );
};

export default NumberInput;
