import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import _last from 'lodash/last';
import moment from 'moment';

import { useSubscription } from '@apollo/client';
import { getMachine } from 'lib/selectors/getMachine';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getAreActivitiesEnabled } from 'lib/selectors/getAreActivitiesEnabled';

import {
  SUBSCRIBE_CURRENT_ALARMS,
  SUBSCRIBE_EXECUTION_INTERVALS,
  SUBSCRIBE_MINIMUM_PART_COUNT_EVENTS,
  SUBSCRIBE_LATEST_HEARTBEAT,
  SUBSCRIBE_ACTIVITIES_STATUS,
  SUBSCRIBE_PROGRAM_NAMES,
  SUBSCRIBE_FIRST_PART,
  SUBSCRIBE_LABOR_TICKETS,
  SUBSCRIBE_LABOR_TICKET_PARTS,
} from 'lib/api/queries';

import {
  actionSetAlarms,
  actionSetScopeStatusIntervals,
  actionSetPartCountEvents,
  actionSetLatestHeartbeat,
  actionSetActivitiesStatus,
  actionSetProgramNameData,
  actionSetFirstPart,
  actionSetLastPartTimestamp,
  actionSetLaborTickets,
  actionSetLaborTicketParts,
} from 'lib/actions';
import { getStartOfSixDaysPrior } from 'lib/selectors/getStartOfSixDaysPrior';
import { getIsBaseAppDataLoading } from 'lib/selectors/getIsBaseAppDataLoading';
import { getIsInitialDataSet } from 'lib/selectors/getIsInitialDataSet';
import { getCompany } from 'lib/selectors/getCompany';
import { getIsInitialActivitySetPollLoading } from 'lib/selectors/getIsInitialActivitySetPollLoading';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { getPartKeys } from 'lib/selectors/getPartKeys';
import { getCurrentLaborTicket } from 'lib/selectors/getLaborTickets';
import { getIsLaborTicketEnabled } from 'lib/selectors/getIsLaborTicketEnabled';
// import useTestingIntervals from './useTestingIntervals';

function onData(cb) {
  return ({ subscriptionData: { data } }) => {
    return cb(data);
  };
}

const useRealTimeData = () => {
  const dispatch = useDispatch();
  const initialLoading = useSelector(getIsBaseAppDataLoading);
  const isInitialDataSet = useSelector(getIsInitialDataSet);
  const isAPMEnabled = useSelector(getIsAPMEnabled);

  const machine = useSelector(getMachine);
  const laborTicketEnabled = useSelector(getIsLaborTicketEnabled);

  const scopeStart = useSelector(getScopeStart);
  const startOfSixDaysPrior = useSelector(getStartOfSixDaysPrior);
  const areActivitiesEnabled = useSelector(getAreActivitiesEnabled);
  const company = useSelector(getCompany);
  const isInitialActivitySetPollLoading = useSelector(
    getIsInitialActivitySetPollLoading
  );
  const hasOpenActivity = useSelector(getHasOpenActivity);
  const isPaused = useSelector(getIsPaused);
  const partKeys = useSelector(getPartKeys);
  const currentLaborTicket = useSelector(getCurrentLaborTicket);
  const clockIn = currentLaborTicket?.clockIn;

  const throttledDispatch = useCallback(
    _throttle((val) => {
      return dispatch(actionSetLastPartTimestamp(val));
    }, 3000),
    []
  );

  useSubscription(SUBSCRIBE_CURRENT_ALARMS, {
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    variables: {
      machineRef: machine.machineRef,
    },
    skip: !machine.id,
    onSubscriptionData: onData(({ alarms }) => {
      dispatch(actionSetAlarms(alarms));
    }),
  });

  // Dispatch these for downtime flyup testing
  // Switches Idle > Active every minute
  // useTestingIntervals();

  useSubscription(SUBSCRIBE_EXECUTION_INTERVALS, {
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    variables: {
      machineRef: machine.machineRef,
      startDate: scopeStart,
    },
    skip: !scopeStart || !machine.id,
    onSubscriptionData: onData(({ executionIntervals }) => {
      dispatch(actionSetScopeStatusIntervals(executionIntervals));
    }),
  });

  useSubscription(SUBSCRIBE_FIRST_PART, {
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    variables: {
      machineRef: machine.machineRef,
      startDate: scopeStart,
    },
    skip: !scopeStart || !machine.id,
    onSubscriptionData: onData(({ firstPart }) => {
      dispatch(actionSetFirstPart(firstPart));
    }),
  });

  useSubscription(SUBSCRIBE_MINIMUM_PART_COUNT_EVENTS, {
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    variables: {
      machineRef: machine.machineRef,
      startDate: startOfSixDaysPrior,
    },
    skip: !isInitialDataSet || initialLoading || !machine.machineRef,
    onSubscriptionData: onData(({ partCounts }) => {
      // order 5 most recent cycles by asc
      partCounts.sort((a, b) => {
        return moment(a.eventTime).isAfter(moment(b.eventTime)) ? 1 : -1;
      });
      const lastPart = _last(partCounts);
      const lastPartTimestamp = lastPart && lastPart.eventTime;
      dispatch(actionSetPartCountEvents(partCounts));
      throttledDispatch(lastPartTimestamp);
    }),
  });

  useSubscription(SUBSCRIBE_LATEST_HEARTBEAT, {
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    variables: {
      machineRef: machine.machineRef,
    },
    skip: !machine.machineRef,
    onSubscriptionData: onData(({ latestHeartbeat }) => {
      dispatch(actionSetLatestHeartbeat(_get(latestHeartbeat, '0', {})));
    }),
  });

  useSubscription(SUBSCRIBE_ACTIVITIES_STATUS, {
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    onSubscriptionData: onData(({ Company }) => {
      const activitiesStatus = _get(Company, ['0', 'activitiesStatus'], null);
      dispatch(actionSetActivitiesStatus(activitiesStatus));
    }),
  });

  useSubscription(SUBSCRIBE_PROGRAM_NAMES, {
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    skip:
      !areActivitiesEnabled || // fetched via polling for jsg clients
      isInitialActivitySetPollLoading ||
      // optimization: no need for sub when machineHeader will not show program info
      hasOpenActivity ||
      isPaused ||
      !_get(machine, 'machineRef') ||
      !_get(company, 'id'),
    variables: {
      machineRef: machine.machineRef,
      metricKey: machine.programMetric || 'program',
    },
    onSubscriptionData: onData(({ programNames }) => {
      const programNameData = _get(programNames, ['0'], {});
      dispatch(actionSetProgramNameData(programNameData));
    }),
  });

  useSubscription(SUBSCRIBE_LABOR_TICKETS, {
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    skip: !machine.machineRef || !laborTicketEnabled || isAPMEnabled,
    variables: {
      where: {
        // state: { _eq: 'OPEN' },
        resource: { machineRef: { _eq: machine.machineRef } },
      },
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const { erpLaborTickets } = data;
      dispatch(actionSetLaborTickets(erpLaborTickets));
    },
  });

  useSubscription(SUBSCRIBE_LABOR_TICKET_PARTS, {
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    variables: {
      machineRef: machine.machineRef,
      windowStart: clockIn,
      partKeys,
    },

    skip: !laborTicketEnabled || !partKeys || !clockIn || isAPMEnabled,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const { aggregatedCountsByActivitySet } = data;
      if (aggregatedCountsByActivitySet) {
        const sum = aggregatedCountsByActivitySet.reduce((total, curr) => {
          return total + curr.sum;
        }, 0);
        dispatch(actionSetLaborTicketParts(sum));
      }
    },
  });
};

export { useRealTimeData };
