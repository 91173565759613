/* eslint-disable arrow-body-style */
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '@m12s/component-library';
import { fileAlt, pencil } from 'lib/icons';
import { getWorkOrderId } from 'lib/selectors/getWorkOrderId';
import { getShouldShowWorkOrderIdField } from 'lib/selectors/getShouldShowWorkOrderIdField';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';

import { useTranslation } from 'react-i18next';
import useOpenOperationSelection from 'lib/hooks/useOpenOperationSelection';
import {
  WorkOrderInfoGroupContainer,
  WorkOrderText,
  MachineHeaderButton,
  MachineHeaderButtonText,
  NoWorkOrderIdContainer,
} from './styled';

const WorkOrderInfoGroup = ({ variant, onClickToOverview, isPaused }) => {
  const { t } = useTranslation();

  const withWorkOrderWidthRef = useRef();
  const noWorkOrderWidthRef = useRef();
  const workOrderFlowEnabled = useIsWorkOrderFlow();

  const workOrderId = useSelector(getWorkOrderId);
  const shouldShowWorkOrderIdField = useSelector(getShouldShowWorkOrderIdField);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);

  const openOperationSelection = useOpenOperationSelection();

  return (
    <WorkOrderInfoGroupContainer
      variant={variant}
      isInSetup={hasOpenSetupActivity}
      workOrderId={workOrderId}
    >
      {workOrderId && (
        <>
          <MachineHeaderButton
            ref={withWorkOrderWidthRef}
            onClick={onClickToOverview}
            isInSetup={hasOpenSetupActivity}
            variant={variant}
          >
            <Icon icon={fileAlt} size="0.625rem" />
            <MachineHeaderButtonText variant={variant}>
              {t('View Job')}
            </MachineHeaderButtonText>
          </MachineHeaderButton>
          {shouldShowWorkOrderIdField && (
            <WorkOrderText
              isInSetup={hasOpenSetupActivity}
              useHeaderBG={false}
              variant={variant}
              isPaused={isPaused}
              workOrderId={workOrderId}
            >
              {workOrderId}
            </WorkOrderText>
          )}
        </>
      )}

      {!workOrderId && shouldShowWorkOrderIdField && (
        <NoWorkOrderIdContainer
          ref={noWorkOrderWidthRef}
          onClick={(e) => {
            e.stopPropagation();
            if (workOrderFlowEnabled) {
              return openOperationSelection({
                updateWorkOrderId: true,
              });
            }
            return onClickToOverview();
          }}
          isInSetup={hasOpenSetupActivity}
          variant={variant}
        >
          <Icon icon={pencil} size="0.625rem" />
          <WorkOrderText
            isPaused={false} // this text should be opaque
            isInSetup={hasOpenSetupActivity}
            useHeaderBG
            variant={variant}
            workOrderId={workOrderId}
          >
            {t('Add Production Order')}
          </WorkOrderText>
        </NoWorkOrderIdContainer>
      )}

      {!workOrderId && !shouldShowWorkOrderIdField && (
        <MachineHeaderButton
          useHeaderBG
          onClick={onClickToOverview}
          isInSetup={hasOpenSetupActivity}
          variant={variant}
        >
          <Icon icon={fileAlt} size="0.625rem" />
          <MachineHeaderButtonText variant={variant}>
            {t('View Job')}
          </MachineHeaderButtonText>
        </MachineHeaderButton>
      )}
    </WorkOrderInfoGroupContainer>
  );
};

export { WorkOrderInfoGroup };
