import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'lib/utils/routes';
import { getMachine } from 'lib/selectors/getMachine';

function objectToQueryString(obj) {
  if (!obj || Object.keys(obj).length === 0) {
    return '';
  }

  return `?${Object.keys(obj)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join('&')}`;
}

const useOpenOperationSelection = () => {
  const history = useHistory();
  const machine = useSelector(getMachine);

  return (params = {}) => {
    const encodedParams = objectToQueryString(params);
    return history.push(
      Routes.machineIdSelectJobPath(machine.id) + encodedParams
    );
  };
};

export default useOpenOperationSelection;
