import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useGoToHome from 'lib/hooks/useGoToHome';

import { throwErrorToast, throwSuccessToast } from 'lib/utils/toast';

import { TRIGGER_WORKFLOW } from 'lib/api/mutations';
import { actionTriggerWorkflow } from 'lib/actions';

import { useTranslation } from 'react-i18next';

import { bugsnag } from 'lib/external/bugsnag';
import { getBugsnagUserInfo } from 'lib/selectors/getBugsnagUserInfo';
import { useMutation } from '@apollo/client';

const useWorkflowTrigger = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bugsnagUserInfo = useSelector(getBugsnagUserInfo);
  const goToHome = useGoToHome();

  const onError = useCallback(
    ({ networkError }) => {
      if (networkError) {
        throwErrorToast(t('Error triggering workflow'));

        bugsnag.notify(new Error('Error triggering workflow'), (event) => {
          event.addMetadata('user', bugsnagUserInfo);
        });
      }
    },
    [t, bugsnagUserInfo]
  );

  const onCompleted = useCallback(() => {
    throwSuccessToast(t('Workflow triggered'));
  }, [t]);

  const [triggerWorkflow] = useMutation(TRIGGER_WORKFLOW, {
    fetchPolicy: 'no-cache',
    onCompleted,
    onError,
  });

  const submit = useCallback(
    (workflow, machine) => {
      dispatch(actionTriggerWorkflow(workflow));

      goToHome();
      return triggerWorkflow({
        variables: {
          input: {
            machineId: machine.id,
            monitorId: workflow.id,
            machineRef: machine.machineRef,
          },
        },
      });
    },
    [triggerWorkflow, dispatch, goToHome]
  );

  return { submit };
};

export default useWorkflowTrigger;
